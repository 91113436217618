import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import DefaultContext from "../../contexts/DefaultContext";
import styled from "@emotion/styled";
import Select from "react-dropdown-select";
import { faPlusSquare, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomButton from "../buttons/customButton";

function SignUp() {
  let [organisations, setOrganisations] = useState([]);
  const [selected, setSelected] = useState([]);

  const history = useHistory("");
  const { register, handleSubmit } = useForm("");
  const { addFlashMessage, setAlert } = useContext(DefaultContext);
  const token = sessionStorage.getItem("complexToken");
  let [createNewOrg, setCreateNewOrg] = useState(false);
  let [newOrgName, setNewOrgName] = useState("");
  let [functionHint, setFunctionHint] = useState(false);

  const StyledSelect = styled(Select)`
    ${({ dropdownRenderer }) =>
      dropdownRenderer &&
      `
      .react-dropdown-select-dropdown {
        overflow: initial;
      }
    `}
  `;

  const onSubmit = (data) => {
    data.userName = data.email;
    data.organisationId = createNewOrg ? 0 : selected[0].value;

    if (-1 == data.password.search("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_-]).{8,}$")) {
      addFlashMessage("Uw wachtwoord moet minstens 8 tekens bevatten met minstens 1 hoofdletter, 1 kleine letter, 1 nummer en 1 speciaal teken", "warning");
      setAlert(true);
      return;
    }

    axios.get("Users/CheckEmail/" + data.email)
      .then((response) => {
        if (response.data) {
          addFlashMessage("Dit emailadres is al in gebruik.", "danger");
          setAlert(true);
        } else {
          if (createNewOrg === "false" && (data.organisationId === 0 || data.organisationId === "0")) {
            addFlashMessage("U heeft geen organisatie aangeduid.", "danger");
            setAlert(true);
          } else {
            if (createNewOrg) {
              let orgData = {
                organisationName: newOrgName,
              };
              axios.post("/Organisations/Register", orgData)
              .then((res) => {
                data.organisationId = res.data.id;
                postUser(data);
              })
              .catch((err) => console.log(err))
            } else {
              postUser(data);
            }
          }
        }
      }).catch((error) => {
        addFlashMessage(
          "Er is iets fout gelopen met de server. Probeer later opnieuw.",
          "danger"
        );
        setAlert(true);
        console.log(error);
      });
  };

  function postUser(data) {
    axios
    .post("/Users/", data)
    .then(() => {
      addFlashMessage(
        "Uw profiel is aangemaakt. Na goedkeuring kan je inloggen.",
        "success"
      );
      setAlert(true);
      goToHome();
    })
    .catch((error) => {
      addFlashMessage(
        "Er is iets fout gelopen met de server. Probeer later opnieuw.",
        "danger"
      );
      setAlert(true);
    });
  }

  useEffect(() => {
    getOrganisationData();
  }, []);

  function goToHome() {
    history.push("/");
  }
  const getOrganisationData = () => {
    organisations = [];
    axios
      .get(`/Organisations/`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        // organisations = res.data.map((result) => (
        //   <option className="text-center" key={result.id} value={result.id}>
        //     {result.organisationName}
        //   </option>
        // ));
        res.data.map((res) => 
          res.id !== 1 &&
          organisations.push({
            label: `${res.organisationName}`,
            value: res.id
        }));
        setOrganisations(organisations);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function toggleNewOrg() {
    newOrgName = "";
    createNewOrg = !createNewOrg;
    setCreateNewOrg(createNewOrg);
  }

  return (
    <div className="">
      <div className="outer">
        <div className="inner">
          <form className="container" onSubmit={handleSubmit(onSubmit)}>
            <h3>
              <p className="text-signUp" style={{ color: "white", fontSize: "25px" }}>
                Registreer
                <Link
                  style={{ color: "white", fontSize: "25px" }}
                  className="button-signUp"
                  to="/sign-in"
                  href="btn btn-primary"
                >
                  Log in
                </Link>
              </p>
            </h3>
            <div className="form-group">
              {createNewOrg ? (
                <div style={{ display: "inline-block", width: "224px", marginBotton: "1rem" }}>
                  <label style={{ color:"white" }}>
                    Nieuwe organisatie
                  </label>
                  <br/>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nieuwe Organisatie"
                    pattern="[a-zA-Z -]{1,64}"
                    title="Enkel letters zijn toegelaten"
                    autoComplete="given-name"
                    onChange={(e) => {setNewOrgName(e.target.value)}}
                    />
                </div>
              ) : (
                <div style={{ display: "inline-block", width: "224px", marginBottom: "1rem" }}>
                  <label style={{ color: "white" }}>
                    Organisatie
                  </label>
                  <StyledSelect
                    style={{backgroundColor:"white" }}
                    options={organisations}
                    values={selected}
                    onChange={setSelected}
                    labelledBy="Selecteer"
                    placeholder="Selecteer uw Organisatie"
                    />
                  {/* <select
                    className="form-control"
                    style={styleDropDown}
                    as="select"
                    required
                    {...register("organisationId")}
                    >
                  <option value="" autoFocus>
                  Kies een organisatie
                  </option>
                  {organisations}
                </select> */}
              </div>
              )}
              <CustomButton
                handler={(e) => {
                  e.preventDefault();
                  toggleNewOrg();
                }}
                name={
                  createNewOrg ? 
                  <FontAwesomeIcon
                    style={{ textDecoration: "none", color: "white", display: "inline-block" }}
                    icon={faTimesCircle}
                    size="1x"
                    />
                    :
                  <FontAwesomeIcon
                    style={{ textDecoration: "none", color: "white", display: "inline-block" }}
                    icon={faPlusSquare}
                    size="1x"
                    />
                  }/>
                  
            </div>
            <div className="form-group"
              onMouseEnter={() => {setFunctionHint(true);}}
              onMouseLeave={() => {setFunctionHint(false);}}
              >
              <label style={{ color: "white" }}>Functie</label>
              <select
                className="form-control"
                style={styleDropDown}
                as="select"
                required
                {...register("grade")}
              >
                <option value="" autoFocus>
                  Kies een functie
                </option>
                {createNewOrg === false &&
                <option value="Employer">Werkgever</option>
                }
                {createNewOrg === false &&
                <option value="Coach">Coach</option>
                }
                <option value="Admin">Beheerder</option>
              </select>
              <div className="alert alert-primary" role="alert"
                style={{
                  visibility: functionHint ? "visible" : "hidden",
                  position: "absolute",
                }}
                >
                <p>Functie duidt op de functie binnen de portal.</p>
                <p>Werkgevers kunnen quizzen aanmaken, coaches kunnen inzichten bekijken.</p>
                <p>Een organisatie heeft minstens 1 beheerder.</p>
              </div>
            </div>
            <div className="form-group">
              <label style={{ color: "white" }}>Voornaam</label>
              <input
                type="text"
                className="form-control"
                placeholder="Voornaam"
                required
                pattern="[a-zA-Z -]{1,20}"
                title="Enkel letters zijn toegelaten"
                autoComplete="given-name"
                {...register("firstName")}
              />
            </div>
            <div className="form-group">
              <label style={{ color: "white" }}>Familienaam</label>
              <input
                type="text"
                className="form-control"
                placeholder="Familienaam"
                required
                pattern="[a-zA-Z -]{1,20}"
                title="Enkel letters zijn toegelaten"
                autoComplete="family-name"
                {...register("lastName")}
              />
            </div>
            {/* <div className="form-group">
              <label style={{ color: "white" }}>Gebruikersnaam</label>
              <input
                type="text"
                className="form-control"
                placeholder="Gebruikersnaam"
                pattern="[a-zA-Z0-9]{1,20}"
                title="Enkel letters en cijfers zijn toegelaten"
                autoComplete="username"
                {...register("userName")}
              />
            </div> */}
            <div className="form-group">
              <label style={{ color: "white" }}>Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                required
                autoComplete="email"
                {...register("email")}
              />
            </div>
            <div className="form-group">
              <label style={{ color: "white" }}>Wachtwoord</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Wachtwoord"
                required
                pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_-]).{8,}$"
                title="Minstens 1 hoofdletter, 1 kleine letter, 1 cijfer en 1 speciaal teken"
                autoComplete="new-password"
                {...register("password")}
              />
            </div>
            <button type="submit" className=" mt-4 btn customButton2 btn-lg">
              Registreer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

const styleDropDown = {
  marginRight: "3vw",
  width: "100%",
  height: "2.5rem",
  borderColor: "#ddd",
  borderRadius: "5px",
};

export default SignUp;
