import React from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
function GoBack() {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  return (
    <div>
      {
        <FontAwesomeIcon
          onClick={goBack}
          style={{ textDecoration: "none", color: "#2d5971" }}
          icon={faArrowLeft}
          size="3x"
        />
      }
    </div>
  );
}

export default GoBack;
