import React, { useEffect, useState, useContext } from "react";
import { Col, Container, FormControl, InputGroup, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import GoBack from "../../buttons/goBackButton";
import axios from "axios";
import LoadingIcon from "../../actions/LoadingIcon";
import CustomButton from "../../buttons/customButton";
import Context from "../../../contexts/DefaultContext";

function Profile() {
  let [firstName, setFirstName] = useState("");
  let [lastName, setLastName] = useState("");
  let [email, setEmail] = useState("");
  let [title, setTitle] = useState("");
  let [file, setFile] = useState();
  let [organisation, setOrganisation] = useState(0);
  let [currentPassword, setCurrentPassword] = useState();
  let [newPassword, setNewPassword] = useState();
  let userId = sessionStorage.getItem("Id");

  const { addFlashMessage, setAlert } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const token = sessionStorage.getItem("complexToken");
  const history = useHistory();

  let [defaultReminderMessage, setDefaultReminderMessage] = useState();
  let [reminderFrequency, setReminderFrequency] = useState();
  let [defaultReminderTiming, setDefaultReminderTiming] = useState();
  let [settingsId, setSettingsId] = useState();

  let [defaultMessageHint, setDefaultMessageHint] = useState(false);
  let [messageFrequencyHint, setMessageFrequencyHint] = useState(false);
  let [defaultReminderHint, setDefaultReminderHint] = useState(false);

  // krijg de data van de ingelogde gebruiker
  const getUserData = () => {
    axios
      .get(`/Users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setFirstName(res.data.firstName);
        setLastName(res.data.lastName);
        setEmail(res.data.email);
        setOrganisation(res.data.organisationId);
        setTitle(res.data.description);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
      if (sessionStorage.getItem("RoleName") === "Employer") {
        axios.get(`/Settings/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          // console.log(res.data);
          if (sessionStorage.getItem("RoleName") === "Employer" && res.data !== null && res.data.length !== 0) {
            setDefaultReminderMessage(res.data.defaultReminderMessage);
            setReminderFrequency(res.data.reminderFrequency);
            setDefaultReminderTiming(res.data.defaultReminderDays);
            setSettingsId(res.data.id);
          } else {
            setDefaultReminderMessage("De resultaten zijn nu beschikbaar");
            setReminderFrequency("7");
            setDefaultReminderTiming("0");
          }
        })
        .catch((err) => {
          console.log(err);
          setDefaultReminderMessage("De resultaten zijn nu beschikbaar");
          setReminderFrequency("7");
          setDefaultReminderTiming("0");
        });
      }
  }

  const data = {
    id: userId,
    organisationId: organisation,
    firstName: firstName,
    lastName: lastName,
    email: email,
    description: title,
  };
  const settings = {
    userId: userId,
    defaultReminderMessage: defaultReminderMessage,
    reminderFrequency: reminderFrequency,
    defaultReminderDays: defaultReminderTiming
  }

  // functie voor het updaten van de gebruiker
  async function updateHandler() {
    if (file){ // POST profile picture if chosen
      let formData = new FormData();
      formData.append('formfile', file);
      formData.append('userId', userId);
      formData.append('fileName', file.name);

      await axios
        .post(`Photos`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then()
        .catch((err) => {console.log(err);});
    }
    
    await axios
      .put(`/Users/${userId}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {})
      .catch((err) => {console.log(err);});

    // console.log(settings, settingsId);
    if (settingsId === undefined) {
      await axios
      .post(`/Settings`, settings, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {})
      .catch((err) => console.log(err));
    } else {
      settings.id = settingsId;
      await axios
      .put(`/Settings/${settingsId}`, settings, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {})
      .catch((err) => console.log(err));
    }

    sessionStorage.setItem("FirstName", data.firstName);
    sessionStorage.setItem("LastName", data.lastName);
    addFlashMessage("Uw profiel is succesvol gewijzigd!!", "success");
    setAlert(true);
    // Go back to previous page
    history.goBack();
  }
  async function removePfP(e) {
    e.preventDefault();
    await axios
      .delete(`Photos/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then()
      .catch((err) => console.log(err));
    getUserData();
  }

  async function updatePassword() {
    let tryData = {
      email: email,
      password: currentPassword,
    }
    let putData = {
      id: userId,
      email: email,
      currentPassword: currentPassword,
      newPassword: newPassword,
    }
    if (currentPassword === undefined || currentPassword === "" || newPassword === undefined || newPassword === "") {
      addFlashMessage("Vul alle wachtwoordvelden in." , "warning");
      setAlert(true);
    } else if (currentPassword === newPassword) {
      addFlashMessage("Nieuw wachtwoord moet verschillend zijn van huidig wachtwoord" , "warning");
      setAlert(true);
    } else {
      try {
        await axios.post(
          "/Users/Authenticate", tryData
        );
        await axios.put(
          `/Users/Password/${userId}`, putData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        ).then((res) => {
          addFlashMessage("Uw wachtwoord is veranderd.", "success");
          setAlert(true);
        }).catch((err) => {
          console.log(err);
          addFlashMessage("Nieuw wachtwoord ongeldig of er zijn problemen met de server.", "danger");
          setAlert(true);
        })
          
      } catch (error) {
          addFlashMessage("Huidig wachtwoord is incorrect." , "danger");
          setAlert(true);
      }
    }
  }

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div>
      {isLoading ? (
        <div style={{ marginTop: "40vh" }}>
          <LoadingIcon />
        </div>
      ) : (
        <div>
          <form>
          <Row style={{marginTop: "2rem"}}>
                <Col>
                  <h3>Profiel</h3>
                </Col>
              </Row>
            <Row style={{marginBottom: "1rem"}}>
              <Col className="tekstNaamInstelling" style={style}>
                <label>Profielfoto</label>
              </Col>
              <Col className="tekstNaamInstelling" style={style}>
                <InputGroup>
                  <FormControl
                    type="file"
                    aria-label="file"
                    onChange={(e) => {setFile(e.target.files[0]);}}
                    accept="image/*"
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col className="tekstNaamInstelling" style={style}>
                <label>Voornaam</label>
              </Col>
              <Col style={styleInput}>
                <InputGroup>
                  <FormControl
                    defaultValue={firstName || ""}
                    type="text"
                    aria-label="Firstname"
                    aria-describedby=""
                    onChange={(e) => setFirstName(e.target.value)}
                  ></FormControl>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col className="tekstNaamInstelling" style={style}>
                <label>Familienaam</label>
              </Col>
              <Col style={styleInput}>
                <InputGroup>
                  <FormControl
                    defaultValue={lastName || ""}
                    type="text"
                    aria-label="Lastname"
                    aria-describedby=""
                    onChange={(e) => setLastName(e.target.value)}
                  ></FormControl>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col className="tekstNaamInstelling" style={style}>
                <label>Titel</label>
              </Col>
              <Col style={styleInput}>
                <InputGroup>
                  <FormControl
                    defaultValue={title || ""}
                    type="text"
                    aria-label="Titel"
                    aria-describedby=""
                    onChange={(e) => setTitle(e.target.value)}
                  ></FormControl>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col className="tekstNaamInstelling" style={style}>
                <label>Email</label>
              </Col>
              <Col style={styleInput}>
                <InputGroup>
                  <FormControl
                    defaultValue={email || ""}
                    type="text"
                    aria-label="Email"
                    aria-describedby=""
                    onChange={(e) => setEmail(e.target.value)}
                  ></FormControl>
                </InputGroup>
              </Col>
            </Row>
          </form>
          {sessionStorage.getItem("RoleName") === "Employer" && defaultReminderMessage !== undefined ?  (
            <div>
              <Row style={{marginTop: "2rem"}}>
                <Col>
                  <h3>Instellingen</h3>
                </Col>
              </Row>
              <Row
                onMouseEnter={() => {setDefaultMessageHint(true);}}
                onMouseLeave={() => {setDefaultMessageHint(false);}}
                >
                <Col className="tekstNaamInstelling" style={style}>
                  <label>Standaard bericht (meldingen)</label>
                  <p
                    style={{
                      visibility: defaultMessageHint ? "visible" : "hidden",
                      position: "absolute",
                      zIndex: "1",
                      marginTop: "-5rem",
                      marginLeft: "3rem",
                      marginRight: "45%",
                      fontSize: "medium",
                    }}
                    className="alert alert-primary" role="alert"
                    >
                    Wat er standaard in de melding komt te staan, eens een quiz is afgelopen
                  </p>
                </Col>
                <Col style={styleInput}>
                  <InputGroup>
                    <FormControl
                      defaultValue={defaultReminderMessage || ""}
                      type="text"
                      aria-label="Standaard herinneringsbericht"
                      aria-describedby=""
                      onChange={(e) => {setDefaultReminderMessage(e.target.value);}}
                      ></FormControl>
                  </InputGroup>
                </Col>
              </Row>
              <Row
                onMouseEnter={() => {setDefaultReminderHint(true);}}
                onMouseLeave={() => {setDefaultReminderHint(false);}}
                >
                <Col className="tekstNaamInstelling" style={style}>
                  <label>Standaard melding tijd</label>
                  <p
                    style={{
                      visibility: defaultReminderHint ? "visible" : "hidden",
                      position: "absolute",
                      zIndex: "1",
                      marginTop: "-3rem",
                      marginLeft: "3rem",
                      marginRight: "45%",
                      fontSize: "medium",
                    }}
                    className="alert alert-primary" role="alert"
                    >
                    Aantal dagen na de quiz dat u een herinnering wenst te ontvangen
                  </p>
                </Col>
                <Col style={styleInput}>
                  <InputGroup>
                    <FormControl
                      defaultValue={defaultReminderTiming}
                      type="number"
                      min="-30"
                      max="100"
                      aria-label="Standaard herinnering tijd"
                      aria-describedby=""
                      onChange={(e) => {setDefaultReminderTiming(e.target.value);}}
                      ></FormControl>
                  </InputGroup>
                </Col>
              </Row>
              <Row
                onMouseEnter={() => {setMessageFrequencyHint(true);}}
                onMouseLeave={() => {setMessageFrequencyHint(false);}}
                >
                <Col className="tekstNaamInstelling" style={style}>
                  <label>Melding frequentie (aantal dagen)</label>
                  <p
                    style={{
                      visibility: messageFrequencyHint ? "visible" : "hidden",
                      position: "absolute",
                      zIndex: "1",
                      marginTop: "-3rem",
                      marginLeft: "3rem",
                      marginRight: "45%",
                      fontSize: "medium",
                    }}
                    className="alert alert-primary" role="alert"
                    >
                    Hoeveel dagen er zijn tussen de laatste quiz en een herinneringsmelding
                  </p>
                </Col>
                <Col style={styleInput}>
                  <InputGroup>
                    <FormControl
                      defaultValue={reminderFrequency}
                      type="number"
                      min="-30"
                      max="100"
                      aria-label="Herinnering frequentie"
                      aria-describedby=""
                      onChange={(e) => {setReminderFrequency(e.target.value);}}
                      ></FormControl>
                  </InputGroup>
                </Col>
              </Row>
            </div>
          ) : (
            <></>
          )}
          <Row style={styleRow}>
            <Col sm={1} md={2} lg={2}>
              <GoBack />
            </Col>
            <Col sm={6} md={6} lg={6}>
              <form onSubmit={removePfP}>
                <CustomButton name="Profielfoto verwijderen" />
              </form>
            </Col>
            <Col sm={4} md={4} lg={4}>
              <CustomButton handler={updateHandler} name="Opslaan" />
            </Col>
          </Row>
          <Row style={{marginTop: "2rem"}}>
            <Col>
              <h3>Wachtwoord veranderen</h3>
            </Col>
          </Row>
          <Row>
            <Col className="tekstNaamInstelling" style={style}>
              <label>Huidig wachtwoord</label>
            </Col>
            <Col style={styleInput}>
              <InputGroup>
                <FormControl
                  id="currentPassword"
                  type="password"
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  ></FormControl>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col className="tekstNaamInstelling" style={style}>
              <label>Nieuw wachtwoord</label>
            </Col>
            <Col style={styleInput}>
              <InputGroup>
                <FormControl
                  id="newPassword"
                  type="password"
                  onChange={(e) => setNewPassword(e.target.value)}
                  ></FormControl>
              </InputGroup>
            </Col>
          </Row>
          <Row style={{marginBottom: "2rem"}}>
            <Col>
              <CustomButton
                name="Nieuw wachtwoord indienen"
                handler={updatePassword}
                />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
const style = {
  display: "flex",
  justifyContent: "left",
  marginLeft: "6vw",
};
const styleInput = {
  marginLeft: "6vw",
  marginRight: "4vw",
  width: "80%",
};
const styleRow = {
  position: "relative",
};
export default Profile;
