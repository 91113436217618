import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { InputGroup, FormControl, Container, Col, Row } from "react-bootstrap";
import { nlBE } from "date-fns/locale";
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import ChallengeContainer from "./ChallengeContainer";
import Questions from "../questionSection/Questions";
import GoBack from "../../buttons/goBackButton";
import CustomButtonLarge from "../../buttons/customButtonLarge";
import Context from "../../../contexts/DefaultContext";
import CustomButton from "../../buttons/customButton";

function ChallengeCreate() {
  const { addFlashMessage, setAlert } = useContext(Context);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [isTimed, setIsTimed] = useState(false);
  const [time, setTime] = useState({hours: 0, minutes: 0, seconds: 30});

  // functie voor het verzenden van de info van de quiz
  async function handleSubmit(e) {
    e.preventDefault();
    // check of de titel ingevuld is
    if (
      sessionStorage.getItem("titel") === undefined ||
      sessionStorage.getItem("titel").trim() === ""
    ) {
      addFlashMessage("Gelieve de quiz een titel te geven.", "danger");
      setAlert(true);
      // check of het aantal vragen meer dan 0 is
    } else if (
      sessionStorage.getItem("aantalVragen") < 2 ||
      sessionStorage.getItem("aantalVragen") === undefined ||
      sessionStorage.getItem("aantalVragen") === ""
    ) {
      addFlashMessage(
        "Gelieve de quiz 2 of meer vragen te geven.",
        "danger"
      );
      setAlert(true);
      // check of een correcte periode is ingegeven
    } else if (
      startDate === undefined ||
      startDate === "" ||
      endDate === undefined ||
      endDate === ""
    ) {
      addFlashMessage("Gelieve een geldige periode aan te duiden.", "danger");
      setAlert(true);
    } else if (
      startDate.toString() === endDate.toString() && (startTime === undefined || startTime === "" || endTime === undefined || endTime === "")
    ) {
      addFlashMessage("Gelieve een geldig tijdstip in te vullen, of minstens 1 dag verschil", "danger")
      setAlert(true);
    } else if (
      startTime !== undefined &&
      startTime !== "" &&
      endTime !== undefined &&
      endTime !== "" &&
      startDate.toString() === endDate.toString() &&
      endTime <= startTime
    ) {
      //End Time earlier than starting time
      addFlashMessage("Gelieve een geldige tijdspanne te kiezen", "danger");
      setAlert(true);
    } else if (
      isTimed &&
      time.hours * 60 * 60 + time.minutes * 60 + time.seconds < 10 * aantal
    ) {
      addFlashMessage("Geef minstens 10 seconden per vraag om de quiz in te vullen", "danger");
      setAlert(true);
    } else {
      let start = startDate;
      let end = endDate;

      // Add time to the dates if filled in
      if (startTime !== undefined && startTime !== "" && endTime !== undefined && endTime !== "") {
        start.setHours(startTime.slice(0, 2));
        start.setMinutes(startTime.slice(3, 5));
        end.setHours(endTime.slice(0, 2));
        end.setMinutes(endTime.slice(3, 5));
      }
      
      // stuur deze data naar de database via de api.
      const data = {
        title: sessionStorage.getItem("titel"),
        subjectId: sessionStorage.getItem("subjectId"),
        startDate: start,
        endDate: end,
        isTimed: isTimed,
        time: isTimed ? (time.hours + ":" + time.minutes + ":" + time.seconds) : null
      };
      const token = sessionStorage.getItem("complexToken");
      await axios
        .post("/Challenges", data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          sessionStorage.setItem("challengeId", res.data.id);
        })
        .catch((err) => {
          console.log(err);
          addFlashMessage("Uw account is niet toegestaan om quizzen aan te maken. U wordt zometeen naar de homepagina verwezen.", "warning");
          setAlert(true);
          setTimeout(() => {
            history.push("/HomePage");
            window.location.reload();
          }, 4000);
        });
      const data2 = {
        UserId: sessionStorage.getItem("Id"),
        ChallengeId: sessionStorage.getItem("challengeId"),
        IsFinished: false,
      };
      await axios
        .post("/UserChallenges", data2, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          goToVragen();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const history = useHistory();
  function goToVragen() {
    history.push("/Questions");
  }
  const aantal = sessionStorage.getItem("aantalVragen");
  var aantalV = [];
  for (var i = 0; i < aantal; i++) {
    aantalV.push(<Questions />);
  }
  const style = {
    marginTop: "1rem",
  };
  // redirect naar studentenlijst
  const GoEmployeeList = () => {
    history.push("/EmployeeList");
  };

  return (
    <Container>
      <h2>{sessionStorage.getItem("subjectName")}</h2>
      <div style={{ marginBottom: "1rem" }}>
        <CustomButton handler={GoEmployeeList} name="Update Deelnemerlijst" />
      </div>
      <ChallengeContainer />
      <Row>
        <Col sm={12} md={6}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            minimumDate={new Date()}
            minimumLength={0}
            format="dd MMM yyyy"
            locale={nlBE}
          >
            {({ startDateInputProps, endDateInputProps, focus }) => (
              <div style={{ position: "" }} className="date-range   ">
                <Row>
                  <InputGroup style={{ width: "40%" }}>
                    <FormControl
                      className={
                        "input" + (focus === START_DATE ? " -focused" : "")
                      }
                      {...startDateInputProps}
                      placeholder="Start datum"
                    />
                  </InputGroup>
                  <InputGroup style={{ width: "40%" }}>
                    <FormControl
                      className={
                        "input" + (focus === END_DATE ? " -focused" : "")
                      }
                      {...endDateInputProps}
                      placeholder="Eind datum"
                    />
                  </InputGroup>
                  
                </Row>
              </div>
            )}
          </DateRangePicker>
        </Col>
        <Col>
          <InputGroup>
            Start tijd 
            <input type="time"
              onChange={(e) => setStartTime(e.target.value)}
              style={{marginLeft: "0.5rem", marginRight: "0.5rem", border: "none", backgroundColor: "#EEE", textAlign: "center"}}
              />
            Eind tijd 
            <input type="time"
              onChange={(e) => setEndTime(e.target.value)}
              style={{marginLeft: "0.5rem", marginRight: "0.5rem", border: "none", backgroundColor: "#EEE", textAlign: "center"}}
              />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6" style={{marginTop: "8px", height: "1.5rem", marginBottom: "8rem"}}>
          <label>
            <input type="checkbox" onChange={(e) => {setIsTimed(e.target.checked);}} />
            Deze quiz moet binnen een bepaalde tijd worden ingevuld
          </label>
        </Col>
        {isTimed ? 
        <Col md="6">
          <InputGroup>
            <input type="number" min="00" max="24"
              onChange={(e) => {setTime({hours: Math.min(Math.max(e.target.value, 0), 24), minutes: time.minutes, seconds: time.seconds});}}
              value={time.hours}
              style={{border: "none", backgroundColor: "#EEE", textAlign: "right"}}
              /> 
              uren, &nbsp;
            <input type="number" min="00" max="59"
              onChange={(e) => {setTime({hours: time.hours, minutes: Math.min(Math.max(e.target.value, 0), 59), seconds: time.seconds});}}
              value={time.minutes}
              style={{border: "none", backgroundColor: "#EEE", textAlign: "right"}}
              /> 
              minuten, &nbsp;
            <input type="number" min="00" max="59"
              onChange={(e) => {setTime({hours: time.hours, minutes: time.minutes, seconds: Math.min(Math.max(e.target.value, 0), 59)});}}
              value={time.seconds}
              style={{border: "none", backgroundColor: "#EEE", textAlign: "right"}}
              /> 
              seconden &nbsp;
            {time.hours * 60 * 60 + time.minutes * 60 + time.seconds < 10 * aantal ? 
              <p style={{backgroundColor:"#FFA500", paddingLeft: "8px", paddingRight: "8px", borderRadius: "5px", fontSize: "large"}}>Geef de gebruikers minstens 10 seconden per vraag om een quiz in te vullen</p>
              :
              ""
            }
          </InputGroup>
        </Col> : <Col md="6"></Col>
        }
      </Row>
      <div style={style}>
        <Row>
          <Col>
            <CustomButtonLarge handler={handleSubmit} name="Verzenden" />
          </Col>
        </Row>
        <Row style={{ marginTop: "5vh", marginLeft: "-5vw" }} lg={6} md={6}>
          <GoBack />
        </Row>
      </div>
    </Container>
  );
}

export default ChallengeCreate;
