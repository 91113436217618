import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "./style.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCalendar,
  faCheckSquare,
  faCheckCircle,
  faExclamationCircle,
  faTrash,
  faUserCircle,
  faArrowLeft,
  faArrowRight,
  faAngleDown,
  faChartBar,
  faClipboardList,
  faClipboardCheck,
  faPlusSquare,
  faUserCheck,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Header from "./components/header/Header.js";
import SignIn from "./components/sign-in sign-up/Sign-in";
import SignUp from "./components/sign-in sign-up/Sign-up";
import axios from "axios";
import HomePageUser from "./components/mainSection/homeSection/HomePageUser";
import HomePageCoach from "./components/mainSection/homeSection/HomePageCoach";
import EmployeeList from "./components/mainSection/createChallenge/EmployeeList";
import ChooseSubject from "./components/mainSection/chooseSubjectSection/ChooseSubject";
import ChallengeCreate from "./components/mainSection/createChallenge/Challenge";
import Questions from "./components/mainSection/questionSection/Questions";
import AlertMessage from "./components/actions/Alert";
import UpdateQuestions2 from "./components/mainSection/questionSection/UpdateQuestions2";
import AvailableQuizes from "./components/mainSection/homeSection/AvailableQuizesPerSubject";
import AvailableQuizesCoach from "./components/mainSection/homeSection/AvailableQuizesCoach";
import Dashboard from "./components/mainSection/dashboardSection/Dashboard";
import DashboardMood from "./components/mainSection/dashboardSection/DashboardMood";
import DashboardEmployee from "./components/mainSection/dashboardSection/DashboardEmployee";
import uniqid from "uniqid";
import Profile from "./components/mainSection/profileSection.js/Profile";
import DefaultContext from "./contexts/DefaultContext";
import DashboardTop from "./components/mainSection/dashboardSection/DashboardTop";
import DashboardEmployeeMood from "./components/mainSection/dashboardSection/DashboardEmployeeMood";
import DashboardEmployeeMoodAll from "./components/mainSection/dashboardSection/DashboardEmployeeMoodAll";
import DashboardMoodTop from "./components/mainSection/dashboardSection/DashboardMoodTop";
import ReminderSet from "./components/mainSection/createChallenge/ReminderSet";
import FeedbackWebApp from "./components/mainSection/othersSection/FeedbackWebApp";
import UserList from "./components/mainSection/othersSection/UserList";
// Elk icoon van FontAwesome moet voor gebruik geïmporteerd worden en dan in de library hieronder ook gezet worden.
library.add(
  fab,
  faCheckSquare,
  faCheckCircle,
  faExclamationCircle,
  faUserCircle,
  faTrash,
  faCalendar,
  faArrowLeft,
  faArrowRight,
  faAngleDown,
  faChartBar,
  faClipboardList,
  faClipboardCheck,
  faPlusSquare,
  faUserCheck,
  faUser
);
// axios baseURL hangt af van wat de url is van de API, dit is een lokale versie en dus om lokaal te testen.
axios.defaults.baseURL = process.env.REACT_APP_API_ADDR;
// De function App initialiseert meldingen maar ook de routing. Elke nieuwe pagina of wijziging moet dus eerst hier worden gedefinieerd.
function App() {
  const [flashMessages, setFlashMessages] = useState("");
  const [variantFlash, setVariantFlash] = useState("");
  const [alert, setAlert] = useState(false);
  function addFlashMessage(message, variant) {
    setFlashMessages(message);
    setVariantFlash(variant);
  }

  return (
    <div className="App">
      <DefaultContext.Provider
        value={{ setFlashMessages, addFlashMessage, alert, setAlert }}
      >
        <Router>
          <AlertMessage messages={flashMessages} variant={variantFlash} />
          <Header />

          <Switch>
            <Route path="/Sign-in">
              <SignIn />
            </Route>
            <Route path="/" exact>
              <SignIn />
            </Route>
            <Route path="/Sign-up">
              <SignUp />
            </Route>

            <Route key={uniqid()} path="/HomePage" component={HomePageUser} />

            <Route path="/ChooseSubject">
              <ChooseSubject />
            </Route>
            <Route path="/ChallengeCreate" component={ChallengeCreate} />
            <Route path="/Questions" component={Questions} />
            <Route
              path="/DashboardMood"
              component={DashboardMood}
              key={uniqid()}
            />
            <Route
              path="/UpdateQuestions2"
              key={uniqid()}
              component={UpdateQuestions2}
            />
            <Route path="/Dashboard" key={uniqid()} component={Dashboard} />
            <Route
              path="/DashboardTop"
              key={uniqid()}
              component={DashboardTop}
            />
            <Route
              path="/DashboardMoodTop"
              key={uniqid()}
              component={DashboardMoodTop}
            />
            <Route
              path="/DashboardEmployee"
              key={uniqid()}
              component={DashboardEmployee}
            />
            <Route
              path="/DashboardEmployeeMood"
              key={uniqid()}
              component={DashboardEmployeeMood}
            />
            <Route
              path="/DashboardEmployeeMoodAll"
              key={uniqid()}
              component={DashboardEmployeeMoodAll}
            />
            <Route
              path="/AvailableQuizesPerSubject"
              component={AvailableQuizes}
            />
            <Route
              path="/AvailableQuizesCoach"
              component={AvailableQuizesCoach}
            />
            <Route path="/Profile" component={Profile} />
            <Route path="/EmployeeList" component={EmployeeList} />
            <Route path="/HomePageCoach" component={HomePageCoach} />

            <Route path="/ReminderSet" component={ReminderSet} />

            <Route path="/FeedbackWebApp" component={FeedbackWebApp} />
            <Route path="/UserList" component={UserList} />

            <Route path="/">
              <SignIn />
            </Route>
          </Switch>
        </Router>
      </DefaultContext.Provider>
    </div>
  );
}

export default App;
