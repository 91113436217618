import React, { useState, useContext } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import Context from "../../contexts/DefaultContext";
import { Container, Row, Col } from "react-bootstrap";

function SignIn() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const { addFlashMessage, setAlert } = useContext(Context);

  const data = {
    email: email,
    password: password,
  };

  function handleSubmit(e) {
    e.preventDefault();

    axios
      .post("/Users/authenticate", data)
      .then((res) => {
        if (
          res.data.roles[0] === "SuperAdmin" ||
          res.data.roles[0] === "Admin" ||
          res.data.roles[0] === "Employer" ||
          res.data.roles[0] === "Coach"
        ) {
          sessionStorage.setItem("FirstName", res.data.voornaam);
          sessionStorage.setItem("LastName", res.data.familienaam);
          sessionStorage.setItem("complexToken", res.data.jwtToken);
          sessionStorage.setItem("OrganisationId", res.data.organisation);
          sessionStorage.setItem("RoleName", res.data.roles[0]);
          sessionStorage.setItem("Id", res.data.id);
          sessionStorage.setItem("Email", res.data.email);
          sessionStorage.setItem("LockedOut", res.data.lockoutEnabled);

          if (res.data.lockoutEnabled) {
            addFlashMessage("Uw account is gepauzeerd, u kan gegevens bekijken, maar niets aanmaken, updaten of verwijderen.", "warning");
            setAlert(true);

            const timer = setTimeout(() => {
              goToStart();
              const timer2 = setTimeout(() => {
                refreshPage();
              }, 2000); //Refresh Page (so the header updates) after 2 more seconds
            }, 2000); //Go to Start after 2 seconds
          } else {
            goToStart();
            refreshPage();
          }

          
          
        } else {
          addFlashMessage("Je account is nog niet goedgekeurd!", "danger");
          setAlert(true);
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            addFlashMessage("Email en/of wachtwoord is niet correct!", "danger");
            setAlert(true);
          } else {
            addFlashMessage(
              "Er is iets fout gelopen. Probeer later opnieuw!",
              "danger"
            );
            setAlert(true);
          }
        } else {
          addFlashMessage(
            "Er is iets fout gelopen met de server. Probeer later opnieuw!",
            "danger"
          );
          setAlert(true);
        }
      });
  }

  const history = useHistory();
  function goToStart() {
    history.push("/HomePage");
  }

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className="App">
      <Container>
        <div className="outer">
          <div className="inner">
              <form onSubmit={handleSubmit}>
                <Row>
                  <h3>
                    <Col
                      className="text-signUp"
                      style={{ color: "white", fontSize: "25px" }}
                      >
                      Log in
                    </Col>
                    <Col md={2}></Col>
                    <Col>
                      <Link
                        style={{ color: "white", fontSize: "25px" }}
                        className="button-signUp"
                        to="/sign-up"
                        >
                        Registreer
                      </Link>
                    </Col>
                  </h3>
                </Row>
                <div className="form-group">
                  <label style={{ color: "white" }}>Email</label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    required
                    className="form-control"
                    placeholder="Email"
                    autoComplete="email"
                  />
                </div>
                <div className="form-group mt-3">
                  <label style={{ color: "white" }}>Wachtwoord</label>
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    required
                    className="form-control"
                    placeholder="Wachtwoord"
                    autoComplete="current-password"
                  />
                </div>
                <button
                  type="submit"
                  className="btn mt-5 btn-lg customButton2"
                  style={{ fontWeight: "bold" }}
                >
                  Log in
                </button>
              </form>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default SignIn;
