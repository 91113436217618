import React from "react";

function CustomButton(props) {
  return (
    <>
      <button
        onClick={props.handler}
        style={style}
        value={props.value}
        >
        {props.name}
      </button>
    </>
  );
}
const style = {
  backgroundColor: "#2d5971",
  color: "white",
  borderRadius: "5px ",
  borderStyle: "hidden",
  outline: "none",
  border: "#2d5971",
  height: "2.5em",
  padding: "0.5em 2em 2em 2em",
};
export default CustomButton;
