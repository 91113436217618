import React, { useEffect, useState } from "react";
import { Col, Row, Container, Table, Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
import uniqid from "uniqid";
import LoadingIcon from "../../actions/LoadingIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCheckCircle,
  faClipboardCheck,
  faClipboardList,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Pie, Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
} from "chart.js";
// register de nodige elementen van ChartJS
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement
);

function DashboardEmployeeMoodAll() {
  const token = sessionStorage.getItem("complexToken");
  const employeeId = sessionStorage.getItem("employeeId");
  let [subjectsLoading, setSubjectsLoading] = useState(true);
  let [employeeLoading, setEmployeeLoading] = useState(true);
  let [moodsLoading, setMoodsLoading] = useState(true);
  const history = useHistory();
  let [subjectChallenges, setSubjectChallenges] = useState([]);
  let [employeeResults, setEmployeeResults] = useState([]);
  let [employee, setEmployee] = useState([]);
  let [employeeMoodResultNames] = useState([]);
  let [employeeMoodResult] = useState([]);
  let [employeeMoodLabel] = useState([]);
  let [allSubjects, setAllSubjects] = useState([]);
  let [totaalJuist, setTotaalJuist] = useState(0);
  let [moods, setMoods] = useState([]);
  let [dataPie] = useState([]);
  let [dataLineScores] = useState([]);
  let [dataLineAttendance] = useState([]);
  let [ChallengeNames] = useState([]);
  let [AttendanceScores] = useState([]);
  let [colorsMood] = useState([]);
  let [count] = useState(0);
  let [percent] = useState(0);
  let [countAttend] = useState(0);
  let [employeeMoodTable, setEmployeeMoodTable] = useState([]);

  useEffect(function () {
    getEmployee();
    getMood();
  }, []);

  // krijg de data van de Employee
  const getEmployee = async() => {
    await Axios.get(`/Users/${employeeId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      //console.log(response.data);
      setEmployee(response.data);
      setEmployeeLoading(false);
      setAllSubjects(response.data.userSubjects);
      totaalJuist = 0;
      setTotaalJuist(0);
      employeeResults = [];
      subjectChallenges = [];
      // voor elk onderwerp waar de Employee is ingeschreven halen we resultaten op en tellen we het aantal juiste antwoorden en voegen we dit aan een grote array
      response.data.userSubjects.forEach((element, index) => {
        Axios.get(
          `/Results/Employee/${employeeId}/Subject/${element.subjectId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          response.data.forEach((element) => {
            setTotaalJuist((totaalJuist) => totaalJuist + element.score);
            setEmployeeResults((employeeResults) => [
              ...employeeResults,
              element,
            ]);
          });
        })
        .catch((error) => console.log(error));
        // voor elk onderwerp halen we de data van de quizes op en zetten deze in 1 grote array
        Axios.get(`/Challenges/Subjects/${element.subjectId}/WebApp`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          response.data.forEach((element) => {
            setSubjectChallenges((subjectChallenges) => [
              ...subjectChallenges,
              element,
            ]);
          });
        })
        .catch((error) => console.log(error));
      });
      setSubjectsLoading(false);
    })
    .catch((error) => console.log(error));
  };

  // krijg alle moods van de Employee, voor elk vak dat hij/zij volgt
  const getMood = () => {
    Axios.get(`/Moods/User/${employeeId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setMoods(response.data);
        setMoodsLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const goBack = () => {
    history.goBack();
  };

  //Switch om de emoji's uit de database terug om te zetten in emoji's
  const emojiPicker = (emoji) => {
    switch (emoji) {
      case "InLove":
        return "🥰";
      
      case "Easy":
        return "😎";
      
      case "Funny":
        return "🤣";

      case "Good":
        return "😀";

      case "OK":
        return "🙂";

      case "Neutral":
        return "😐";

      case "Surprised":
        return "😮";

      case "Angry":
        return "😠";

      case "Sad":
        return "😥";

      case "VerySad":
        return "😭";

      case "Sick":
        return "🤮";

      default:
        return "⁉️";
    }
  };
  // switch om de emoji's om te zetten naar getallen
  const moodCalculator = (emoji) => {
    switch (emoji) {
      case "InLove":
        percent += 100;
        break;

      case "Easy":
        percent += 90;
        break;

      case "Funny":
        percent += 80;
        break;

      case "Good":
        percent += 70;
        break;

      case "OK":
        percent += 60;
        break;

      case "Neutral":
        percent += 50;
        break;

      case "Surprised":
        percent += 40;
        break;

      case "Angry":
        percent += 30;
        break;

      case "Sad":
        percent += 20;
        break;

      case "VerySad":
        percent += 10;
        break;
      
      case "Sick":
        percent += 0;
        break;
      
      default:
        percent += 50;
        break;
    }
  };
  // zet de data van aanwezigheid in de cirkeldiagram
  dataPie = {
    labels: ["niet deelgenomen", "deelgenomen"],
    datasets: [
      {
        label: "Aantal deelgenomen en niet-deelgenomen",
        data: [
          subjectChallenges.length - employeeResults.length,
          employeeResults.length,
        ],
        backgroundColor: ["rgba(255, 50, 50, 0.5)", "rgba(5, 195, 5, 0.5)"],
        borderColor: ["rgba(255, 50, 50, 1)", "rgba(5, 195, 5, 1)"],
        borderWidth: 1,
      },
    ],
  };

  // map de mooddata voor de staafdiagram
  employeeMoodResultNames = [];
  employeeMoodResult = [];
  employeeMoodLabel = [];
  let percents = [];
  let counts = [];
  employeeMoodTable = [];
  let emojiTimesUsed = []; let highest = 0; let mostUsedEmoji = "";

  moods.map((res) => {
    percent = 0;
    moodCalculator(res.employeeMood);
    if (percents[res.challengeId]) { // add score to existing score
      percents[res.challengeId] += percent;
      counts[res.challengeId]++;
    } else { // new challenge Id
      percents[res.challengeId] = percent;
      counts[res.challengeId] = 1;
      employeeMoodResultNames.push(res.challenge.title);
    }
    employeeMoodTable.push(
      <tr key={"EmployeeMoodTable" + res.id}>
        <td>{res.challenge.title}:<br/>{res.moodQuestion.question}</td>
        <td style={{fontSize: "3rem"}}>{emojiPicker(res.employeeMood)}<br/><p style={{fontSize: "1rem"}}>{percent}%</p></td>
      </tr>
    );
    // Track how many times each emoji is used
    if (emojiTimesUsed[percent/10]) emojiTimesUsed[percent/10]++; else emojiTimesUsed[percent/10] = 1;
    if (emojiTimesUsed[percent/10] > highest) {
      highest++;
      mostUsedEmoji = emojiPicker(res.employeeMood);
    }
  });

  colorsMood = [];
  percents.map((res, index) => {
    let percentage = res/counts[index];
    employeeMoodResult.push(percentage);
    percentage < 40
      ? colorsMood.push("red")
      : percentage > 60
      ? colorsMood.push("#1ed454")
      : colorsMood.push("orange");
  });

  // zet de mooddata in de staafdiagram
  dataLineScores = {
    labels: employeeMoodResultNames,
    datasets: [
      {
        backgroundColor: colorsMood,
        data: employeeMoodResult,
        fill: false,
        borderColor: "rgba(75,2,12,0)",
      },
    ],
  };

  // map de data voor de lijndiagram van aanwezigheid
  ChallengeNames = [];
  AttendanceScores = [];
  count = 0;
  countAttend = 0;
  // voor de tabel
  let attendTable = [];
  // sorteer de quizen door elkaar zodat ze allemaal chronologisch staan i.p.v. gegroepeerd per vak.
  subjectChallenges.sort(function (a, b) {
    return a.startDate < b.startDate ? -1 : a.startDate > b.startDate ? 1 : 0;
  });
  subjectChallenges.map((res) => {
    ChallengeNames.push(res.title);
    let attended = false;
    count++;
    employeeResults.forEach((element) => {
      if (element.challenge.id === res.id) {
        attended = true;
      }
    });
    if (attended === true) {
      countAttend++;
    }
    AttendanceScores.push((countAttend / count) * 100);
    attendTable.push(
      <tr key={"AttendTable" + res.id + res.subjectId}>
        <td>{res.title}</td>
        <td>{attended ? 
          <FontAwesomeIcon
            style={{
              textDecoration: "none",
              color: "#1ed454",
              paddingTop: "2.5vh",
            }}
            icon={faCheckCircle}
            size="4x"
            /> : 
          <FontAwesomeIcon
            style={{
              textDecoration: "none",
              color: "#e61c1c",
              paddingTop: "2.5vh",
            }}
            icon={faExclamationCircle}
            size="4x"
            />
          }
        </td>
      </tr>
    );
  });

  // zet de aanwezigheidsdata in de lijndiagram
  dataLineAttendance = {
    labels: ChallengeNames,
    datasets: [
      {
        label: "Aanwezigheid van test tot test uitgedrukt in %",
        data: AttendanceScores,
        fill: false,
        borderColor: "rgba(75,192,192,1)",
      },
    ],
    scales: {
      y: {
        min: 0,
        max: 100,
      },
    },
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: () => {
            "null";
          },
          label: (res) => {
            return res.formattedValue;
          },
          beforeLabel: (res) => {
            return employeeMoodLabel[res.dataIndex];
          },
        },
      },
    },
  };
  const optionsScale = {
    scales: {
      y: {
        min: 0,
        max: 100,
      },
      x: {},
    },
  };

  return (
    <div>
      <Container style={{ marginBottom: "1rem" }}>
        <Row>
          <Col sm={1} lg={1}>
            <div>
              {
                <FontAwesomeIcon
                  onClick={goBack}
                  style={{ textDecoration: "none", color: "#2d5971" }}
                  icon={faArrowLeft}
                  size="3x"
                />
              }
            </div>
          </Col>
          {employeeLoading ? (
            <div style={{ marginRight: "20vw", marginTop: "10vh" }}>
              <LoadingIcon />
            </div>
          ) : (
            <Col sm={10}>
              <h1>
                {employee.firstName} {employee.lastName}
              </h1>
              <h2>Alle Onderwerpen</h2>
            </Col>
          )}
        </Row>
      </Container>

      <Container key={uniqid()}>
        {subjectsLoading ? (
          <div style={{ marginRight: "20vw", marginTop: "10vh" }}>
            <LoadingIcon />
          </div>
        ) : (
          <div>
            <Row>
              <Col md={8}>
                <Col>
                  <Row>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          height: "90%",
                          paddingTop: "4.4vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            paddingLeft: "3vh",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              /** style om de FontAwesome iconen weer te geven als contour zonder de betalende versie te gebruiken
                               * backgroundcolor is voor de kleur rond het icoon
                               * color is dezelfde als de background om te doen alsof deze doorzichtig is
                               * stroke geven we de kleur die de contour heeft
                               * strokewidth bepaalt de breedte van de contour*/
                              textDecoration: "none",
                              color: "rgba(30, 212, 84, 0.2)",
                              marginBottom: "3vh",
                              backgroundColor: "rgba(30, 212, 84, 0.2)",
                              padding: "1.5vh 2.5vh",
                              borderRadius: "20px",
                              stroke: "rgba(30, 200, 84, 1)",
                              strokeWidth: "20px",
                            }}
                            icon={faClipboardCheck}
                            size="10x"
                          />
                          <div>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              Aantal voltooide quizzen
                            </p>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "2.5rem",
                                float: "left",
                              }}
                            >
                              {employeeResults.length}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          height: "90%",
                          paddingTop: "4.4vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            paddingLeft: "3vh",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              textDecoration: "none",
                              color: "rgba(255, 59, 121, 0.2)",
                              marginBottom: "3vh",
                              backgroundColor: "rgba(255, 59, 121, 0.2)",
                              padding: "1.5vh 2.5vh",
                              borderRadius: "20px",
                              stroke: "rgba(255, 59, 105, 1)",
                              strokeWidth: "20px",
                            }}
                            icon={faClipboardList}
                            size="10x"
                          />
                          <div>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              Aantal gemiste quizzen
                            </p>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "2.5rem",
                                float: "left",
                              }}
                            >
                              {subjectChallenges.length - employeeResults.length}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          height: "90%",
                          paddingTop: "4.4vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            paddingLeft: "3vh",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              textDecoration: "none",
                              color: "rgba(30, 212, 84, 0.2)",
                              marginBottom: "3vh",
                              backgroundColor: "rgba(30, 212, 84, 0.2)",
                              padding: "1.5vh 2.5vh",
                              borderRadius: "20px",
                              stroke: "rgba(30, 200, 84, 1)",
                              strokeWidth: "20px",
                            }}
                            icon={faClipboardCheck}
                            size="10x"
                          />
                          <div>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              Totaal juiste antwoorden
                            </p>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "2.5rem",
                                float: "left",
                              }}
                            >
                              {totaalJuist}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          height: "90%",
                          paddingTop: "4.4vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            paddingLeft: "3vh",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              textDecoration: "none",
                              color: "rgba(108, 160, 255, 0.25)",
                              marginBottom: "3vh",
                              backgroundColor: "rgba(108, 160, 255, 0.25)",
                              padding: "1.5vh 2.5vh",
                              borderRadius: "20px",
                              stroke: "#2d5971",
                              strokeWidth: "20px",
                            }}
                            icon={faClipboardList}
                            size="10x"
                          />
                          <div>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              Aantal gevolgde vakken
                            </p>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "2.5rem",
                                float: "left",
                              }}
                            >
                              {allSubjects.length}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Col>
              <Col md={4}>
                <Card
                  style={{
                    marginBottom: "5vh",
                    height: "90%",
                    paddingTop: "4.4vh",
                    paddingBottom: "4.4vh",
                    boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <Pie data={dataPie} style={{ maxHeight: "37.5vh" }}></Pie>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={employeeMoodResult.length < 8 ? 6 : 12 /*If there's too many results, split the cards into multiple Rows*/}>
                <Card
                  style={{
                    marginBottom: "5vh",
                    padding: "4.5vh",
                    boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <Bar data={dataLineScores} options={options} />
                  <p style={{ fontSize: "0.7rem" }}>
                    (Hover over de staven om de score te zien)
                  </p>
                </Card>
              </Col>
              <Col md={employeeMoodResult.length < 8 ? 6 : 12}>
                <Card
                  style={{
                    marginBottom: "5vh",
                    padding: "4.5vh",
                    boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <Line data={dataLineAttendance} options={optionsScale} />
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card
                    style={{
                      marginBottom: "5vh",
                      padding: "4.5vh",
                      boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                      overflowY: "scroll",
                      borderRadius: "0",
                      maxHeight: "40rem",
                    }}
                  >
                    <Table>
                      <thead>
                        <tr>
                          <th>Emotionele vraag</th>
                          <th>{employee.firstName + " " + employee.lastName}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employeeMoodTable}
                      </tbody>
                    </Table>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={4}>
                <Card
                  style={{
                    marginBottom: "5vh",
                    padding: "4.5vh",
                    boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                  }}
                  >
                  Meest voorkomende emoji:
                  <p style={{fontSize: "3rem"}}>{mostUsedEmoji}</p>
                </Card>
              </Col>
              <Col sm={12} md={8}>
                <Card
                  style={{
                    marginBottom: "5vh",
                    padding: "4.5vh",
                    boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                    overflowY: "scroll",
                    borderRadius: "0",
                    maxHeight: "20rem",
                  }}
                >
                  <Table>
                    <thead>
                      <tr>
                        <th>Quiz naam</th>
                        <th>Aanwezig</th>
                      </tr>
                    </thead>
                    <tbody>
                      {attendTable}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </div>
  );
}
export default DashboardEmployeeMoodAll;
