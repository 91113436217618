import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import uniqid from "uniqid";
import LoadingIcon from "../../actions/LoadingIcon";
import { Col, Row } from "react-bootstrap";

function ShowSubjectsFromEmployer() {
  const UserId = sessionStorage.getItem("Id");
  const [subjectEmployer, setSubjectEmployer] = useState([]);
  let [responseSubjects, setResponseSubjects] = useState([]);
  const [subjectsLoading, setSubjectsLoading] = useState(true);
  const token = sessionStorage.getItem("complexToken");

  useEffect(function () {
    getData();
  }, []);
  
  const getData = async() => {
    // ontvang data van de Subjects van de Employer
    await axios
      .get(`/UserSubjects/${UserId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setSubjectsLoading(false);
        setSubjectEmployer(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // map de data van de Subjects van de Employer
  responseSubjects = subjectEmployer.map((result) => (
    <div key={result.id}>
      <Row>
        {sessionStorage.getItem("LockedOut") === "true" ? <></> :
          <Col lg={6} sm={8}>
            <Link
              style={{
                textDecoration: "none",
                color: "#5094B9",
                hover: "#2d5971",
              }}
              value={result.subjectId}
              to={{ pathname: "/ChallengeCreate" }}
              onClick={() =>
                handleClick(result.subjectId, result.subject.subjectName)
              }
              className="tekstNaamInstelling ml-0 mt-3"
            >
              {result.subject.subjectName}
            </Link>
          </Col>
        }
        <Col lg={6} sm={4}>
          <Link
            style={{
              textDecoration: "none",
              color: "#5094B9",
              hover: "#2d5971",
            }}
            value={result.subjectId}
            to={{ pathname: "/AvailableQuizesPerSubject" }}
            onClick={() =>
              handleClick(result.subjectId, result.subject.subjectName)
            }
            className="tekstNaamInstelling ml-0 mt-3"
          >
            {result.subject.subjectName}
          </Link>
        </Col>
      </Row>
    </div>
  ));

  const handleClick = (id, subjectName) => {
    sessionStorage.setItem("subjectId", id);
    sessionStorage.setItem("subjectName", subjectName);
  };

  return (
    <div key={uniqid()}>
      {subjectsLoading ? (
        <div style={{ marginRight: "10vw", marginTop: "10vh" }}>
          <LoadingIcon />
        </div>
      ) : (
        <div>{responseSubjects}</div>
      )}
    </div>
  );
}

export default ShowSubjectsFromEmployer;
