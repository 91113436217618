import React, { useEffect, useState, useContext } from "react";
import Axios from "axios";
import { Container, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { useHistory, Redirect } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import GoBack from "../../buttons/goBackButton";
import CustomButton from "../../buttons/customButton";
import Context from "../../../contexts/DefaultContext";
import LoadingIcon from "../../actions/LoadingIcon";

function ChooseSubject() {
  const { addFlashMessage, setAlert } = useContext(Context);
  const token = sessionStorage.getItem("complexToken");
  const history = useHistory();
  const [selected, setSelected] = useState([]);
  let [usersLoading, setUsersLoading] = useState(true);

  let [Users, setUsers] = useState([]);
  let organisationId = sessionStorage.getItem("OrganisationId");
  let options = [];
  let id = sessionStorage.getItem("Id");

  let [naam, setNaam] = useState("");

  useEffect(function () {
    // krijg alle Employees van de correcte organisation
    Axios.get(`/Users/Organisation/${organisationId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => console.log(error));
    setUsersLoading(false);
  }, []);

  // vul de dropdown in met de Employees door deze als label hun naam te geven en waarde hun id
  Users.map((result) =>
    options.push({
      label: `${result.firstName} ${result.lastName}`,
      value: result.id,
    })
  );

  // functie om vak aan te maken met Employees eraan gekoppeld.
  async function SaveSubject(e) {
    e.preventDefault();
    let usersId = [];

    selected.forEach(myFunction);

    function myFunction(item) {
      usersId.push(item.value);
    }
    // check of het Subject een naam heeft
    if (naam === "" || naam === undefined) {
      addFlashMessage(
        "Gelieve een geldige naam te geven aan het Subject.",
        "danger"
      );
      setAlert(true);
      // check of er minstens 1 Employee het Subject volgt
    } else if (usersId.length === 0) {
      addFlashMessage("Er is geen enkele Werknemer aangeduid.", "danger");
      setAlert(true);
    } else {
      // stuur correcte data naar de database via de api door eerst de Subject aan te maken
      const data = {
        subjectName: naam
      };
      await Axios.post("/Subjects", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          sessionStorage.setItem("SubjectId", res.data.id);
        })
        .catch((err) => {});
      usersId.push(id);
      const data2 = {
        UsersId: usersId,
        SubjectId: sessionStorage.getItem("SubjectId"),
      };

      // stuur correcte data door naar api om Employees aan de nieuwe Subject te koppelen
      await Axios.post("/UserSubjects/", data2, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (res.status === 200) {
          } else {
            addFlashMessage("Er is iets foutgelopen.", "danger");
            setAlert(true);
          }
        })
        .catch((error) => console.log(error));

      const data3 = {
        OrganisationId: organisationId,
        SubjectId: sessionStorage.getItem("SubjectId"),
      };

      // stuur correcte data door naar de api om de Subject aan de Organisation te koppelen
      await Axios
        .post("/OrganisationSubjects/", data3, {
        headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.status === 200) {
            history.push("/HomePage");
            return <Redirect to="/HomePage" />;
          } else {
            addFlashMessage("Er is iets foutgelopen.", "danger");
            setAlert(true);
          }
        })
        .catch((error) => {
          if((error + "").search("Network Error")) { // 500 / Network Error can mean it has been added successfully too
            history.push("/HomePage");
            return <Redirect to="/HomePage" />;
          }
          console.log(error);
        });

      GoBack();
    }
  }

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div className="tekstNaamInstelling pb2">
              Onderwerp aanmaken:
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Col md={4}>
              <InputGroup style={{ width: "100%" }} className="mb-4">
                <FormControl
                  placeholder="Onderwerp naam"
                  aria-label="naam"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setNaam(e.target.value.trim())}
                />
              </InputGroup>
            </Col>
            <Col md={8}>
              {usersLoading ? <LoadingIcon /> :
                <InputGroup className="mb-4">
                  <MultiSelect
                    options={options}
                    value={selected}
                    onChange={setSelected}
                    labelledBy="Select"
                  />
                </InputGroup>
              }
            </Col>
            <InputGroup style={{ width: "100%" }}>
              <CustomButton handler={SaveSubject} name="Opslaan" />
            </InputGroup>
          </Col>
        </Row>
        <Row style={styleRow}>
          <Col style={styleBack} sm={6} md={6} lg={6}>
            <GoBack />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const styleBack = {
  position: "absolute",
  top: "10vh",
  display: "flex",
};
const styleRow = {
  position: "relative",
};

export default ChooseSubject;
