import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useHistory, Redirect } from "react-router-dom";
import { Container, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import QuestionInput from "./CustomInputField";
import CustomButton from "../../buttons/customButton";
import useToggle from "../../../customUseHooks/useToggle";

function UpdateQuestions2() {
  let idChallenge = sessionStorage.getItem("challengeId");
  const [challengeData, setChallengeData] = useState(() => []);
  let [teller, setTeller] = useState(0);
  const token = sessionStorage.getItem("complexToken");
  var aantalQuestions = [];
  let [clickButton, setClickButton] = useState(false);
  let [clickButton2, setClickButton2] = useState(false);
  let [clickButton3, setClickButton3] = useState(false);
  let [value, toggleValue] = useToggle(true);
  let [value2, toggleValue2] = useToggle(true);
  let [value3, toggleValue3] = useToggle(true);
  let [textColor, setTextColor] = useState("white");
  let [textColor2, setTextColor2] = useState("white");
  let [textColor3, setTextColor3] = useState("white");
  let [antwoord, setAntwoord] = useState("");
  let [antwoord2, setAntwoord2] = useState("");
  let [antwoord3, setAntwoord3] = useState("");
  let [vraagStelling, setVraagStelling] = useState("");
  let [errorValue, setErrorValue] = useState("");
  let [hasFile, setHasFile] = useState();
  let [fileChanged, setFileChanged] = useState(false);
  let [file, setFile] = useState();
  let [fileName, setFileName] = useState();
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    // krijg de data van de quiz om deze hierna aan te kunnen passen
    await axios
      .get(`/Challenges/${idChallenge}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setChallengeData(response.data.questions);
        setVraagStelling(response.data.questions[teller].questionText);
        setFile(response.data.questions[teller].url);
        setFileName(response.data.questions[teller].fileName);
        setFileChanged(false);
        setHasFile(false);
        if (response.data.questions[teller].questionType === 1) {
          setHasFile(true);
        }
        setAntwoord(response.data.questions[teller].answers[0].possibleAnswer);
        setAntwoord2(response.data.questions[teller].answers[1].possibleAnswer);
        setAntwoord3(response.data.questions[teller].answers[2].possibleAnswer);
        setClickButton(
          response.data.questions[teller].answers[0].correctAnswer
        );
        setClickButton2(
          response.data.questions[teller].answers[1].correctAnswer
        );
        setClickButton3(
          response.data.questions[teller].answers[2].correctAnswer
        );
        setTextColor(
          response.data.questions[teller].answers[0].correctAnswer
            ? "#83BBBA"
            : "white"
        );
        setTextColor2(
          response.data.questions[teller].answers[1].correctAnswer
            ? "#83BBBA"
            : "white"
        );
        setTextColor3(
          response.data.questions[teller].answers[2].correctAnswer
            ? "#83BBBA"
            : "white"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // map van de vragen uit de quiz die ingevuld zijn met de huidige data
  aantalQuestions = challengeData.map((result) => (
    <Container key={result.id}>
      <Row>
        <h5 className="mb-4">Vraag {teller + 1}</h5>
        <QuestionInput
          value={result.questionText}
          handler={(e) => setVraagStelling(e.target.value.trim())}
        />
        <Row>
          <Col>
            <label>
              <input type="checkbox" onChange={(e) => {setHasFile(e.target.checked);}} style={{marginBottom: "1%"}} checked={hasFile} />
              Deze vraag heeft een afbeelding.
            </label>
          </Col>
          <Col>
            {hasFile ? (
              <InputGroup>
                  <FormControl
                    type="file"
                    aria-label="file"
                    onChange={(e) => {setFile(e.target.files[0]); setFileChanged(true);}}
                    accept="image/*"
                    />
                  {file !== undefined && <img alt="Verduidelijking van de vraag" src={fileChanged ? URL.createObjectURL(file) : file} style={{marginBottom:"5rem", maxHeight: "30rem", maxWidth: "30rem"}} />}
              </InputGroup>
              ) : (<div></div>)
            }
          </Col>
        </Row>
        <h5 className="mb-3">Mogelijke antwoorden : </h5>
        <Fragment>
          <QuestionInput
            value={result.answers[0].possibleAnswer}
            style={{ backgroundColor: textColor }}
            clickHandler={(e) => click(e)}
            handler={(e) => setAntwoord(e.target.value.trim())}
          />
          <QuestionInput
            value={result.answers[1].possibleAnswer}
            style={{ backgroundColor: textColor2 }}
            clickHandler={(e) => click2(e)}
            handler={(e) => setAntwoord2(e.target.value.trim())}
          />
          <QuestionInput
            value={result.answers[2].possibleAnswer}
            style={{ backgroundColor: textColor3 }}
            clickHandler={(e) => click3(e)}
            handler={(e) => setAntwoord3(e.target.value.trim())}
          />
        </Fragment>
      </Row>
    </Container>
  ));
  let aantalComponenten = [];
  aantalQuestions.forEach((element) => {
    aantalComponenten.push(element);
  });
  // functie om huidige vraag op te slaan en door te gaan naar de volgende
  async function nextQuestion() {
    if (teller + 1 === aantalQuestions.length) {
      history.push("ReminderSet");
    } else {
      let data = {
        id: challengeData[teller].id,
        questionText: vraagStelling,
        challengeId: challengeData[teller].challengeId,
        answers: [
          {
            id: challengeData[teller].answers[0].id,
            possibleAnswer: antwoord,
            correctAnswer: clickButton,
            questionId: challengeData[teller].id,
          },
          {
            id: challengeData[teller].answers[1].id,
            possibleAnswer: antwoord2,
            correctAnswer: clickButton2,
            questionId: challengeData[teller].id,
          },
          {
            id: challengeData[teller].answers[2].id,
            possibleAnswer: antwoord3,
            correctAnswer: clickButton3,
            questionId: challengeData[teller].id,
          },
        ],
        questionType: hasFile ? 1 : 0,
        fileName: fileName,
      };
      let formData = new FormData();
      formData.append("id", challengeData[teller].id);
      formData.append("questionText", vraagStelling);
      formData.append("challengeId", sessionStorage.getItem("challengeId"));
      formData.append("questionType", hasFile ? 1 : 0); // No file: normal question (0), file chosen: question with file (1)
      if (hasFile) {
        formData.append("file", file);
        formData.append("fileName", file.name);
        if (fileChanged) data.fileName = file.fileName;
      }

      // checks om te zien of de vraag correct is aangemaakt, dus alles ingevuld en minstens 1 juist antwoord
      if (
        antwoord !== "" &&
        antwoord !== undefined &&
        antwoord2 !== "" &&
        antwoord2 !== undefined &&
        antwoord3 !== "" &&
        antwoord3 !== undefined &&
        vraagStelling !== "" &&
        vraagStelling !== undefined &&
        CheckAnswers() === true
      ) {
        await axios
          .put(`/QuestionAnswers/${data.id}`, data, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(() => {
            if (hasFile && fileChanged) {
              axios
                .post("/QuestionAnswers/File/" + data.id, formData, {
                  headers: { Authorization: `Bearer ${token}` },
                })
                .then((res) => {})
                .catch((err) => {
                  console.log(err);
                });
            }
            setTeller((teller) => teller + 1);
            setErrorValue("");
            // if staat hier voor geval er iets foutloopt
            if (teller + 1 === aantalQuestions.length) {
              history.goBack();
              return <Redirect to="/availableQuizesPerSubject" />;
            }
            // herlaadt de data van de quiz nu deze is aangepast
            axios
              .get(`/Challenges/${idChallenge}`, {
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((response) => {
                setChallengeData(response.data.questions);
                setVraagStelling(
                  response.data.questions[teller + 1].questionText
                );
                setFile(response.data.questions[teller + 1].url);
                setFileName(response.data.questions[teller + 1].fileName);
                setFileChanged(false);
                setHasFile(false);
                if (response.data.questions[teller + 1].questionType === 1) {
                  setHasFile(true);
                }
                setAntwoord(
                  response.data.questions[teller + 1].answers[0].possibleAnswer
                );
                setAntwoord2(
                  response.data.questions[teller + 1].answers[1].possibleAnswer
                );
                setAntwoord3(
                  response.data.questions[teller + 1].answers[2].possibleAnswer
                );
                setClickButton(
                  response.data.questions[teller + 1].answers[0].correctAnswer
                );
                setClickButton2(
                  response.data.questions[teller + 1].answers[1].correctAnswer
                );
                setClickButton3(
                  response.data.questions[teller + 1].answers[2].correctAnswer
                );
                setTextColor(
                  response.data.questions[teller + 1].answers[0].correctAnswer
                    ? "#83BBBA"
                    : "white"
                );
                setTextColor2(
                  response.data.questions[teller + 1].answers[1].correctAnswer
                    ? "#83BBBA"
                    : "white"
                );
                setTextColor3(
                  response.data.questions[teller + 1].answers[2].correctAnswer
                    ? "#83BBBA"
                    : "white"
                );
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        if (vraagStelling === "" || vraagStelling === undefined) {
          setErrorValue("Gelieve de quiz een titel te geven.");
        } else if (
          antwoord === "" ||
          antwoord === undefined ||
          antwoord2 === "" ||
          antwoord2 === undefined ||
          antwoord3 === "" ||
          antwoord3 === undefined
        ) {
          setErrorValue("Gelieve de antwoorden in te vullen.");
        } else if (CheckAnswers() === false) {
          setErrorValue("Gelieve een antwoord als juist aan te duiden.");
        }
      }
    }
  }
  // functie om huidige vraag op te slaan en terug te gaan naar de vorige
  async function prevQuestion() {
    let data = {
      id: challengeData[teller].id,
      questionText: vraagStelling,
      challengeId: challengeData[teller].challengeId,
      answers: [
        {
          id: challengeData[teller].answers[0].id,
          possibleAnswer: antwoord,
          correctAnswer: clickButton,
          questionId: challengeData[teller].id,
        },
        {
          id: challengeData[teller].answers[1].id,
          possibleAnswer: antwoord2,
          correctAnswer: clickButton2,
          questionId: challengeData[teller].id,
        },
        {
          id: challengeData[teller].answers[2].id,
          possibleAnswer: antwoord3,
          correctAnswer: clickButton3,
          questionId: challengeData[teller].id,
        },
      ],
      questionType: hasFile ? 1 : 0,
      fileName: fileName,
    };
    let formData = new FormData();
    formData.append("id", challengeData[teller].id);
    formData.append("questionText", vraagStelling);
    formData.append("challengeId", sessionStorage.getItem("challengeId"));
    formData.append("questionType", hasFile ? 1 : 0); // No file: normal question (0), file chosen: question with file (1)
    if (hasFile) {
      formData.append("file", file);
      formData.append("fileName", file.name);
      if (fileChanged) data.fileName = file.fileName;
    }
    // checks om te zien of de vraag correct is aangemaakt, dus alles ingevuld en minstens 1 juist antwoord
    if (
      antwoord !== "" &&
      antwoord !== undefined &&
      antwoord2 !== "" &&
      antwoord2 !== undefined &&
      antwoord3 !== "" &&
      antwoord3 !== undefined &&
      vraagStelling !== "" &&
      vraagStelling !== undefined &&
      CheckAnswers() === true
    ) {
      await axios
        .put(`/QuestionAnswers/${data.id}`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          if (hasFile && fileChanged) {
            axios
              .post("/QuestionAnswers/File/" + data.id, formData, {
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((res) => {})
              .catch((err) => {
                console.log(err);
              });
          }
          setTeller((teller) => teller - 1);
          setErrorValue("");
          // if staat hier voor geval er iets foutloopt
          if (teller === 0) {
            history.push("/availableQuizesPerSubject");
            return <Redirect to="/availableQuizesPerSubject" />;
          }
          // herlaadt de data van de quiz nu deze is aangepast
          axios
            .get(`/Challenges/${idChallenge}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
              setChallengeData(response.data.questions);
              setVraagStelling(
                response.data.questions[teller - 1].questionText
              );
              setFile(response.data.questions[teller - 1].url);
              setFileName(response.data.questions[teller - 1].fileName);
              setFileChanged(false);
              setHasFile(false);
              if (response.data.questions[teller - 1].questionType === 1) {
                setHasFile(true);
              }
              setAntwoord(
                response.data.questions[teller - 1].answers[0].possibleAnswer
              );
              setAntwoord2(
                response.data.questions[teller - 1].answers[1].possibleAnswer
              );
              setAntwoord3(
                response.data.questions[teller - 1].answers[2].possibleAnswer
              );
              setClickButton(
                response.data.questions[teller - 1].answers[0].correctAnswer
              );
              setClickButton2(
                response.data.questions[teller - 1].answers[1].correctAnswer
              );
              setClickButton3(
                response.data.questions[teller - 1].answers[2].correctAnswer
              );
              setTextColor(
                response.data.questions[teller - 1].answers[0].correctAnswer
                  ? "#83BBBA"
                  : "white"
              );
              setTextColor2(
                response.data.questions[teller - 1].answers[1].correctAnswer
                  ? "#83BBBA"
                  : "white"
              );
              setTextColor3(
                response.data.questions[teller - 1].answers[2].correctAnswer
                  ? "#83BBBA"
                  : "white"
              );
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (vraagStelling === "" || vraagStelling === undefined) {
        setErrorValue("Gelieve de quiz een titel te geven.");
      } else if (
        antwoord === "" ||
        antwoord === undefined ||
        antwoord2 === "" ||
        antwoord2 === undefined ||
        antwoord3 === "" ||
        antwoord3 === undefined
      ) {
        setErrorValue("Gelieve de antwoorden in te vullen.");
      } else if (CheckAnswers() === false) {
        setErrorValue("Gelieve een antwoord als juist aan te duiden.");
      }
    }
  }
  async function submitHandler() {
    // Submit changes made
    let data = {
      id: challengeData[teller].id,
      questionText: vraagStelling,
      challengeId: challengeData[teller].challengeId,
      answers: [
        {
          id: challengeData[teller].answers[0].id,
          possibleAnswer: antwoord,
          correctAnswer: clickButton,
          questionId: challengeData[teller].id,
        },
        {
          id: challengeData[teller].answers[1].id,
          possibleAnswer: antwoord2,
          correctAnswer: clickButton2,
          questionId: challengeData[teller].id,
        },
        {
          id: challengeData[teller].answers[2].id,
          possibleAnswer: antwoord3,
          correctAnswer: clickButton3,
          questionId: challengeData[teller].id,
        },
      ],
      questionType: hasFile ? 1 : 0,
      fileName: fileName,
    };
    let formData = new FormData();
    formData.append("id", challengeData[teller].id);
    formData.append("questionText", vraagStelling);
    formData.append("challengeId", sessionStorage.getItem("challengeId"));
    formData.append("questionType", hasFile ? 1 : 0); // No file: normal question (0), file chosen: question with file (1)
    if (hasFile) {
      formData.append("file", file);
      formData.append("fileName", file.name);
      if (fileChanged) data.fileName = file.fileName;
    }
    // checks om te zien of de vraag correct is aangemaakt, dus alles ingevuld en minstens 1 juist antwoord
    if (
      antwoord !== "" &&
      antwoord !== undefined &&
      antwoord2 !== "" &&
      antwoord2 !== undefined &&
      antwoord3 !== "" &&
      antwoord3 !== undefined &&
      vraagStelling !== "" &&
      vraagStelling !== undefined &&
      CheckAnswers() === true
    ) {
      await axios
        .put(`/QuestionAnswers/${data.id}`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          if (hasFile && fileChanged) {
            axios
              .post("/QuestionAnswers/File/" + data.id, formData, {
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((res) => {})
              .catch((err) => {
                console.log(err);
              });
          }
          setTeller((teller) => teller - 1);
          setErrorValue("");
          // if staat hier voor geval er iets foutloopt
          if (teller === 0) {
            history.push("/availableQuizesPerSubject");
            return <Redirect to="/availableQuizesPerSubject" />;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }


    history.push("/availableQuizesPerSubject");
    return <Redirect to="/availableQuizesPerSubject" />;
  }
  // functie op dubbelklik om het juiste antwoord aan te passen.
  function click(e) {
    toggleValue(e.target.value);
    clickButton = value;
    setClickButton(clickButton);
    setTextColor(value ? "#83BBBA" : "white");
  }
  function click2(e) {
    toggleValue2(e.target.value);
    clickButton2 = value2;
    setClickButton2(clickButton2);
    setTextColor2(value2 ? "#83BBBA" : "white");
  }
  function click3(e) {
    toggleValue3(e.target.value);
    clickButton3 = value3;
    setClickButton3(clickButton3);
    setTextColor3(value3 ? "#83BBBA" : "white");
  }
  function CheckAnswers() {
    if (
      clickButton === false &&
      clickButton2 === false &&
      clickButton3 === false
    ) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <>
      <Container>
        {aantalComponenten[teller]}
        <p style={{ color: "red" }}>{errorValue}</p>
        <Row>
          {teller === 0 ? (
            <Col className="col-md-4 "></Col>
          ) : (
            <Col className="col-md-4 ">
              <CustomButton handler={prevQuestion} name="Vorige" />
            </Col>
          )}
          <Col className="col-md-4 ">
            <CustomButton handler={submitHandler} name="Opslaan" />
          </Col>
          {teller === aantalQuestions.length ? ( // teller + 1 === aantalQuestions.length
            <Col className="col-md-4 "></Col>
          ) : (
            <Col className="col-md-4 ">
              <CustomButton handler={nextQuestion} name="Volgende" />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}

export default UpdateQuestions2;
