import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Row, Container, Table, Card } from "react-bootstrap";
import Axios from "axios";
import uniqid from "uniqid";
import LoadingIcon from "../../actions/LoadingIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faClipboardCheck,
  faClipboardList,
  faUser,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Pie, Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import GaugeChart from "react-gauge-chart";
ChartJS.register(ArcElement, Tooltip, Legend);

let title = [];
function DashboardMood() {
  const [isLoading, setIsLoading] = useState(true);
  let [challengesLoading, setChallengesLoading] = useState(true);
  let [employeesLoading, setEmployeesLoading] = useState(true);
  let [moodLoading, setMoodLoading] = useState(true);
  const token = sessionStorage.getItem("complexToken");
  const challengeId = sessionStorage.getItem("challengeId");
  const history = useHistory();
  let [challengeResults, setChallengeResults] = useState([]);
  // let [challenge, setChallenge] = useState([]);
  let [moodData, setMoodData] = useState([]);
  let [totaalJuist, setTotaalJuist] = useState(0);
  let [totaalEmployees, setTotaalEmployees] = useState(0);
  const subjectId = sessionStorage.getItem("subjectId");
  let [result] = useState([]);
  let [data] = useState([]);
  let [tableHeadMood, setTableHeadMood] = useState([]);
  let [percent] = useState(0);
  let [currentPercent] = useState(0);
  let [count] = useState(1);

  let [dataLineEmotionalPercentage, setDataLineEmotionalPercentage] = useState([]);
  let [tableHead, setTableHead] = useState([]);
  let [moodPercentages, setMoodPercentages] = useState([]);

  useEffect(function () {
    getChallengeData();
    getTotaalEmployees();
    // getChallenge();
    getMoodData();
    // getMoods();
  }, []);

  // krijg resultaten van de quiz
  const getChallengeData = () => {
    Axios.get(`/Results/Challenge/${challengeId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setChallengeResults(response.data);
        // tel het aantal juiste antwoorden met behulp van de quiz data
        setTotaalJuist(0);
        response.data.forEach((element) => {
          setTotaalJuist((totaalJuist) => totaalJuist + element.score);
        });
        // setIsLoading(false);
        setChallengesLoading(false);
      })
      .catch((error) => console.log(error));
  };
  // krijg het aantal Employees in dit vak
  const getTotaalEmployees = () => {
    Axios.get(`/Users/Subject/${subjectId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setTotaalEmployees(response.data.length);
        setEmployeesLoading(false);
      })
      .catch((error) => console.log(error));
  };
  // krijg data van de quiz
  // const getChallenge = () => {
  //   Axios.get(`/Challenges/${challengeId}`, {
  //     headers: { Authorization: `Bearer ${token}` },
  //   })
  //     .then((response) => {
  //       setChallenge(response.data.moodQuestionChallenges);
  //     })
  //     .catch((error) => console.log(error));
  // };
  // krijg mood data van de quiz
  const getMoodData = () => {
    // Reset percentages before calculcating
    moodPercentages = {
      "🤮":0,
      "😭":0,
      "😥":0,
      "😠":0,
      "😮":0,
      "😐":0,
      "🙂":0,
      "😀":0,
      "🤣":0,
      "😎":0,
      "❤️":0,
    }
    Axios.get(`Moods/Challenge/${challengeId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        response.data.map((mood) => {
          moodPercentages[emojiPicker(mood.employeeMood)]+=100/response.data.length;
        });
        // PAS DIT AAN ZODAT DE GEBRUIKTE MOODQUESTIONS GETOOND WORDEN
        tableHead = response.data.map((res) => {
          return <th key={uniqid()}>
              {res.moodQuestion.question}
            </th>;
        });
        tableHead.length = 3;
        setTableHead(tableHead);
        setMoodData(response.data);
        setMoodLoading(false);
      })
      .catch((error) => console.log(error));
    
    setMoodPercentages(moodPercentages);
    setIsLoading(false);
  };
  const goBack = () => {
    history.goBack();
  };

  const handleClick = (user) => {
    sessionStorage.setItem("employeeId", user.id);
  };

  // switch om de emoji's uit de database terug om te zetten in emoji's
  const emojiPicker = (emoji) => {
    switch (emoji) {
      case "InLove":
        return "🥰";
      
      case "Easy":
        return "😎";
      
      case "Funny":
        return "🤣";

      case "Good":
        return "😀";

      case "OK":
        return "🙂";

      case "Neutral":
        return "😐";

      case "Surprised":
        return "😮";

      case "Angry":
        return "😠";

      case "Sad":
        return "😥";

      case "VerySad":
        return "😭";

      case "Sick":
        return "🤮";

      default:
        return "⁉️";
    }
  };

  // switch om de emoji's om te zetten naar getallen
  const moodCalculator = (emoji) => {
    switch (emoji) {
      case "InLove":
        percent += 100;
        currentPercent = 100;
        break;

      case "Easy":
        percent += 90;
        currentPercent = 90;
        break;

      case "Funny":
        percent += 80;
        currentPercent = 80;
        break;

      case "Good":
        percent += 70;
        currentPercent = 70;
        break;

      case "OK":
        percent += 60;
        currentPercent = 60;
        break;

      case "Neutral":
        percent += 50;
        currentPercent = 50;
        break;

      case "Surprised":
        percent += 40;
        currentPercent = 40;
        break;

      case "Angry":
        percent += 30;
        currentPercent = 30;
        break;

      case "Sad":
        percent += 20;
        currentPercent = 20;
        break;

      case "VerySad":
        percent += 10;
        currentPercent = 10;
        break;
      
      case "Sick":
        percent += 0;
        currentPercent = 0;
        break;
      
      default:
        percent += 50;
        currentPercent = 50;
        break;
    }
  };
  result = challengeResults.map((res, index) => {
    percent = 0;
    count = 0;
    let mood = [];
    let moodResult = moodData.filter((item) => item.userId === res.employeeId);
    // map de resultaten op de moodvragen en vul deze in met de correcte emoji's
    mood = moodResult.map((res2) => {
      count++;
      moodCalculator(res2.employeeMood);
      return (
        <td
          style={{
            fontSize: "3rem",
          }}
          key={"EmployeeMood" + res2.id}
        >
          {emojiPicker(res2.employeeMood)} <br/> <p style={{fontSize: "1rem"}}>{currentPercent}%</p>
        </td>
      );
    });
    // map de Employees en hun mood barometer
    return (
      <tr key={uniqid()}>
        <td colSpan="2">
          <Link
            style={{
              textDecoration: "none",
              color: "#5094B9",
              hover: "#2d5971",
              display: "flex",
              justifyContent: "center",
            }}
            value={result.subjectId}
            to={{ pathname: "/DashboardEmployeeMood", customObject: title }}
            onClick={() => handleClick(res.user)}
            className="tekstNaamInstelling ml-0 mt-3"
          >
            <p>
              {res.user.firstName} {res.user.lastName}
            </p>
          </Link>
        </td>
        {mood}
        <td
          style={{
            justifyContent: "center",
          }}
        >
          <GaugeChart
            style={{ width: "15rem", margin: "auto" }}
            needleColor="rgb(45, 89, 113,0.5)"
            id="gauge-chart5"
            nrOfLevels={420}
            arcsLength={[0.3, 0.4, 0.3]}
            colors={["#EA4228", "#ffa500", "#5BE12C"]}
            percent={percent / count / 100}
            arcPadding={0.02}
            textColor={"Black"}
          />
        </td>
      </tr>
    );
  });

  // data voor de aanwezigheid cirkel diagram
  data = {
    labels: ["niet deelgenomen", "deelgenomen"],
    datasets: [
      {
        label: "Aantal deelgenomen en niet-deelgenomen",
        data: [
          totaalEmployees - challengeResults.length,
          challengeResults.length,
        ],
        backgroundColor: ["rgba(255, 50, 50, 0.5)", "rgba(5, 195, 5, 0.5)"],
        borderColor: ["rgba(255, 50, 50, 1)", "rgba(5, 195, 5, 1)"],
        borderWidth: 1,
      },
    ],
  };

  dataLineEmotionalPercentage = {
    datasets: [
      {
        data: moodPercentages,
        borderColor: "rgb(75,192,192)",
        borderWidth: "1",
        backgroundColor: "rgba(75,192,192,0.2)",
        fill: false,
      },
    ],
    scales: {
      y: {
        min: 0,
        max: 100,
      },
    },
  };

  const options = {
    scales: {
      y: {
        min: 0,
        max: 100,
        display: true,
      },
      x: {
        display: true,
        ticks: {
          font: {
            size: 30
          }
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    datalabels: {
      display: false,
    },
  };

  return (
    <div>
      <Container>
        <Row style={{ marginBottom: "1rem" }}>
          <Col sm={1} lg={1}>
            <div>
              {
                <FontAwesomeIcon
                  onClick={goBack}
                  style={{ textDecoration: "none", color: "#2d5971" }}
                  icon={faArrowLeft}
                  size="3x"
                />
              }
            </div>
          </Col>
          <Col sm={10}>
            <h1>{sessionStorage.getItem("quizName")}</h1>
          </Col>
        </Row>
      </Container>

      <Container key={uniqid()}>
        {isLoading ? (
          <div style={{ marginRight: "20vw", marginTop: "10vh" }}>
            <LoadingIcon />
          </div>
        ) : (
          <div>
            <Row>
              <Col md={8}>
                <Col>
                  <Row>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          height: "90%",
                          paddingTop: "4.4vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            paddingLeft: "3vh",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              /** style om de FontAwesome iconen weer te geven als contour zonder de betalende versie te gebruiken
                               * backgroundcolor is voor de kleur rond het icoon
                               * color is dezelfde als de background om te doen alsof deze doorzichtig is
                               * stroke geven we de kleur die de contour heeft
                               * strokewidth bepaalt de breedte van de contour*/
                              textDecoration: "none",
                              color: "rgba(108, 160, 255, 0.25)",
                              marginBottom: "3vh",
                              backgroundColor: "rgba(108, 160, 255, 0.25)",
                              padding: "1.5vh 2.5vh",
                              borderRadius: "20px",
                              stroke: "#2d5971",
                              strokeWidth: "30px",
                            }}
                            icon={faUser}
                            size="10x"
                          />
                          <div>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "1vh 0 0 2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              Ingeschreven in Onderwerp
                            </p>
                            {employeesLoading ? <LoadingIcon /> :
                              <p
                                style={{
                                  color: "#2d5971",
                                  margin: "0 2vh",
                                  fontSize: "2.5rem",
                                  float: "left",
                                }}
                              >
                                {totaalEmployees}
                              </p>
                            }
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          height: "90%",
                          paddingTop: "4.4vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            paddingLeft: "3vh",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              textDecoration: "none",
                              color: "rgba(30, 212, 84, 0.2)",
                              marginBottom: "3vh",
                              backgroundColor: "rgba(30, 212, 84, 0.2)",
                              padding: "1.5vh 2vh",
                              borderRadius: "20px",
                              stroke: "rgba(30, 200, 84, 1)",
                              strokeWidth: "30px",
                            }}
                            icon={faUserCheck}
                            size="10x"
                          />
                          <div>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "1vh 0 0 2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              Deelnemers quiz
                            </p>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "2.5rem",
                                float: "left",
                              }}
                            >
                              {challengeResults.length}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  {/* <Row>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          paddingTop: "2vh",
                          height: "90%",
                          paddingBottom: "2vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                        >Extra Card space</Card>
                    </Col>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          paddingTop: "2vh",
                          height: "90%",
                          paddingBottom: "2vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                        >Another Card <br/><br/><br/><br/><br/><br/><br/><br/>
                      </Card>
                    </Col>
                  </Row> */}
                </Col>
              </Col>
              <Col md={4}>
                {challengesLoading || moodLoading ? <LoadingIcon /> : 
                  <>
                    <Row style={{ width: "100%", marginLeft: "0px" }}>
                      <Card
                        style={{
                          marginBottom: "4vh",
                          height: "90%",
                          paddingTop: "2vh",
                          paddingBottom: "2vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <Pie data={data} style={{ maxHeight: "27vh" }}></Pie>
                      </Card>
                    </Row>
                    {challengeResults.length > 0 && (
                      <Row style={{ width: "100%", marginLeft: "0px" }}>
                        <Card
                          style={{
                            marginBottom: "4vh",
                            height: "90%",
                            paddingTop: "2vh",
                            paddingBottom: "0.9vh",
                            boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          <Link
                            style={{
                              textDecoration: "none",
                              color: "#5094B9",
                              hover: "#2d5971",
                              fontSize: "1.8rem",
                            }}
                            to={{ pathname: "/DashboardMoodTop", customObject: title }}
                            >
                            <p>Top/Bottom 10</p>
                          </Link>
                        </Card>
                      </Row>
                    )}
                  </>
                }
              </Col>
            </Row>
            {challengesLoading || moodLoading ? <LoadingIcon /> :
              challengeResults.length > 0 ? (
                <>
                  <Row>
                    <Col md={12}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          padding: "4.5vh",
                          paddingBottom: "4.5vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                          overflowX: "scroll",
                          borderRadius: "0",
                        }}
                      >
                        <Table>
                          <thead>
                            <tr>
                              <th colSpan="2">Werknemer</th>
                              {tableHeadMood}
                              <th style={{ fontSize: "20px" }} colSpan="3">Gevoel</th>
                              <th>Meter</th>
                            </tr>
                          </thead>
                          <tbody>{result}</tbody>
                        </Table>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          padding: "4.5vh",
                          paddingBottom: "4.5vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                        >
                        <p>&larr; Negatief -- Percentage gekozen emoji -- Positief &rarr;</p>
                        <Bar data={dataLineEmotionalPercentage} options={options} />
                      </Card>
                    </Col>
                  </Row>
                  </>
                ) : (
                <Row>
                  <Col>
                    <Card
                      style={{
                        marginBottom: "5vh",
                        padding: "4.5vh",
                        paddingBottom: "4.5vh",
                        boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                      }}
                      >
                        <p>Er zijn nog geen resultaten beschikbaar</p>
                    </Card>
                  </Col>
                </Row>
              )
            }
          </div>
        )}
      </Container>
    </div>
  );
}
export default DashboardMood;
