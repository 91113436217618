import React, { useEffect, useState, useContext } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import CustomButton from "../../buttons/customButton";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import GoBack from "../../buttons/goBackButton";
import LoadingIcon from "../../actions/LoadingIcon";
import Context from "../../../contexts/DefaultContext";

function UserList() {
    const userId = sessionStorage.getItem("Id");
    let [isLoading, setIsLoading] = useState(true);
    const roleNaam = sessionStorage.getItem("RoleName")
    const token = sessionStorage.getItem("complexToken");
    let [allUsersTable, setAllUsersTable] = useState();
    let [deactivateModalShow, setDeactivateModalShow] = useState(false);
    let [removeModalShow, setRemoveModalShow] = useState(false);
    let [selectedUser, setSelectedUser] = useState(0);

    let [usersLoading, setUsersLoading] = useState(true);
    const history = useHistory();
    const { addFlashMessage, setAlert } = useContext(Context);

    useEffect(function() {
        getAllUsers();
    }, []);

    async function getAllUsers() {
        let apiRoute = `/Users/Organisation/${userId}`;
        if (roleNaam === "SuperAdmin")
            apiRoute = "/Users/AllEmployees";
        await axios
        .get(apiRoute, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
            //console.log(res.data);
            let allUsersRows = res.data.map((item) => (
                <tr key={"allUsersRow" + item.id}>
                    <td>{item.firstName} {item.lastName}</td>
                    <td>
                        {!item.lockoutEnabled ? (
                            <p
                                style={{color: "#1ed454"}}
                                >
                                Actief
                            </p>
                        ) : (
                            <p
                                style={{color: "#e61c1c"}}
                                >
                                Inactief
                            </p>
                        )}
                    </td>
                    <td>
                        {roleNaam === "Employer" ? (
                            !item.lockoutEnabled ? (
                                <CustomButton
                                    handler={(e) => {
                                        setSelectedUser(e.target.value);
                                        setDeactivateModalShow(true);
                                    }}
                                    value={item.id}
                                    name="Deactiveren"
                                    />
                            ) : (
                                <CustomButton
                                    handler={enableUser}
                                    value={item.id}
                                    name="Activeren"
                                    />
                            )
                        ) : roleNaam === "SuperAdmin" ? (
                            <CustomButton
                                handler={(e) => {
                                    setSelectedUser(e.target.value);
                                    setRemoveModalShow(true);
                                }}
                                value={item.id}
                                name="Verwijderen"
                                />
                        ) : (
                            <div></div>
                        )}
                    </td>
                </tr>
            ));
            allUsersTable = (
                <Row>
                    <Col>
                        <Card
                            style={{
                                marginBottom: "5vh",
                                //height: "90%",
                                paddingTop: "4.4vh",
                                paddingBottom: "4.4vh",
                                boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                                overFlowY: "scroll",
                            }}
                            >
                            <h2>Gebruikers in de organisatie:</h2>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Naam</th>
                                        <th>Actief?</th>
                                        <th>Functie</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allUsersRows}
                                </tbody>
                            </table>
                        </Card>
                    </Col>
                </Row>
            );
            setAllUsersTable(allUsersTable);
            setIsLoading(false);
        })
        .catch((err) => console.log(err));
        setUsersLoading(false);
    }
    async function disableUser() {
        await axios
        .delete(`/Users/Deactivate/${selectedUser}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
            setDeactivateModalShow(false);
            setUsersLoading(true);
            getAllUsers();
        })
        .catch((err) => {
            console.log(err);
            setDeactivateModalShow(false);
            addFlashMessage("Uw account is niet toegestaan dit aan te passen", "warning");
            setAlert(true);
            setTimeout(() => {
                history.push("/HomePage");
                window.location.reload();
            }, 2000);
        });
    }
    async function enableUser(e) {
        e.preventDefault();
        await axios
        .get(`/Users/Activate/${e.target.value}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
            setUsersLoading(true);
            getAllUsers();
        })
        .catch((err) => {
            console.log(err);
            addFlashMessage("Uw account is niet toegestaan dit aan te passen", "warning");
            setAlert(true);
            setTimeout(() => {
                history.push("/HomePage");
                window.location.reload();
            }, 2000);
        });
    }
    async function removeUser() {
        await axios
        .delete(`/Users/${selectedUser}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
            setRemoveModalShow(false);
            setUsersLoading(true);
            getAllUsers();
        })
        .catch((err) => {
            console.log(err);
            setRemoveModalShow(false);
            addFlashMessage("Uw account is niet toegestaan dit aan te passen", "warning");
            setAlert(true);
            setTimeout(() => {
                history.push("/HomePage");
                window.location.reload();
            }, 2000);
        });
        
        console.log("removed");
    }

    return (
        <Container>
            <Row>
                <Col md={1}>
                    <GoBack />
                </Col>
            </Row>
            {isLoading ? (
                <LoadingIcon/>
            ) : (
                <div>
                    {allUsersTable}
                    <Modal
                        id="DeactivateModal"
                        size="md"
                        centered
                        show={deactivateModalShow}
                        >
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Deactiveren
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Gebruiker deactiveren? Een gedeactiveerde gebruiker kan niet meer inloggen, maar wordt nog wel ingeschreven in eventuele nieuwe quizzen, alle gebruikersdata wordt bijgehouden.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn-secondary" onClick={() => setDeactivateModalShow(false)}>Annuleer</Button>
                            <Button className="btn-primary customButton" onClick={() => disableUser()}>Deactiveer</Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        id="RemoveModal"
                        size="md"
                        centered
                        show={removeModalShow}
                        >
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Gebruiker verwijderen
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Gebruiker verwijderen? Alle gebruikersdata wordt verwijderd!</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn-secondary" onClick={() => setRemoveModalShow(false)}>Annuleer</Button>
                            <Button className="btn-primary customButton" onClick={() => removeUser()}>Verwijder</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            )}
        </Container>
    );
}

export default UserList;