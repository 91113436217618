import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Row, Container, Table, Card } from "react-bootstrap";
import GaugeChart from "react-gauge-chart";
import Axios from "axios";
import uniqid from "uniqid";
import Context from "../../../contexts/DefaultContext";
import LoadingIcon from "../../actions/LoadingIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCheckCircle,
  faClipboardCheck,
  faClipboardList,
  faExclamationCircle,
  faUser,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Bar, Line, Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// register de nodige elementen van ChartJS
ChartJS.register(ArcElement, Tooltip, Legend);

let title = [];
function Dashboard() {
  let [quizLoading, setQuizLoading] = useState(true);
  let [answersLoading, setAnswersLoading] = useState(true);
  let [enrolledLoading, setEnrolledLoading] = useState(true);
  let [moodLoading, setMoodLoading] = useState(true);

  const token = sessionStorage.getItem("complexToken");
  const challengeId = sessionStorage.getItem("challengeId");
  const history = useHistory();
  let [challengeResults, setChallengeResults] = useState([]);
  let [challenge, setChallenge] = useState([]);
  let [answerData, setAnswerData] = useState([]);
  let [totaalJuist, setTotaalJuist] = useState(0);
  let [totaalFout, setTotaalFout] = useState(0);
  let [totaalEmployees, setTotaalEmployees] = useState(0);
  const subjectId = sessionStorage.getItem("subjectId");
  let [result] = useState([]);
  let [data] = useState([]);
  let [tableHead] = useState([]);

  let [dataLineEmotionalPercentage, setDataLineEmotionalPercentage] = useState([]);
  let [moodData, setMoodData] = useState([]);
  let [tableHeadMood, setTableHeadMood] = useState([]);
  let [resultMood, setResultMood] = useState([]);
  let [moodPercentages, setMoodPercentages] = useState([]);

  const { addFlashMessage, setAlert } = useContext(Context);

  useEffect(function () {
    getChallenge();
    getChallengeData();
    getTotaalEmployees();
    getMoods();
  }, []);

  // krijg data vanuit de result tabel van de database voor deze quiz
  const getChallengeData = () => {
    Axios.get(`/Results/Challenge/${challengeId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        //console.log(response);
        challengeResults = response.data;
        setChallengeResults(challengeResults);
        // tel de data voor het totaal aantal juiste en foute antwoorden
        setTotaalJuist(0);
        setTotaalFout(0);
        response.data.forEach((element) => {
          setTotaalJuist((totaalJuist) => totaalJuist + element.score);
          setTotaalFout(
            (totaalFout) =>
              totaalFout + (element.challenge.questions.length - element.score)
          );
        });
        if (challengeResults.length > 0)
          getAnswerData();
        else setAnswersLoading(false);
      })
      .catch((error) => console.log(error));
  };
  // krijg de data voor het aantal Employees die deze cursus volgen
  const getTotaalEmployees = () => {
    Axios.get(`/Users/subject/${subjectId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setTotaalEmployees(response.data.length);
        setEnrolledLoading(false);
      })
      .catch((error) => console.log(error));
  };
  // krijg de data van de quiz voor de correcte vragen te krijgen.
  const getChallenge = () => {
    Axios.get(`/Challenges/${challengeId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setChallenge(response.data.questions);
        setQuizLoading(false);
      })
      .catch((error) => console.log(error));
  };
  // krijg de data van de antwoorden van de Employees.
  const getAnswerData = () => {
    Axios.get(`/SelectedAnswers/Challenge/${challengeId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setAnswerData(response.data);
        setAnswersLoading(false);
      })
      .catch((error) => fail);
  };
  function fail() {
    addFlashMessage("Er ging iets mis tijdens de communicatie met de server.", "warning");
    setAlert(true);
  }
  const goBack = () => {
    history.goBack();
  };
  // map de vragen als header in de tabel
  tableHead = challenge.map((res) => {
    return <th style={{ maxWidth: "10rem" }} key={res.id}
      onMouseEnter={(e) => {
        if (res.questionType === 1 && res.url !== undefined && res.fileName !== undefined && res.fileName !== "undefined")
        {
          e.target.firstChild.style.visibility = "visible";
        }
      }}
      onMouseLeave={(e) => {
        if (res.questionType === 1 && res.url !== undefined && res.fileName !== undefined && res.fileName !== "undefined")
          e.target.firstChild.style.visibility = "hidden";
      }}
      >
      {res.questionType === 1 && res.url !== undefined && res.fileName !== undefined && res.fileName !== "undefined" && (
        <img src={res.url} style={{visibility: "hidden", position: "absolute", width: "10rem"}} />
      )}
      {res.questionText}
    </th>;
  });
  const handleClick = (user) => {
    sessionStorage.setItem("employeeId", user.id);
  };
  // map of de antwoorden per Employee correct zijn of niet op de vragen, dit houdt ook hun naam en totale score in
  result = challengeResults.map((res, index) => {
    // filter de antwoordendata op de juiste Employee
    let antwoordenResult = answerData.filter(
      (item) => item.employeeId === res.employeeId
    );
    
    let score = 0;
    antwoordenResult.forEach((res) => {
      if(res.answer.correctAnswer) score++;
    });
    // map per antwoord of dit juist is of niet
    let antw = antwoordenResult.map((res1) => {
      return (
        <td key={res1.id}>
          {res1.answer.correctAnswer ? (
            <div key={res1.id}>
              <FontAwesomeIcon
                style={{
                  textDecoration: "none",
                  color: "#1ed454",
                  paddingTop: "2.5vh",
                }}
                icon={faCheckCircle}
                size="4x"
                />
                <br/>
              <span style={{color: "#1ed454"}}>{res1.answer.possibleAnswer}</span>
            </div>
          ) : (
            <div key={res1.id}>
              <FontAwesomeIcon
                style={{
                  textDecoration: "none",
                  color: "#e61c1c",
                  paddingTop: "2.5vh",
                }}
                icon={faExclamationCircle}
                size="4x"
              />
              <br/>
              <span style={{color: "#e61c1c"}}>{res1.answer.possibleAnswer}</span>
            </div>
          )}
        </td>
      );
    });
    return (
      <tr key={uniqid()}>
        <td colSpan="2">
          <Link
            style={{
              textDecoration: "none",
              color: "#5094B9",
              hover: "#2d5971",
              fontSize: "1.8rem",
            }}
            value={result.subjectId}
            to={{ pathname: "/DashboardEmployee", customObject: title }}
            onClick={() => handleClick(res.user)}
          >
            <p>
              {res.user.firstName} {res.user.lastName}
            </p>
          </Link>
        </td>
        {antw}
        <td>
          {/* check of de score hoger dan 50% ligt om zo deze in groen of rood uit te drukken */}
          {score / res.challenge.questions.length === 0.5 ? (
            <p
              style={{ color: "#ffa500", paddingTop: "2vh", fontSize: "1.8em" }} key={uniqid()}
            >
              {score}/{res.challenge.questions.length}
            </p>
          ) : score / res.challenge.questions.length < 0.5 ? (
            <p
              style={{ color: "#e61c1c", paddingTop: "2vh", fontSize: "1.8em" }} key={uniqid()}
            >
              {score}/{res.challenge.questions.length}
            </p>
          ) : (
            <p
              style={{ color: "#1ed454", paddingTop: "2vh", fontSize: "1.8em" }} key={uniqid()}
            >
              {score}/{res.challenge.questions.length}
            </p>
          )}
        </td>
      </tr>
    );
  });

  // zet de data voor de cirkeldiagram goed met de deelnames van Employees.
  data = {
    labels: ["niet deelgenomen", "deelgenomen"],
    datasets: [
      {
        label: "Aantal deelgenomen en niet-deelgenomen",
        data: [
          totaalEmployees - challengeResults.length,
          challengeResults.length,
        ],
        backgroundColor: ["rgba(255, 50, 50, 0.5)", "rgba(5, 195, 5, 0.5)"],
        borderColor: ["rgba(255, 50, 50, 1)", "rgba(5, 195, 5, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const emojiPicker = (emoji) => {
    switch (emoji) {
      case "InLove":
        return "🥰";
      
      case "Easy":
        return "😎";
      
      case "Funny":
        return "🤣";

      case "Good":
        return "😀";

      case "OK":
        return "🙂";

      case "Neutral":
        return "😐";

      case "Surprised":
        return "😮";

      case "Angry":
        return "😠";

      case "Sad":
        return "😥";

      case "VerySad":
        return "😭";

      case "Sick":
        return "🤮";

      default:
        return "⁉️";
    }
  };
  // switch om de emoji's om te zetten naar getallen
  const moodCalculator = (emoji) => {
    switch (emoji) {
      case "InLove":
        return 100;

      case "Easy":
        return 90;

      case "Funny":
        return 80;

      case "Good":
        return 70;

      case "OK":
        return 60;

      case "Neutral":
        return 50;

      case "Surprised":
        return 40;

      case "Angry":
        return 30;

      case "Sad":
        return 20;

      case "VerySad":
        return 10;
      
      case "Sick":
        return 0;
      
      default:
        return 50;
    }
  };

  const getMoods = async() => {
    // Reset percentages before calculcating
    moodPercentages = {
      "🤮":0,
      "😭":0,
      "😥":0,
      "😠":0,
      "😮":0,
      "😐":0,
      "🙂":0,
      "😀":0,
      "🤣":0,
      "😎":0,
      "🥰":0,
    }
    await Axios.get(`/Moods/Challenge/${challengeId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      response.data.map((mood) => {
        moodPercentages[emojiPicker(mood.employeeMood)]+=100/response.data.length;
      });
      // PAS DIT AAN ZODAT DE GEBRUIKTE MOODQUESTIONS GETOOND WORDEN
      tableHeadMood = response.data.map((res) => {
        return <th key={uniqid()}>
            {res.moodQuestion.question}
          </th>;
      });
      tableHeadMood.length = 3;
      setTableHeadMood(tableHeadMood);
      setMoodData(response.data);
      setMoodLoading(false);
    })
    .catch((error) => console.log(error));

    setMoodPercentages(moodPercentages);
  };

  resultMood = challengeResults.map((res, index) => {
    let percent = 0;
    let count = 0;
    let mood = [];
    let moodResult = moodData.filter((item) => item.userId === res.employeeId);
    // map de resultaten op de moodvragen en vul deze in met de correcte emoji's
    mood = moodResult.map((res2) => {
      count++;
      percent += moodCalculator(res2.employeeMood);
      return (
        <td
          style={{
            fontSize: "3rem",
          }}
          key={"EmployeeMood" + res2.id}
        >
          {emojiPicker(res2.employeeMood)} <br/> <p style={{fontSize: "1rem"}}>{moodCalculator(res2.employeeMood)}%</p>
        </td>
      );
    });
    // map de Employees en hun mood barometer
    return (
      <tr key={uniqid()}>
        <td colSpan="2">
          <Link
            style={{
              textDecoration: "none",
              color: "#5094B9",
              hover: "#2d5971",
              display: "flex",
              justifyContent: "center",
            }}
            value={result.subjectId}
            to={{ pathname: "/DashboardEmployee", customObject: title }}
            onClick={() => handleClick(res.user)}
            className="tekstNaamInstelling ml-0 mt-3"
          >
            <p>
              {res.user.firstName} {res.user.lastName}
            </p>
          </Link>
        </td>
        {mood}
        <td
          style={{
            justifyContent: "center",
          }}
        >
          <GaugeChart
            style={{ width: "15rem" }}
            needleColor="rgb(45, 89, 113,0.5)"
            id="gauge-chart5"
            nrOfLevels={420}
            arcsLength={[0.3, 0.4, 0.3]}
            colors={["#EA4228", "#ffa500", "#5BE12C"]}
            percent={percent / count / 100}
            arcPadding={0.02}
            textColor={"Black"}
          />
        </td>
      </tr>
    );
  });
  
  dataLineEmotionalPercentage = {
    datasets: [
      {
        data: moodPercentages,
        borderColor: "rgb(75,192,192)",
        borderWidth: "1",
        backgroundColor: "rgba(75,192,192,0.2)",
        fill: false,
      },
    ],
    scales: {
      y: {
        min: 0,
        max: 100,
      },
    },
  };

  const options = {
    scales: {
      y: {
        min: 0,
        max: 100,
        display: true,
      },
      x: {
        display: true,
        ticks: {
          font: {
            size: 30
          }
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    datalabels: {
      display: false,
    },
  };

  return (
    <div>
      <Container>
        <Row style={{ marginBottom: "1rem" }}>
          <Col sm={1} lg={1}>
            <div>
              {
                <FontAwesomeIcon
                  onClick={goBack}
                  style={{ textDecoration: "none", color: "#2d5971" }}
                  icon={faArrowLeft}
                  size="3x"
                />
              }
            </div>
          </Col>
          <Col sm={10}>
            <h1>{sessionStorage.getItem("quizName")}</h1>
          </Col>
        </Row>
      </Container>

      <Container key={uniqid()}>
        {false ? (
          <div style={{ marginRight: "20vw", marginTop: "10vh" }}>
            <LoadingIcon />
          </div>
        ) : (
          <div>
            <Row>
              <Col md={8}>
                <Col>
                  <Row>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          height: "90%",
                          paddingTop: "4.4vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            paddingLeft: "3vh",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              /** style om de FontAwesome iconen weer te geven als contour zonder de betalende versie te gebruiken
                               * backgroundcolor is voor de kleur rond het icoon
                               * color is dezelfde als de background om te doen alsof deze doorzichtig is
                               * stroke geven we de kleur die de contour heeft
                               * strokewidth bepaalt de breedte van de contour*/
                              textDecoration: "none",
                              color: "rgba(108, 160, 255, 0.25)",
                              marginBottom: "3vh",
                              backgroundColor: "rgba(108, 160, 255, 0.25)",
                              padding: "1.5vh 2.5vh",
                              borderRadius: "20px",
                              stroke: "#2d5971",
                              strokeWidth: "30px",
                            }}
                            icon={faUser}
                            size="10x"
                          />
                          <div>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "1vh 0 0 2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              Ingeschreven in Onderwerp
                            </p>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "2.5rem",
                                float: "left",
                              }}
                            >
                              {enrolledLoading ? <LoadingIcon /> : totaalEmployees}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          height: "90%",
                          paddingTop: "4.4vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            paddingLeft: "3vh",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              textDecoration: "none",
                              color: "rgba(30, 212, 84, 0.2)",
                              marginBottom: "3vh",
                              backgroundColor: "rgba(30, 212, 84, 0.2)",
                              padding: "1.5vh 2vh",
                              borderRadius: "20px",
                              stroke: "rgba(30, 200, 84, 1)",
                              strokeWidth: "30px",
                            }}
                            icon={faUserCheck}
                            size="10x"
                          />
                          <div>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "1vh 0 0 2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              Deelnemers quiz
                            </p>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "2.5rem",
                                float: "left",
                              }}
                            >
                              {quizLoading ? <LoadingIcon /> : challengeResults.length}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          height: "90%",
                          paddingTop: "4.4vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            paddingLeft: "3vh",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              textDecoration: "none",
                              color: "rgba(30, 212, 84, 0.2)",
                              marginBottom: "3vh",
                              backgroundColor: "rgba(30, 212, 84, 0.2)",
                              padding: "1.5vh 2.5vh",
                              borderRadius: "20px",
                              stroke: "rgba(30, 200, 84, 1)",
                              strokeWidth: "20px",
                            }}
                            icon={faClipboardCheck}
                            size="10x"
                          />
                          <div>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "1vh 0 0 2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              Totaal juiste antwoorden
                            </p>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "2.5rem",
                                float: "left",
                              }}
                            >
                              {quizLoading ? <LoadingIcon/> : totaalJuist}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          height: "90%",
                          paddingTop: "4.4vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            paddingLeft: "3vh",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              textDecoration: "none",
                              color: "rgba(255, 59, 121, 0.2)",
                              marginBottom: "3vh",
                              backgroundColor: "rgba(255, 59, 121, 0.2)",
                              padding: "1.5vh 2.5vh",
                              borderRadius: "20px",
                              stroke: "rgba(255, 59, 105, 1)",
                              strokeWidth: "20px",
                            }}
                            icon={faClipboardList}
                            size="10x"
                          />
                          <div>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "1vh 0 0 2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              Totaal foute antwoorden
                            </p>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "2.5rem",
                                float: "left",
                              }}
                            >
                              {quizLoading ? <LoadingIcon/> : totaalFout}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Col>
              <Col md={4}>
                {quizLoading && data !== undefined ? <LoadingIcon/> : (
                  <Row style={{ width: "100%", marginLeft: "0px" }}>
                    <Card
                      style={{
                        marginBottom: "4vh",
                        height: "90%",
                        paddingTop: "2vh",
                        paddingBottom: "2vh",
                        boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      <Pie data={data} style={{ maxHeight: "27vh" }}></Pie>
                    </Card>
                  </Row>
                )}
                {!quizLoading && challengeResults.length > 0 && (
                  <Row style={{ width: "100%", marginLeft: "0px" }}>
                    <Card
                      style={{
                        marginBottom: "4vh",
                        height: "90%",
                        paddingTop: "2vh",
                        paddingBottom: "0.9vh",
                        boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "#5094B9",
                          hover: "#2d5971",
                          fontSize: "1.8rem",
                        }}
                        to={{ pathname: "/DashboardTop", customObject: title }}
                      >
                        <p>Top/Bottom 10</p>
                      </Link>
                    </Card>
                  </Row>
                )}
              </Col>
            </Row>
            {answersLoading ? (
              <LoadingIcon />
            ) : (
              challengeResults.length > 0 ? (
                <>
                  <Row>
                    <Col md={12}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          padding: "4.5vh",
                          paddingBottom: "4.5vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                          overflowX: "scroll",
                          borderRadius: "0",
                        }}
                        >
                        <Table>
                          <thead>
                            <tr>
                              <th colSpan="2">Werknemer</th>
                              {tableHead}
                              <th style={{ minWidth: "5rem" }}>Totaal</th>
                            </tr>
                          </thead>
                          <tbody>{result}</tbody>
                        </Table>
                      </Card>
                    </Col>
                  </Row>
                  {moodLoading ? (
                    <LoadingIcon />
                  ) : (
                    <>
                    <Row>
                      <Col md={12}>
                        <Card
                          style={{
                            marginBottom: "5vh",
                            padding: "4.5vh",
                            paddingBottom: "4.5vh",
                            boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                            overflowX: "scroll",
                            borderRadius: "0",
                          }}
                        >
                          <Table>
                            <thead>
                              <tr>
                                <th colSpan="2">Werknemer</th>
                                {tableHeadMood}
                                <th style={{ fontSize: "20px" }} colSpan="3">Gevoel</th>
                              </tr>
                            </thead>
                            <tbody>{resultMood}</tbody>
                          </Table>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card
                          style={{
                            marginBottom: "5vh",
                            padding: "4.5vh",
                            paddingBottom: "4.5vh",
                            boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                          }}
                          >
                          <p>&larr; Negatief -- Percentage gekozen emoji -- Positief &rarr;</p>
                          <Bar data={dataLineEmotionalPercentage} options={options} />
                        </Card>
                      </Col>
                    </Row>
                    </>
                  )}
                </>
              ) : (
                <Row>
                  <Col>
                    <Card
                      style={{
                        marginBottom: "5vh",
                        padding: "4.5vh",
                        paddingBottom: "4.5vh",
                        boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                      }}
                      >
                        <p>Er zijn nog geen resultaten beschikbaar</p>
                    </Card>
                  </Col>
                </Row>
              )
            )}
          </div>
        )}
      </Container>
    </div>
  );
}
export default Dashboard;
