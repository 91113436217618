import React, { Fragment, useState, useContext } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { Container, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import axios from "axios";
import useToggle from "../../../customUseHooks/useToggle";
import CustomInputField from "./CustomInputField";
import Context from "../../../contexts/DefaultContext";
import CustomButton from "../../buttons/customButton";

function Questions() {
  const [vraagStelling, setVraagStelling] = useState(() => {});
  const [antwoord, setAntwoord] = useState("");
  const [antwoord2, setAntwoord2] = useState("");
  const [antwoord3, setAntwoord3] = useState("");
  const [index, setIndex] = useState(0);
  const [indexVraag, setIndexVraag] = useState(10);
  let [clickButton, setClickButton] = useState(false);
  let [clickButton2, setClickButton2] = useState(false);
  let [clickButton3, setClickButton3] = useState(false);
  let [value, toggleValue] = useToggle(true);
  let [value2, toggleValue2] = useToggle(true);
  let [value3, toggleValue3] = useToggle(true);
  const [textColor, setTextColor] = useState("white");
  const [textColor2, setTextColor2] = useState("white");
  const [textColor3, setTextColor3] = useState("white");
  const { addFlashMessage, setAlert } = useContext(Context);
  let naamButton = "";
  const token = sessionStorage.getItem("complexToken");

  const [hasFile, setHasFile] = useState(false);
  const [file, setFile] = useState();

  async function handleSubmit() {
    try {
      setClickButton();
      // verzamel de data van de 3 antwoordvelden
      const answers = [
        {
          possibleAnswer: antwoord,
          correctAnswer: clickButton,
        },
        {
          possibleAnswer: antwoord2,
          correctAnswer: clickButton2,
        },
        {
          possibleAnswer: antwoord3,
          correctAnswer: clickButton3,
        },
      ];
      // verzamel de data van de vraagstelling
      const data = {
        questionText: vraagStelling,
        challengeId: sessionStorage.getItem("challengeId"),
        answers: answers,
        questionType: hasFile ? 1 : 0,
      };
      let formData = new FormData();
      formData.append("questionText", vraagStelling);
      formData.append("challengeId", sessionStorage.getItem("challengeId"));
      formData.append("answers", answers);
      formData.append("questionType", hasFile ? 1 : 0); // No file: normal question (0), file chosen: question with file (1)
      if (hasFile) {
        data.fileName = file.name;
        formData.append("file", file);
        formData.append("fileName", file.name);
      }
      // zet de data in de database via de api
      await axios
        .post("/QuestionAnswers/", data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          data.id = res.data.id;
          formData.append("id", res.data.id);
        })
        .catch((err) => {
          console.log(err);
          addFlashMessage("Uw account is niet toegestaan om quizzen aan te maken. U wordt zometeen naar de homepagina verwezen.", "warning");
          setAlert(true);
          setTimeout(() => {
            history.push("/HomePage");
            window.location.reload();
          }, 4000);
        });
      if (hasFile) {
        await axios
          .post("/QuestionAnswers/File/" + data.id, formData, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {})
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (e) {
      console.log("There was a problem.", e);
    }
  }

  const history = useHistory();
  function goToReminderSet() {
    history.push("/ReminderSet");
  }

  // functie om het juiste antwoord aan te duiden
  function click(e) {
    toggleValue(e.target.value);
    clickButton = value;
    setClickButton(clickButton);
    setTextColor(value ? "#83BBBA" : "white");
  }
  function click2(e) {
    toggleValue2(e.target.value);
    clickButton2 = value2;
    setClickButton2(clickButton2);
    setTextColor2(value2 ? "#83BBBA" : "white");
  }
  function click3(e) {
    toggleValue3(e.target.value);
    clickButton3 = value3;
    setClickButton3(clickButton3);
    setTextColor3(value3 ? "#83BBBA" : "white");
  }

  const aantal = sessionStorage.getItem("aantalVragen");
  var aantalV = [];
  let teller = 1;

  for (var i = 0; i < aantal; i++) {
    aantalV.push(
      [...Array({ aantalV })].map((i) => (
        <Container key={i}>
          <form key={indexVraag} className="container py-5">
            <Row>
              <h5 className="mb-4">Vraag {teller++}</h5>
              <CustomInputField
                placeholder="vraag"
                handler={(e) => setVraagStelling(e.target.value.trim())}
              />
              <Row>
                <Col>
                  <label>
                    <input type="checkbox" onChange={(e) => setHasFile(e.target.checked)} style={{marginBottom: "1%"}} />
                    Deze vraag heeft een afbeelding.
                  </label>
                </Col>
                <Col>
                  {hasFile ? (
                    <InputGroup>
                        <FormControl
                          type="file"
                          aria-label="file"
                          onChange={(e) => {setFile(e.target.files[0]);}}
                          accept="image/*"
                          />
                        {file !== undefined && <img alt="Verduidelijking van de vraag" src={URL.createObjectURL(file)} style={{marginBottom:"5rem", maxHeight: "30rem", maxWidth: "30rem"}} />}
                    </InputGroup>
                    ) : (<div></div>)
                  }
                </Col>
              </Row>

              <h5 className="mb-3">Mogelijke antwoorden : </h5>
              <h6 className="mb-3">
                (dubbelklik om het juiste antwoord aan te duiden){" "}
              </h6>
              <Fragment>
                <CustomInputField
                  placeholder="antwoord 1 "
                  style={{ backgroundColor: textColor }}
                  clickHandler={(e) => click(e)}
                  handler={(e) => setAntwoord(e.target.value.trim())}
                />
                <CustomInputField
                  placeholder="antwoord 2"
                  style={{ backgroundColor: textColor2 }}
                  clickHandler={(e) => click2(e)}
                  handler={(e) => setAntwoord2(e.target.value.trim())}
                />
                <CustomInputField
                  placeholder="antwoord 3 "
                  style={{ backgroundColor: textColor3 }}
                  clickHandler={(e) => click3(e)}
                  handler={(e) => setAntwoord3(e.target.value.trim())}
                />
              </Fragment>
            </Row>
          </form>
        </Container>
      ))
    );
  }

  let aantalComponenten = {
    eenComponent: [],
  };

  // checks of alles correct is ingevuld voor data geaccepteerd kan worden
  function VolgendeVraag() {
    if (
      antwoord !== "" &&
      antwoord !== undefined &&
      antwoord2 !== "" &&
      antwoord2 !== undefined &&
      antwoord3 !== "" &&
      antwoord3 !== undefined &&
      vraagStelling !== "" &&
      vraagStelling !== undefined &&
      CheckAnswers() === true
    ) {
      if (index <= aantalV.length - 1) {
        setIndex(index + 1);
        handleSubmit();
        setIndexVraag(indexVraag + 1);
        setTextColor("white");
        setTextColor2("white");
        setTextColor3("white");
        setClickButton(false);
        setClickButton2(false);
        setClickButton3(false);
        setAntwoord("");
        setAntwoord2("");
        setAntwoord3("");
        setVraagStelling("");
        setHasFile(false);
        setFile();
        if (index === aantalV.length - 1) {
          addFlashMessage("De quiz is succesvol toegevoegd!", "success");
          setAlert(true);
          goToReminderSet();
        }
      } else {
        goToReminderSet();

        return <Redirect to="/ReminderSet" />;
      }
    } else {
      if (vraagStelling === "" || vraagStelling === undefined) {
        addFlashMessage("Gelieve de vraag in te vullen.", "danger");
        setAlert(true);
      } else if (
        antwoord === "" ||
        antwoord === undefined ||
        antwoord2 === "" ||
        antwoord2 === undefined ||
        antwoord3 === "" ||
        antwoord3 === undefined
      ) {
        addFlashMessage("Vul de 3 mogelijke antwoorden in.", "danger");
        setAlert(true);
      } else if (CheckAnswers() === false) {
        addFlashMessage("Duid minstens een antwoord aan als juist.", "danger");
        setAlert(true);
      }
    }
  }

  // check of een antwoord als juist is aangeduid
  function CheckAnswers() {
    if (
      clickButton === false &&
      clickButton2 === false &&
      clickButton3 === false
    ) {
      return false;
    } else {
      return true;
    }
  }

  aantalComponenten.eenComponent.push(aantalV[index]);
  index === aantalV.length - 1
    ? (naamButton = "Bevestig")
    : (naamButton = "Volgende");
  const style = {
    marginLeft: "-22%",
  };
  return (
    <Container style={{marginBottom: "2rem"}}>
      {aantalComponenten.eenComponent}
      <Row style={style}>
        <Col md={10}></Col>
        <Col md={2}>
          <CustomButton
            handler={function () {
              VolgendeVraag();
            }}
            name={naamButton}
          ></CustomButton>
        </Col>
      </Row>
    </Container>
  );
}

export default Questions;
