import React from "react";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
const override = css`
  margin: auto;
  display: block;
`;
function LoadingIcon() {
  return (
    <>
      <div>
        <span>
          <HashLoader css={override} color={"#2d5971"} size={50} />
        </span>
      </div>
    </>
  );
}
export default LoadingIcon;
