import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoBack from "../../buttons/goBackButton";

import GaugeChart from "react-gauge-chart";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import LoadingIcon from "../../actions/LoadingIcon";
ChartJS.register(ArcElement, Tooltip, Legend);

function AvailableQuizesCoach() {
  let [userChallenge, setUserChallenge] = useState([]);
  const subjectId = sessionStorage.getItem("subjectId");
  let [startDate, setStartDate] = useState(null);
  let newDate = new Date();
  const token = sessionStorage.getItem("complexToken");
  let title = [];
  let [moodData, setMoodData] = useState([]);
  let [quizzesLoading, setQuizzesLoading] = useState(true);

  useEffect(function () {
    // Krijg alle quizen van de les
    Axios.get(`/Challenges/Subjects/${subjectId}/WebApp`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setUserChallenge(response.data);
        setQuizzesLoading(false);
      })
      .catch((error) => console.log(error));
    // krijg alle moods, deze worden later gefilterd per quiz
    Axios.get(`/Moods`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setMoodData(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleClick = (id, quizName) => {
    sessionStorage.setItem("challengeId", id);
    sessionStorage.setItem("quizName", quizName);
  };

  // switch om de moods in cijfers om te zetten
  const moodCalculator = (emoji) => {
    switch (emoji) {
      case "InLove":
        return 100;

      case "Easy":
        return 90;

      case "Funny":
        return 80;

      case "Good":
        return 70;

      case "OK":
        return 60;

      case "Neutral":
        return 50;

      case "Surprised":
        return 40;

      case "Angry":
        return 30;

      case "Sad":
        return 20;

      case "VerySad":
        return 10;

      case "Sick":
        return 0;

      default:
        return 50;
    }
  };

  // map om de moods per quiz te zetten
  title = userChallenge.map((result) => {
    let mood = 0;
    // filter om de moods per quiz te verzamelen
    let moodResult = moodData.filter((item) => item.challengeId === result.id);
    moodResult.forEach((element) => {
      mood += moodCalculator(element.employeeMood);
    });
    mood = mood / moodResult.length / 100;

    return (
      <Container key={result.id}>
        <Row>
          <Col lg={6} sm={10}>
            <div
              style={{
                textDecoration: "none",
                color: "#5094B9",
              }}
              className="tekstNaamInstelling ml-0 mt-3"
            >
              {result.title}
            </div>
          </Col>
          <Col>
            {new Date(result.startDate) <= newDate ? (
              <GaugeChart
                style={{ width: "13rem" }}
                needleColor="rgb(45, 89, 113,0.5)"
                id="gauge-chart5"
                nrOfLevels={420}
                arcsLength={[0.3, 0.4, 0.3]}
                colors={["#EA4228", "#ffa500", "#5BE12C"]}
                percent={mood}
                arcPadding={0.02}
                textColor={"Black"}
              />
            ) : (
              <div style={{marginTop: "3", textAlign: "left", color: "#ffa500"}}>Deze test is nog niet begonnen</div>
            )}
          </Col>
          <Col style={{ marginTop: "3vh" }} lg={2} sm={2}>
            {new Date(result.startDate) <= newDate ? (
              <div>
                {
                  <Link
                    to={{ pathname: "/DashboardMood", customObject: title }}
                    onClick={() => handleClick(result.id, result.title)}
                  >
                    <FontAwesomeIcon
                      style={{
                        textDecoration: "none",
                        color: "#5094B9",
                        cursor: "pointer",
                      }}
                      icon={["fas", "chart-bar"]}
                      size="2x"
                    />
                  </Link>
                }
              </div>
            ) : (
              <div>
                {
                  <FontAwesomeIcon
                    style={{
                      textDecoration: "none",
                      color: "#5094B9",
                      opacity: "0.5",
                    }}
                    icon={["fas", "chart-bar"]}
                    size="2x"
                  />
                }
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  });

  return (
    <div>
      <Container>
        <Row>
          <Col sm={1} lg={1}>
            <GoBack />
          </Col>
          <Col sm={10}>
            <h1>{sessionStorage.getItem("subjectName")}</h1>
          </Col>
        </Row>
      </Container>
      {quizzesLoading ? (
        <LoadingIcon />
      ) : (
        title
      )}
    </div>
  );
}

export default AvailableQuizesCoach;
