import React, { useEffect, useState } from "react";
import { Col, Row, Container, Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
import uniqid from "uniqid";
import LoadingIcon from "../../actions/LoadingIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faClipboardCheck,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import { Pie, Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
} from "chart.js";
// register de nodige elementen van ChartJS
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement
);

let title = [];
function DashboardEmployeeMood() {
  const token = sessionStorage.getItem("complexToken");
  const employeeId = sessionStorage.getItem("employeeId");
  const subjectId = sessionStorage.getItem("subjectId");
  const [quizzesLoading, setQuizzesLoading] = useState(true);
  const history = useHistory();
  let [subjectChallenges, setSubjectChallenges] = useState([]);
  let [employeeResults, setEmployeeResults] = useState([]);
  let [employee, setEmployee] = useState([]);
  let [employeeMoodResultNames] = useState([]);
  let [employeeMoodResult] = useState([]);
  let [employeeMoodLabel] = useState([]);
  let [totaalJuist, setTotaalJuist] = useState(0);
  let [totaalFout, setTotaalFout] = useState(0);
  let [moods, setMoods] = useState([]);
  let [dataPie] = useState([]);
  let [dataLineScores] = useState([]);
  let [dataLineAttendance] = useState([]);
  let [ChallengeNames] = useState([]);
  let [AttendanceScores] = useState([]);
  let [colorsMood] = useState([]);
  let [count] = useState(0);
  let [percent] = useState(0);
  let [countAttend] = useState(0);

  useEffect(function () {
    getEmployee();
    getSubjectChallenges();
    getEmployeeResults();
    getMood();
  }, []);

  // krijg de data van de Employee
  const getEmployee = () => {
    Axios.get(`/Users/${employeeId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setEmployee(response.data);
      })
      .catch((error) => console.log(error));
  };

  // krijg de data van de quizen van dit Onderwerp
  const getSubjectChallenges = () => {
    Axios.get(`/Challenges/Subjects/${subjectId}/WebApp`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setSubjectChallenges(response.data);
      })
      .catch((error) => console.log(error));
  };

  // krijg de data van de moods voor elke quiz in deze les van de Employee
  const getMood = () => {
    Axios.get(`/Moods/Subject/${subjectId}/User/${employeeId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setMoods(response.data);
      })
      .catch((error) => console.log(error));
  };

  // krijg de resultaten van de Employee voor de quizzes van deze les
  const getEmployeeResults = () => {
    Axios.get(`/Results/Employee/${employeeId}/Subject/${subjectId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setEmployeeResults(response.data);
        // bereken de totale juiste en foute antwoorden
        setTotaalJuist(0);
        setTotaalFout(0);
        response.data.forEach((element) => {
          setTotaalJuist((totaalJuist) => totaalJuist + element.score);
          setTotaalFout(
            (totaalFout) =>
              totaalFout + (element.challenge.questions.length - element.score)
          );
        });
        setQuizzesLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const goBack = () => {
    history.goBack();
  };

  // switch om de emoji's om te zetten naar getallen
  const moodCalculator = (emoji) => {
    switch (emoji) {
      case "InLove":
        percent += 100;
        break;

      case "Easy":
        percent += 90;
        break;

      case "Funny":
        percent += 80;
        break;

      case "Good":
        percent += 70;
        break;

      case "OK":
        percent += 60;
        break;

      case "Neutral":
        percent += 50;
        break;

      case "Surprised":
        percent += 40;
        break;

      case "Angry":
        percent += 30;
        break;

      case "Sad":
        percent += 20;
        break;

      case "VerySad":
        percent += 10;
        break;
      
      case "Sick":
        percent += 0;
        break;
      
      default:
        percent += 50;
        break;
    }
  };
  // cirkel diagram voor aanwezigheid
  dataPie = {
    labels: ["niet deelgenomen", "deelgenomen"],
    datasets: [
      {
        label: "Aantal deelgenomen en niet-deelgenomen",
        data: [
          subjectChallenges.length - employeeResults.length,
          employeeResults.length,
        ],
        backgroundColor: ["rgba(255, 50, 50, 0.5)", "rgba(5, 195, 5, 0.5)"],
        borderColor: ["rgba(255, 50, 50, 1)", "rgba(5, 195, 5, 1)"],
        borderWidth: 1,
      },
    ],
  };

  // map de mooddata voor de staafdiagram
  employeeMoodResultNames = [];
  employeeMoodResult = [];
  employeeMoodLabel = [];
  let percents = [];
  let counts = [];
  colorsMood = [];
  moods.map((res) => {
    percent = 0;
    moodCalculator(res.employeeMood);
    if (percents[res.challengeId]) { // add score to existing score
      percents[res.challengeId] += percent;
      counts[res.challengeId]++;
    } else { // new challenge Id
      percents[res.challengeId] = percent;
      counts[res.challengeId] = 1;
      employeeMoodResultNames.push(res.challenge.title);
    }
  });
  percents.map((res, index) => {
    let percentage = res/counts[index];
    employeeMoodResult.push(percentage);
    percentage < 40
      ? colorsMood.push("red")
      : percentage > 60
      ? colorsMood.push("#1ed454")
      : colorsMood.push("orange");
  });

  // zet de mooddata in de staafdiagram
  dataLineScores = {
    labels: employeeMoodResultNames,
    datasets: [
      {
        backgroundColor: colorsMood,
        data: employeeMoodResult,
        fill: false,
        borderColor: "rgba(75,2,12,0)",
      },
    ],
  };

  // map de data voor de lijndiagram van aanwezigheid
  ChallengeNames = [];
  AttendanceScores = [];
  count = 0;
  countAttend = 0;
  subjectChallenges.map((res) => {
    ChallengeNames.push(res.title);
    let attended = false;
    count++;
    employeeResults.forEach((element) => {
      if (element.challenge.id === res.id) {
        attended = true;
      }
    });
    if (attended === true) {
      countAttend++;
    }
    AttendanceScores.push((countAttend / count) * 100);
  });

  // zet de aanwezigheidsdata in de lijndiagram
  dataLineAttendance = {
    labels: ChallengeNames,
    datasets: [
      {
        label: "Quizaanwezigheid uitgedrukt in %",
        data: AttendanceScores,
        fill: false,
        borderColor: "rgba(75,192,192,1)",
      },
    ],
    scales: {
      y: {
        min: 0,
        max: 100,
      },
    },
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: () => {
            "null";
          },
          label: (res) => {
            return res.formattedValue;
          },
          beforeLabel: (res) => {
            return employeeMoodLabel[res.dataIndex];
          },
        },
      },
    },
  };
  const optionsScale = {
    scales: {
      y: {
        min: 0,
        max: 100,
      },
      x: {},
    },
  };

  return (
    <div>
      <Container style={{ marginBottom: "1rem" }}>
        <Row>
          <Col sm={1} lg={1}>
            <div>
              {
                <FontAwesomeIcon
                  onClick={goBack}
                  style={{ textDecoration: "none", color: "#2d5971" }}
                  icon={faArrowLeft}
                  size="3x"
                />
              }
            </div>
          </Col>
          {quizzesLoading ? (
            <div style={{ marginRight: "20vw", marginTop: "10vh" }}>
              <LoadingIcon />
            </div>
          ) : (
            <Col sm={10}>
              <h1>
                {employee.firstName} {employee.lastName}
              </h1>
              <h2>{sessionStorage.getItem("subjectName")}</h2>
            </Col>
          )}
        </Row>
      </Container>

      <Container key={uniqid()}>
        {quizzesLoading ? (
          <div style={{ marginRight: "20vw", marginTop: "10vh" }}>
            <LoadingIcon />
          </div>
        ) : (
          <div>
            <Row>
              <Col md={8}>
                <Col>
                  <Row>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          height: "90%",
                          paddingTop: "4.4vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            paddingLeft: "3vh",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              /** style om de FontAwesome iconen weer te geven als contour zonder de betalende versie te gebruiken
                               * backgroundcolor is voor de kleur rond het icoon
                               * color is dezelfde als de background om te doen alsof deze doorzichtig is
                               * stroke geven we de kleur die de contour heeft
                               * strokewidth bepaalt de breedte van de contour*/
                              textDecoration: "none",
                              color: "rgba(30, 212, 84, 0.2)",
                              marginBottom: "3vh",
                              backgroundColor: "rgba(30, 212, 84, 0.2)",
                              padding: "1.5vh 2.5vh",
                              borderRadius: "20px",
                              stroke: "rgba(30, 200, 84, 1)",
                              strokeWidth: "20px",
                            }}
                            icon={faClipboardCheck}
                            size="10x"
                          />
                          <div>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              Aantal voltooide quizzen
                            </p>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "2.5rem",
                                float: "left",
                              }}
                            >
                              {employeeResults.length}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          height: "90%",
                          paddingTop: "4.4vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            paddingLeft: "3vh",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              textDecoration: "none",
                              color: "rgba(255, 59, 121, 0.2)",
                              marginBottom: "3vh",
                              backgroundColor: "rgba(255, 59, 121, 0.2)",
                              padding: "1.5vh 2.5vh",
                              borderRadius: "20px",
                              stroke: "rgba(255, 59, 105, 1)",
                              strokeWidth: "20px",
                            }}
                            icon={faClipboardList}
                            size="10x"
                          />
                          <div>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              Aantal gemiste quizzen
                            </p>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "2.5rem",
                                float: "left",
                              }}
                            >
                              {subjectChallenges.length - employeeResults.length}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          height: "90%",
                          paddingTop: "4.4vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            paddingLeft: "3vh",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              textDecoration: "none",
                              color: "rgba(30, 212, 84, 0.2)",
                              marginBottom: "3vh",
                              backgroundColor: "rgba(30, 212, 84, 0.2)",
                              padding: "1.5vh 2.5vh",
                              borderRadius: "20px",
                              stroke: "rgba(30, 200, 84, 1)",
                              strokeWidth: "20px",
                            }}
                            icon={faClipboardCheck}
                            size="10x"
                          />
                          <div>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              Totaal juiste antwoorden
                            </p>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "2.5rem",
                                float: "left",
                              }}
                            >
                              {totaalJuist}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          height: "90%",
                          paddingTop: "4.4vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            paddingLeft: "3vh",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              textDecoration: "none",
                              color: "rgba(255, 59, 121, 0.2)",
                              marginBottom: "3vh",
                              backgroundColor: "rgba(255, 59, 121, 0.2)",
                              padding: "1.5vh 2.5vh",
                              borderRadius: "20px",
                              stroke: "rgba(255, 59, 105, 1)",
                              strokeWidth: "20px",
                            }}
                            icon={faClipboardList}
                            size="10x"
                          />
                          <div>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              Totaal foute antwoorden
                            </p>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "2.5rem",
                                float: "left",
                              }}
                            >
                              {totaalFout}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Col>
              <Col md={4}>
                <Row style={{ width: "100%", marginLeft: "0px" }}>
                  <Card
                    style={{
                      marginBottom: "3vh",
                      height: "90%",
                      paddingTop: "2vh",
                      paddingBottom: "2vh",
                      boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <Pie data={dataPie} style={{ maxHeight: "28vh" }}></Pie>
                  </Card>
                </Row>
                <Row style={{ width: "100%", marginLeft: "0px" }}>
                  <Card
                    style={{
                      marginBottom: "4vh",
                      height: "90%",
                      paddingTop: "2vh",
                      paddingBottom: "0.9vh",
                      boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "#5094B9",
                        hover: "#2d5971",
                        fontSize: "1.8rem",
                      }}
                      to={{
                        pathname: "/DashboardEmployeeMoodAll",
                        customObject: title,
                      }}
                    >
                      <p>Stemming alle onderwerpen</p>
                    </Link>
                  </Card>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={employeeMoodResult.length < 8 ? 6 : 12 /*If there's too many results, split the cards into multiple Rows*/}>
                <Card
                  style={{
                    marginBottom: "5vh",
                    padding: "4.5vh",
                    boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                    overflowX: "scroll",
                    borderRadius: "0",
                  }}
                >
                  <Bar data={dataLineScores} options={options} />
                  <p style={{ fontSize: "0.7rem" }}>
                    (Hover over de staven om de score te zien)
                  </p>
                </Card>
              </Col>
              <Col>
                <Card
                  style={{
                    marginBottom: "5vh",
                    padding: "4.5vh",
                    boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <Line data={dataLineAttendance} options={optionsScale} />
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </div>
  );
}
export default DashboardEmployeeMood;
