import React, { useState, useEffect, useContext } from "react";
import { Container, Table, Col, Row } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import CustomButton from "../../buttons/customButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import LoadingIcon from "../../actions/LoadingIcon";
import Context from "../../../contexts/DefaultContext";

function EmployeeList() {
  let [Users, setUsers] = useState([]);
  let [UsersAdd, setUsersAdd] = useState([]);
  let subjectId = sessionStorage.getItem("subjectId");
  const token = sessionStorage.getItem("complexToken");
  let options = [];
  let organisationId = sessionStorage.getItem("OrganisationId");
  const [selected, setSelected] = useState([]);
  let [usersLoading, setUsersLoading] = useState(true);
  const { addFlashMessage, setAlert } = useContext(Context);

  // functie voor het deleten van een Employee uit het vak
  const handleRemove = (e) => {
    axios
      .delete("/UserSubjects", {
        headers: { Authorization: `Bearer ${token}` },
        data: {
          UserId: e,
          SubjectId: subjectId,
        },
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        addFlashMessage("Uw account is niet toegestaan dit aan te passen", "warning");
        setAlert(true);
        setTimeout(() => {
          history.push("/HomePage");
          window.location.reload();
        }, 2000);
      });
  };

  // functie om de Employeeen van de les op te halen
  const getOrganisationData = () => {
    axios
      .get(`Users/Subject/${subjectId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        Users = res.data.map((item) => (
          <tr key={item.id}>
            <td colSpan="2">
              {item.firstName} {item.lastName}
            </td>
            <td>
              <CustomButton
                handler={() => handleRemove(item.id)}
                name="Verwijderen"
              />
            </td>
          </tr>
        ));
        setUsers(Users);
      })
      .catch((err) => {
        console.log(err);
      });

    // haal Employees op die nog niet in de les zitten maar dit wel kunnen om deze in de dropdown te stoppen
    const data3 = {
      OrganisationId: organisationId,
      SubjectId: subjectId,
    };
    axios
      .post(`/Users/Organisation/Subject/`, data3, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUsersAdd(response.data);
      })
      .catch((error) => console.log(error));
    setUsersLoading(false);
  };

  // stop Employees in de dropdown met als label hun naam en waarde hun id.
  UsersAdd.map((result) =>
    options.push({
      label: `${result.firstName} ${result.lastName}`,
      value: result.id,
    })
  );

  // functie om EMployees aan de les toe te voegen
  const addEmployee = () => {
    let usersId = [];

    selected.forEach(myFunction);

    function myFunction(item) {
      usersId.push(item.value);
    }
    const data2 = {
      UsersId: usersId,
      SubjectId: subjectId,
    };
    axios
      .post("/UserSubjects", data2, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        addFlashMessage("Uw account is niet toegestaan dit aan te passen", "warning");
        setAlert(true);
        setTimeout(() => {
          history.push("/HomePage");
          window.location.reload();
        }, 2000);
      });
  };

  useEffect(() => {
    getOrganisationData();
  }, []);
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  return (
    <Container>
      <div className="AddRemoveEmployee">
        <Row>
          <Col sm={1} lg={1}>
            <div>
              {
                <FontAwesomeIcon
                  onClick={goBack}
                  style={{
                    textDecoration: "none",
                    color: "#2d5971",
                    marginBottom: "5vh",
                  }}
                  icon={faArrowLeft}
                  size="3x"
                />
              }
            </div>
          </Col>
        </Row>
        {usersLoading ? <LoadingIcon /> : 
          <>
            <Row style={{ marginBottom: "1rem" }}>
              <Col>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="Select"
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "2rem" }}>
              <Col>
                <CustomButton handler={addEmployee} name="Opslaan" />
              </Col>
            </Row>
          </>
        }
      </div>
      {usersLoading ? <LoadingIcon /> : 
        <Table>
          <thead>
            <tr>
              <th colSpan="2">Deelnemer</th>
              <th>Beheren</th>
            </tr>
          </thead>
          <tbody>{Users}</tbody>
        </Table>
      }
    </Container>
  );
}
export default EmployeeList;
