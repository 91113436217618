import React, { useEffect, useState } from "react";
import { Container, Col, Row, Card } from "react-bootstrap";
import axios, { Axios } from "axios";
import LoadingIcon from "../../actions/LoadingIcon";
import uniqid from "uniqid";
import ShowSubjectsFromEmployer from "./ShowSubjectsFromEmployer";
import AdminPage from "../../adminSection/AdminPage";
import HomePageCoach from "../../mainSection/homeSection/HomePageCoach";
import { useHistory } from "react-router-dom";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuperAdminPage from "../../adminSection/SuperAdminPage";
import CustomButton from "../../buttons/customButton";

function HomePageUser() {
  const [responseNaam, setResponseNaam] = useState("");
  const userId = sessionStorage.getItem("Id");
  const [organisationLoading, setOrganisationLoading] = useState(true);
  const [roleNaam, setRoleNaam] = useState("");
  const token = sessionStorage.getItem("complexToken");

  const data = {
    id: userId,
  };

  // krijg de data van de ingelogde user
  const getData = () => {
    axios
      .get(`/Users/${data.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        res.data.userRoles.map((result) => setRoleNaam(result.role.name));
        if (res.data.description !== null) sessionStorage.setItem("Description", res.data.description);
        if (res.data.organisation === null) {
          setResponseNaam("Er is nog geen organisatie aangeduid");
        } else {
          setResponseNaam(res.data.organisation.organisationName);
        }
      })
      .catch((err) => {
        console.log(err);
      });
      setOrganisationLoading(false);
    }
      
  useEffect(() => {
    getData();

    // API TESTING
    // axios
    // .get(`/Challenges/Employer/3`, {
    //   headers: { Authorization: `Bearer ${token}` },
    // })
    // .then((res) => console.log(res.data))
    // .catch((err) => console.log(err));
  }, []);

  const history = useHistory();
  function goToOPO() {
    history.push("/ChooseSubject");
  }

  return (
    <div>
      { roleNaam === "SuperAdmin" ? (
        <div>
          <SuperAdminPage />
        </div>
      ) : roleNaam === "Admin" ? (
        <div>
          <AdminPage />
        </div>
      ) : roleNaam === "Coach" ? (
        <div>
          <HomePageCoach />
        </div>

      ) : roleNaam === "Employer" ? (

        <div key={uniqid()}>
          {organisationLoading ? (
            <div style={{ marginTop: "40vh" }}>
              <LoadingIcon />
            </div>
          ) : (
            <Container lg={6} sm={12}>
              <Row>
                <Col style={{ marginTop: "5vh" }} lg={6} sm={6}>
                  <Row>
                    <h5 className="tekstNaamInstelling">
                      Organisatie:
                    </h5>
                  </Row>
                  <Row>
                    <div className="tekstNaamInstelling  mt-3">
                      <strong>{responseNaam}</strong>
                    </div>
                  </Row>
                </Col>
                {sessionStorage.getItem("LockedOut") === "true" ? <></> :
                  <Col style={{ marginTop: "10vh" }} lg={6} sm={6}>
                    <button
                      onClick={goToOPO}
                      style={{
                        backgroundColor: "#2d5971",
                        color: "white",
                        borderRadius: "5px",
                        borderStyle: "hidden",
                        width: "100%",
                        height: "auto",
                        padding: "0.5em 2em",
                        fontSize: "25px",
                      }}
                      >
                      <FontAwesomeIcon
                        style={{ textDecoration: "none", color: "white" }}
                        icon={faPlusSquare}
                        />{" "}
                      Onderwerp Toevoegen
                    </button>
                  </Col>
                }
              </Row>
              <Row>
                {sessionStorage.getItem("LockedOut") === "true" ? <></> :
                  <Col lg={6} sm={8}>
                    <div className="tekstNaamInstelling  mt-5">
                      Onderwerp:
                    </div>
                  </Col>
                }
                <Col lg={6} sm={4}>
                  <div className="tekstNaamInstelling  mt-5">Mijn Quizes:</div>
                </Col>
              </Row>
              <ShowSubjectsFromEmployer />
            </Container>
          )}
        </div>
      ) : ( <><LoadingIcon/></>
      )}
    </div>
  );
}

export default HomePageUser;
