import React, { useEffect, useState, useContext } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import CustomButton from "../../buttons/customButton";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DefaultContext from "../../../contexts/DefaultContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

function FeedbackWebApp() {
    const token = sessionStorage.getItem("complexToken");
    const userId = sessionStorage.getItem("Id");
    const history = useHistory();
    const { addFlashMessage, setAlert } = useContext(DefaultContext);
    let [feedback, setFeedback] = useState("Feedback");
    let [fromChallenge, setFromChallenge] = useState(false);

    useEffect(function() {
        sessionStorage.getItem("challengeId")
            ? setFromChallenge(true)
            : setFromChallenge(false);
        
        window.addEventListener("keyup", handleKeyPress, false);

        return () => {
            window.removeEventListener("keyup", handleKeyPress, false);
        }
    })

    function handleKeyPress(event) {
        switch (event.key)
        {
            case 'Escape':
            {
                goHome();
                break;
            }
        }
    }
    
    function sendFeedback() {
        if (feedback === undefined || feedback === "") return;
        setFeedback("");

        let data = {
            message: feedback.toString(),
            userId: userId,
        }

        axios
            .post(`/Feedbacks/App`, data, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                addFlashMessage("Bericht ingeleverd. Bedankt voor uw feedback!", "success");
                setAlert(true);
                goHome();
            })
            .catch((err) => console.log(err));
    }

    function goHome() {
        history.push("/HomePage");
    }

    

    return (
        <Container>
            {!fromChallenge && (
                <Row>
                    <Col md={1}>
                        <FontAwesomeIcon
                            onClick={goHome}
                            style={{ textDecoration: "none", color: "#2d5971" }}
                            icon={faArrowLeft}
                            size="3x"
                            />
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <h5>
                        Stuur feedback naar de beheerders van de app om ons te helpen de app te verbeteren!
                    </h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card
                        style={{
                            marginBottom: "5vh",
                            //height: "90%",
                            paddingTop: "4.4vh",
                            boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                            <Row>
                                <Col></Col>
                                <Col md={10}>
                                    <textarea
                                        role="textarea"
                                        onChange={(e) => {
                                            feedback = e.target.value;
                                            setFeedback(feedback);
                                        }}
                                        style={{
                                            width:"100%",
                                            //padding: ".1rem",
                                            border: "3px #2d5971 solid",
                                            borderRadius: "8px",
                                            alignItems: "center",
                                            textAlign: "center",
                                        }}
                                        placeholder="Feedback"
                                        />
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row style={{
                                            marginTop:"1rem",
                                            marginBottom:"1rem",
                                        }}>
                                {fromChallenge && (
                                    <Col>
                                        <CustomButton
                                            handler={goHome}
                                            name="Overslaan"
                                            />
                                    </Col>
                                )}
                                <Col>
                                    <CustomButton
                                        handler={sendFeedback}
                                        name="Feedback insturen"
                                        />
                                </Col>
                            </Row>
                        </Card>
                </Col>
            </Row>
            {/* <div
                style={{
                    height:"500rem"
                }}></div> */}
        </Container>
    );
}

export default FeedbackWebApp;