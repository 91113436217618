import { Modal, Button } from "react-bootstrap";
function PopUpConfirmDelete(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Verwijderen
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Quiz verwijderen? Je kan de quiz niet terughalen.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-secondary customButton" onClick={props.onClose}>Annuleer</Button>
        <Button className="btn-primary customButton" onClick={props.onConfirm}>
          Verwijder
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PopUpConfirmDelete;
