import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import axios from "axios";
import LoadingIcon from "../../actions/LoadingIcon";
import uniqid from "uniqid";
import ShowSubjectsFromCoach from "./ShowSubjectsFromCoach";

function HomePageCoach() {
  const [responseNaam, setResponseNaam] = useState("");

  const [organisationLoading, setOrganisationLoading] = useState(true);
  const [roleNaam, setRoleNaam] = useState("");
  const token = sessionStorage.getItem("complexToken");

  const data = {
    id: sessionStorage.getItem("Id"),
  };

  // verkrijg de data van de gebruiker
  const getData = () =>
    axios
      .get(`/Users/${data.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        res.data.userRoles.map((result) => setRoleNaam(result.role.name));

        if (res.data.organisation === null) {
          setResponseNaam("Er is nog geen organisatie aangeduid");
        } else {
          setResponseNaam(res.data.organisation.organisationName);
        }
        setOrganisationLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

  useEffect(() => {
    getData();
  }, []);

  return (
    <div key={uniqid()}>
      {organisationLoading ? (
        <div style={{ marginTop: "40vh" }}>
          <LoadingIcon />
        </div>
      ) : (
        <Container lg={6} sm={12}>
          <Row>
            <Col style={{ marginTop: "5vh" }} lg={6} sm={12}>
              <h5 className="tekstNaamInstelling">Organisatie:</h5>
            </Col>
          </Row>
          <Row>
            <div className="tekstNaamInstelling  mt-3">
              <strong>{responseNaam}</strong>
            </div>
          </Row>
          <Row>
            <Col lg={6} sm={4}>
              <div className="tekstNaamInstelling  mt-5">Onderwerp:</div>
            </Col>
          </Row>
          <ShowSubjectsFromCoach />
        </Container>
      )}
    </div>
    
  );
}

export default HomePageCoach;
