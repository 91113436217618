import React, { useEffect, useState, useContext } from "react";
import { Table, Container, Row, Card, Col } from "react-bootstrap";
import CustomButton from "../buttons/customButton";
import axios from "axios";
import emailjs from "@emailjs/browser";
import LoadingIcon from "../actions/LoadingIcon";
import Context from "..//../contexts/DefaultContext";

function AdminPage() {
  let [employers, setEmployers] = useState([]);
  let [result] = useState([]);
  let [count] = useState(1);
  let [feedback, setFeedback] = useState([]);
  let [usersLoading, setUsersLoading] = useState(true);
  let [feedbackLoading, setFeedbackLoading] = useState(true);

  const token = sessionStorage.getItem("complexToken");
  const organisationId = sessionStorage.getItem("OrganisationId");
  const { addFlashMessage, setAlert } = useContext(Context);
  // initialiseer emailjs zodat bij acceptatie een mail verstuurd kan worden.
  // Dit zal niet meer werken vanaf 30 mei tenzij de email tegen dan al veranderd is naar een van het domein workerpro.
  // Dit is de public key van je emailjs account
  emailjs.init("KgVWX5PAsGfsh2wDK");

  useEffect(() => {
    // api request voor alle geaccepteerde en niet-geaccepteerde coaches en leerkrachten van de admin's organisation
    axios
      .get(`/Users/Organisation/Grade/${organisationId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setEmployers(response.data);
      })
      .catch((error) => console.log(error));
      setUsersLoading(false);
    getFeedback();
  }, []);

  // functie om het account te activeren en de email te versturen
  const activateHandler = async (id) => {
    let employer = employers.find((item) => item.id === id);
    await axios
      .put(`/Users/activate/${id}`, null, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        //console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
        addFlashMessage("Uw account is niet toegestaan om andere gebruikers te activeren.", "warning");
        setAlert(true);
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      });
    await axios
      .get(`/Users/Organisation/Grade/${organisationId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setEmployers(response.data);
        // emailjs gegevens voor correcte template.
        // emailjs.send("service_ndfdr0w", "template_j2ncdtt", {
        //   role_name: employer.grade,
        //   to_email: employer.email,
        // });
      })
      .catch((error) => console.log(error));
  };

  // functie voor het deleten van een account.
  const deleteHandler = async (id) => {
    await axios
      .delete(`/Users/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        //console.log(response.data);
        let newResult = [...employers];
        const index = employers.findIndex((employer) => employer.id === id);
        newResult.splice(index, 1);
        setEmployers(newResult);
      })
      .catch((error) => {
        console.log(error);
        addFlashMessage("Uw account is niet toegestaan om andere gebruikers te verwijderen.", "warning");
        setAlert(true);
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      });
    
  };

  const getFeedback = async () => {
    axios
      .get(`Feedbacks/Organisation/${organisationId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        feedback = response.data.map((item) => (
          <tr key={item.id}>
            <td>{item.user.firstName + " " + item.user.lastName}</td>
            <td>{item.user.grade || "Werknemer"}</td>
            <td>{item.message}</td>
          </tr>
        ));
        setFeedback(feedback);
      })
      .catch((err) => console.log(err));
    setFeedbackLoading(false);
  }

  // map functie om correct de tabel op te bouwen per element in het verkregen resultaat van de api en dan te zetten in de result variabele
  result = employers.map((res) => {
    return (
      <tr key={res.id}>
        <td>{count++}</td>
        <td>
          {res.firstName} {res.lastName}
        </td>
        <td>
        {
          res.grade === "Admin" ? (<b>Beheerder</b>) :
          res.grade === "Employer" ? "Werkgever" :
          res.grade === "Coach" ? "Coach" :
          "Niet ingevuld"
        }
        </td>
        {/* checken of de user geen admin is zodat als deze een grade heeft een andere admin deze niet kan verwijderen. */}
        {res.userRoles.map((result) =>
          result.role.name === "Admin" ? (
            <>
              <td><b>Beheerder</b></td>
              <td></td>
              <td></td>
            </>
            
          ) : result.role.name === "Employer" ||
            result.role.name === "Coach" ? ( //Employees don't get sent from the API
            <>
              <td>Actief</td>
              <td></td>
              <td>
                <CustomButton
                  handler={() => deleteHandler(res.id)}
                  name="Verwijderen"
                />
              </td>
            </>
          ) : (
            <>
              <td>Wachtend</td>
              <td>
                <CustomButton
                  handler={() => activateHandler(res.id)}
                  name="Activeren"
                />
              </td>
              <td>
                <CustomButton
                  handler={() => deleteHandler(res.id)}
                  name="Verwijderen"
                />
              </td>
            </>
          )
        )}
      </tr>
    );
  });

  return (
    <Container>
      {usersLoading ? <LoadingIcon /> : 
        <Row>
          <Col>
            <Card
              style={{
                marginBottom: "5vh",
                height: "90%",
                padding: "2rem",
                paddingTop: "4.4vh",
                boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
              }}
              >
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Gebruiker</th>
                    <th>Functie</th>
                    <th>Status</th>
                    <th colSpan="2">Actie</th>
                  </tr>
                </thead>
                <tbody>{result}</tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      }
      {!feedbackLoading && feedback.length ? (
      <Row>
        <Col>
          <Card
            style={{
              marginBottom: "5vh",
              padding: "4.5vh",
              paddingBottom: "4.5vh",
              boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
            }}
            >
            <Table>
              <thead>
                <tr>
                  <th>Gebruiker</th>
                  <th>Rol</th>
                  <th>Feedbacks</th>
                </tr>
              </thead>
              <tbody>{feedback}</tbody>
            </Table>
          </Card>
        </Col>
      </Row>
      ) : (<Row></Row>)}
    </Container>
  );
}

export default AdminPage;
