import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import CustomButton from "../buttons/customButton";
import axios from "axios";
function Logo() {
  let [pictureLink, setPictureLink] = useState("");
  const role = sessionStorage.getItem("RoleName");
  // Log de gebruiker uit en stuur ze terug naar de login pagina
  function handleLogout() {
    sessionStorage.clear();
    goToLogin();
    refreshPage();
  }
  const history = useHistory();

  function goToLogin() {
    history.push("/sign-in");
  }
  const refreshPage = () => {
    window.location.reload();
  };

  const getProfilePicture = () => {
    let userId = sessionStorage.getItem("Id");
    let token = sessionStorage.getItem("complexToken");
    axios
      .get(`/Photos/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        pictureLink = res.data.url;
        setPictureLink(pictureLink);
      })
      .catch((err) => {
        if (err.status === 404) {
          return; // No profile picture for this user yet
        } else {
          console.log(err); // Otherwise simply log the error
        }
      });
  }

  useEffect(() => {
    if (sessionStorage.getItem("complexToken") !== null)
    getProfilePicture();
  });

  return (
    <div>
      {/* Maak de user icon de dropdown die: profiel, Subject aanmaken (indien Employer) en uitloggen, bevat */}
      <DropdownButton
        style={styleDropDownButton}
        id="dropdown"
        title={pictureLink ? (
          <img src={pictureLink} style={{maxHeight:"3rem", maxWidth:"4rem", borderRadius:"50%"}} />
        ) : (
          <FontAwesomeIcon icon="user-circle" size="3x" />
        )}
      >
        <LinkContainer to={"/Profile"}>
          <Dropdown.Item>Mijn profiel</Dropdown.Item>
        </LinkContainer>
        {role === "Employer" && sessionStorage.getItem("LockedOut") !== "true" ? (
          <div>
            <LinkContainer to={"/ChooseSubject"}>
              <Dropdown.Item location="/ChooseSubject">
                Nieuw Onderwerp
              </Dropdown.Item>
            </LinkContainer>
          </div>
        ) : (
          <div></div>
        )}
        {(role === "Employer" ||
          role === "SuperAdmin") ? (
            <LinkContainer to={"/UserList"}>
              <Dropdown.Item location="/UserList">
                Gebruikerslijst
              </Dropdown.Item>
            </LinkContainer>
        ) : (
          <div></div>
        )}
        {(role === "Employer" ||
          role === "Coach" ||
          role === "Admin") && (
            <LinkContainer
              to={"FeedbackWebApp"}
              >
                <Dropdown.Item
                  location="FeedbackWebApp"
                  onClick={() => {
                    sessionStorage.removeItem("challengeId")
                  }}
                  >
                  Feedback
                </Dropdown.Item>
              </LinkContainer>
          )
        }
        <Dropdown.Item>
          <CustomButton handler={handleLogout} name="Uitloggen" />
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
}
const styleDropDownButton = {
  borderColor: "#2d5971",
  outline: "none",
  boxShadow: "none",
  height: "2rem"
};
export default Logo;
