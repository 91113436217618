import React, { useEffect, useState } from "react";
import { Col, Row, Container, Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
import uniqid from "uniqid";
import LoadingIcon from "../../actions/LoadingIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faClipboardCheck,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import { Pie, Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
// register de nodige elementen van ChartJS
ChartJS.register(
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

function DashboardEmployee() {
  const token = sessionStorage.getItem("complexToken");
  const employeeId = sessionStorage.getItem("employeeId");
  const subjectId = sessionStorage.getItem("subjectId");
  const [quizzesLoading, setQuizzesLoading] = useState(true);
  const history = useHistory();
  let [subjectChallenges, setSubjectChallenges] = useState([]);
  let [employeeResults, setEmployeeResults] = useState([]);
  let [employee, setEmployee] = useState([]);
  let [employeeResultChallengeNames] = useState([]);
  let [employeeResultChallengeScores] = useState([]);
  let [totaalJuist, setTotaalJuist] = useState(0);
  let [totaalFout, setTotaalFout] = useState(0);
  let [dataPie] = useState([]);
  let [dataBarScores] = useState([]);
  let [dataLineAttendance] = useState([]);
  let [ChallengeNames] = useState([]);
  let [AttendanceScores] = useState([]);
  let [count] = useState(0);
  let [countAttend] = useState(0);

  useEffect(function () {
    getEmployee();
    getSubjectChallenges();
    getEmployeeResults();
  }, []);

  // krijg de data van de Employee
  const getEmployee = () => {
    Axios.get(`/Users/${employeeId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setEmployee(response.data);
      })
      .catch((error) => console.log(error));
  };
  // krijg de data van alle quizzen van deze les
  const getSubjectChallenges = () => {
    Axios.get(`/Challenges/Subjects/${subjectId}/WebApp`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setSubjectChallenges(response.data);
      })
      .catch((error) => console.log(error));
  };
  // krijg de data van alle resultaten van een Employee in deze les
  const getEmployeeResults = () => {
    Axios.get(`/Results/Employee/${employeeId}/Subject/${subjectId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setEmployeeResults(response.data);
        // tel de totaal aantal juiste en foute antwoorden van de Employee op
        setTotaalJuist(0);
        setTotaalFout(0);
        response.data.forEach((element) => {
          setTotaalJuist((totaalJuist) => totaalJuist + element.score);
          setTotaalFout(
            (totaalFout) =>
              totaalFout + (element.challenge.questions.length - element.score)
          );
        });
        setQuizzesLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const goBack = () => {
    history.goBack();
  };

  // zet de data van de deelname cirkeldiagram juist
  dataPie = {
    labels: ["niet deelgenomen", "deelgenomen"],
    datasets: [
      {
        label: "Aantal deelgenomen en niet-deelgenomen",
        data: [
          subjectChallenges.length - employeeResults.length,
          employeeResults.length,
        ],
        backgroundColor: ["rgba(255, 50, 50, 0.5)", "rgba(5, 195, 5, 0.5)"],
        borderColor: ["rgba(255, 50, 50, 1)", "rgba(5, 195, 5, 1)"],
        borderWidth: 1,
      },
    ],
  };
  // bereken de percentages voor de staafdiagram
  employeeResultChallengeNames = [];
  employeeResultChallengeScores = [];
  employeeResults.map((res, index) => {
    employeeResultChallengeNames.push(res.challenge.title);
    employeeResultChallengeScores.push(
      (res.score / res.challenge.questions.length) * 100
    );
  });
  // zet de data in de staafdiagram voor scores
  dataBarScores = {
    labels: employeeResultChallengeNames,
    datasets: [
      {
        label: "Scores op deelgenomen testen uitgedrukt in %",
        data: employeeResultChallengeScores,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgb(75,192,192)",
        borderWidth: 1,
      },
    ],
    scales: {
      y: {
        min: 0,
        max: 100,
      },
    },
  };
  // maak de data aan voor de lijndiagram van aanwezigheid. We mappen hiervoor de data van alle quizzen van dit vak om zo aanwezigheid te berekenen
  ChallengeNames = [];
  AttendanceScores = [];
  count = 0;
  countAttend = 0;
  subjectChallenges.map((res, index) => {
    ChallengeNames.push(res.title);
    let attended = false;
    count++;
    employeeResults.forEach((element) => {
      if (element.challenge.id === res.id) {
        attended = true;
      }
    });
    if (attended === true) {
      countAttend++;
    }
    AttendanceScores.push((countAttend / count) * 100);
  });

  // zet de data voor de lijndiagram in de lijndiagram
  dataLineAttendance = {
    labels: ChallengeNames,
    datasets: [
      {
        label: "Aanwezigheid van test tot test uitgedrukt in %",
        data: AttendanceScores,
        fill: false,
        borderColor: "rgba(75,192,192,1)",
      },
    ],
    scales: {
      y: {
        min: 0,
        max: 100,
      },
    },
  };

  const options = {
    scales: {
      y: {
        min: 0,
        max: 100,
      },
      x: {},
    },
  };

  return (
    <div>
      <Container style={{ marginBottom: "1rem" }}>
        <Row>
          <Col sm={1} lg={1}>
            <div>
              {
                <FontAwesomeIcon
                  onClick={goBack}
                  style={{ textDecoration: "none", color: "#2d5971" }}
                  icon={faArrowLeft}
                  size="3x"
                />
              }
            </div>
          </Col>
          {quizzesLoading ? (
            <div style={{ marginRight: "20vw", marginTop: "10vh" }}>
              <LoadingIcon />
            </div>
          ) : (
            <Col sm={10}>
              <h1>
                {employee.firstName} {employee.lastName}
              </h1>
              <h2>{sessionStorage.getItem("subjectName")}</h2>
            </Col>
          )}
        </Row>
      </Container>

      <Container key={uniqid()}>
        {quizzesLoading ? (
          <div style={{ marginRight: "20vw", marginTop: "10vh" }}>
            <LoadingIcon />
          </div>
        ) : (
          <div>
            <Row>
              <Col md={8}>
                <Col>
                  <Row>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          height: "90%",
                          paddingTop: "4.4vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            paddingLeft: "3vh",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              /** style om de FontAwesome iconen weer te geven als contour zonder de betalende versie te gebruiken
                               * backgroundcolor is voor de kleur rond het icoon
                               * color is dezelfde als de background om te doen alsof deze doorzichtig is
                               * stroke geven we de kleur die de contour heeft
                               * strokewidth bepaalt de breedte van de contour*/
                              textDecoration: "none",
                              color: "rgba(30, 212, 84, 0.2)",
                              marginBottom: "3vh",
                              backgroundColor: "rgba(30, 212, 84, 0.2)",
                              padding: "1.5vh 2.5vh",
                              borderRadius: "20px",
                              stroke: "rgba(30, 200, 84, 1)",
                              strokeWidth: "20px",
                            }}
                            icon={faClipboardCheck}
                            size="10x"
                          />
                          <div>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              Aantal voltooide quizzen
                            </p>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "2.5rem",
                                float: "left",
                              }}
                            >
                              {employeeResults.length}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          height: "90%",
                          paddingTop: "4.4vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            paddingLeft: "3vh",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              textDecoration: "none",
                              color: "rgba(255, 59, 121, 0.2)",
                              marginBottom: "3vh",
                              backgroundColor: "rgba(255, 59, 121, 0.2)",
                              padding: "1.5vh 2.5vh",
                              borderRadius: "20px",
                              stroke: "rgba(255, 59, 105, 1)",
                              strokeWidth: "20px",
                            }}
                            icon={faClipboardList}
                            size="10x"
                          />
                          <div>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              Aantal gemiste quizzen
                            </p>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "2.5rem",
                                float: "left",
                              }}
                            >
                              {subjectChallenges.length - employeeResults.length}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          height: "90%",
                          paddingTop: "4.4vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            paddingLeft: "3vh",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              textDecoration: "none",
                              color: "rgba(30, 212, 84, 0.2)",
                              marginBottom: "3vh",
                              backgroundColor: "rgba(30, 212, 84, 0.2)",
                              padding: "1.5vh 2.5vh",
                              borderRadius: "20px",
                              stroke: "rgba(30, 200, 84, 1)",
                              strokeWidth: "20px",
                            }}
                            icon={faClipboardCheck}
                            size="10x"
                          />
                          <div>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              Totaal juiste antwoorden
                            </p>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "2.5rem",
                                float: "left",
                              }}
                            >
                              {totaalJuist}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card
                        style={{
                          marginBottom: "5vh",
                          height: "90%",
                          paddingTop: "4.4vh",
                          boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            paddingLeft: "3vh",
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              textDecoration: "none",
                              color: "rgba(255, 59, 121, 0.2)",
                              marginBottom: "3vh",
                              backgroundColor: "rgba(255, 59, 121, 0.2)",
                              padding: "1.5vh 2.5vh",
                              borderRadius: "20px",
                              stroke: "rgba(255, 59, 105, 1)",
                              strokeWidth: "20px",
                            }}
                            icon={faClipboardList}
                            size="10x"
                          />
                          <div>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "1.1rem",
                              }}
                            >
                              Totaal foute antwoorden
                            </p>
                            <p
                              style={{
                                color: "#2d5971",
                                margin: "0 2vh",
                                fontSize: "2.5rem",
                                float: "left",
                              }}
                            >
                              {totaalFout}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Col>
              <Col md={4}>
                <Card
                  style={{
                    marginBottom: "5vh",
                    height: "90%",
                    paddingTop: "4.4vh",
                    paddingBottom: "4.4vh",
                    boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <Pie data={dataPie} style={{ maxHeight: "37.5vh" }}></Pie>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={AttendanceScores.length < 8 ? 6 : 12}>
                <Card
                  style={{
                    marginBottom: "5vh",
                    padding: "4.5vh",
                    boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <Bar data={dataBarScores} options={options} />
                </Card>
              </Col>
              <Col md={AttendanceScores.length < 8 ? 6 : 12}>
                <Card
                  style={{
                    marginBottom: "5vh",
                    padding: "4.5vh",
                    boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <Line data={dataLineAttendance} options={options} />
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </div>
  );
}
export default DashboardEmployee;
