import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import uniqid from "uniqid";
import LoadingIcon from "../../actions/LoadingIcon";
import { Col, Row } from "react-bootstrap";

let subjectsName = "";

function ShowSubjectsFromProf() {
  const [subjectsProf, setSubjectsProf] = useState([]);
  let [responseStudieRichting, setResponseStudieRichting] = useState([]);
  const [subjectsLoading, setSubjectsLoading] = useState(true);
  const token = sessionStorage.getItem("complexToken");
  const organisationId = sessionStorage.getItem("OrganisationId");

  // krijg alle lessen van de coach zijn organisation
  useEffect(function () {
    axios
      .get(`Subjects/Organisation/${organisationId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setSubjectsLoading(false);
        setSubjectsProf(res.data.organisationSubjects);
        setResponseStudieRichting(subjectsName);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // map alle lessen van de coach
  responseStudieRichting = subjectsProf.map((result) => (
    <div key={result.id}>
      <Row>
        <Col lg={6} sm={4}>
          <Link
            style={{
              textDecoration: "none",
              color: "#5094B9",
              hover: "#2d5971",
            }}
            value={result.subjectId}
            to={{ pathname: "/AvailableQuizesCoach" }}
            onClick={() =>
              handleClick2(result.subjectId, result.subject.subjectName)
            }
            className="tekstNaamInstelling ml-0 mt-3"
          >
            {result.subject.subjectName}
          </Link>
        </Col>
      </Row>
    </div>
  ));

  const handleClick2 = (id, subjectName) => {
    sessionStorage.setItem("subjectId", id);
    sessionStorage.setItem("subjectName", subjectName);
  };

  return (
    <div key={uniqid()}>
      {subjectsLoading ? (
        <div style={{ marginRight: "10vw", marginTop: "10vh" }}>
          <LoadingIcon />
        </div>
      ) : (
        <div>{responseStudieRichting}</div>
      )}
    </div>
  );
}

export default ShowSubjectsFromProf;
