import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, Container, InputGroup, FormControl, Button } from "react-bootstrap";
import Axios from "axios";

import { DatePicker } from "react-nice-dates";
import { nlBE } from "date-fns/locale";
import CustomButton from "../../buttons/customButton";
import LoadingIcon from "../../actions/LoadingIcon";

function ReminderSet() {
    let history = useHistory();
    const token = sessionStorage.getItem("complexToken");
    let challengeId = sessionStorage.getItem("challengeId");
    let userId = sessionStorage.getItem("Id");
    let [startDate, setStartDate] = useState( new Date() );
    let [endDate, setEndDate] = useState( new Date() );
    let [reminderDays, setReminderDays] = useState();
    let [reminderMessage, setReminderMessage] = useState();
    let [reminderDate, setReminderDate] = useState( new Date() );
    let [challenge, setChallenge] = useState();

    let [insertFeedback, setInsertFeedback] = useState(false);

    let [settingsLoading, setSettingsLoading] = useState(true);

    useEffect(function() {
        getChallengeData();

        window.addEventListener("keyup", handleKeyPress);

        return () => {
            window.removeEventListener("keyup", handleKeyPress, false);
        }
    }, []);

    function handleKeyPress(event) {
        switch (event.key)
        {
            case 'Escape':
            {
                next();
                break;
            }
        }
    }

    const getChallengeData = async() => {
        await Axios
        .get(`/Challenges/${challengeId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
            // console.log(response.data);
            challenge = response.data;
            setChallenge(challenge);
            setStartDate(new Date(response.data.startDate));
            endDate = new Date(response.data.endDate);
            setEndDate(endDate);
            if (challenge.reminderMessage !== undefined && challenge.reminderMessage !== null && challenge.reminderMessage !== "")
            {
                setReminderDate(new Date(challenge.reminderTimeStamp));
                setReminderMessage(challenge.reminderMessage);
            } else {
                getUserSettings();
            }
        })
        .catch((error) => console.log(error));
        setSettingsLoading(false);
    };

    const getUserSettings = async() => {
        await Axios.get(`/Settings/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
            // console.log(response.data);
            if (response.data !== undefined && response.data !== null && response.data.length !== 0) {
                setReminderMessage(response.data.defaultReminderMessage);
                reminderDays = response.data.defaultReminderDays;
                setReminderDays(reminderDays);
                reminderDate = new Date(endDate.setDate(endDate.getDate() + reminderDays));
                setReminderDate(reminderDate);
            } else {
                setReminderMessage("Melding");
                setReminderDate(endDate);
            }
            getChallengesData();
        })
        .catch((error) => console.log(error));
    };

    const getChallengesData = async() => {
        await Axios.get(`/Challenges/Employer/${userId}`, {
            headers: { Authorization: `Bearer ${token}`},
        })
        .then((res) => {
            let lastChallengeStartDate = new Date(null);
            let now = new Date();
            res.data.map((challenge) => {
                if (new Date(challenge.startDate) > lastChallengeStartDate &&
                    new Date(challenge.startDate) < now &&
                    challenge.id != challengeId
                    )
                {
                    lastChallengeStartDate = new Date(challenge.startDate);
                }

            });
            let reminderTime = lastChallengeStartDate;
            reminderTime.setDate(reminderTime.getDate() + reminderDays);
            if (reminderTime < now)
            {
                setInsertFeedback(true);
            }
        })
        .catch((err) => console.log(err));
    }

    const updateChallenge = async() => {
        challenge.reminderTimeStamp = reminderDate;
        challenge.reminderRead = false; // Should already be false by default.
        challenge.reminderMessage = reminderMessage;
        await Axios
        .put(`/Challenges/${challengeId}`, challenge, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
            insertFeedback
                ? history.push("/FeedbackWebApp")
                : goHome()
        })
        .catch((err) => console.log(err));
    }

    const next = () => {
        insertFeedback
                ? history.push("/FeedbackWebApp")
                : goHome()
    }

    const goHome = () => {
        history.push("HomePage");
        history.go(0);
    }

    return (
        <Container>
            {settingsLoading ? <LoadingIcon /> :
                <Row>
                    <Col>
                        <Card
                            style={{
                                marginBottom: "5vh",
                                height: "90%",
                                paddingTop: "4.4vh",
                                boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                            }}
                            >
                            <Row
                                style={{
                                    marginLeft: "6rem",
                                    marginRight: "6rem",
                                }}
                                >
                                <Col className="tekstNaamInstelling">
                                    <label>Melding Bericht:</label>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <FormControl
                                            defaultValue={reminderMessage || ""}
                                            type="text"
                                            aria-label="Melding bericht"
                                            aria-describedby=""
                                            onChange={(e) => setReminderMessage(e.target.value)}
                                            value={reminderMessage}
                                            ></FormControl>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    marginLeft: "6rem",
                                    marginRight: "6rem",
                                }}
                                >
                                <Col className="tekstNaamInstelling">
                                    <label>Melding Datum:</label>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <DatePicker
                                            date={reminderDate}
                                            onDateChange={setReminderDate}
                                            format="dd MMM yyyy"
                                            locale={nlBE}
                                            minimumDate={startDate || new Date()}
                                            >
                                                {({ inputProps, focused }) => (
                                                    <input
                                                    className={'input' + (focused ? ' -focused' : '')}
                                                    {...inputProps}
                                                    />
                                                )}
                                        </DatePicker>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CustomButton
                                        handler={next}
                                        name="Overslaan"
                                    />
                                </Col>
                                <Col>
                                    <CustomButton
                                        handler={updateChallenge}
                                        name="Indienen"
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            }
        </Container>
    )
}

export default ReminderSet;