import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CustomButton from '../buttons/customButton';
import { Table, Col, Row, Container, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";

function SuperAdminPage() {
  let [organisations, setOrganisations] = useState([]);
  let [adminList, setAdminList] = useState([]);
  let [tableOrganisations, setTableOrganisations] = useState([]);
  let [tableMoodQuestions, setTableMoodQuestions] = useState([]);
  let [newOrganisation, setNewOrganisation] = useState();
  let [newMoodQuestion, setNewMoodQuestion] = useState();
  let [moodQuestions, setMoodQuestions] = useState([]);
  let [feedbacks, setFeedbacks] = useState([]);
  let [tableNewOrganisations, setTableNewOrganisations] = useState([]);

  let [showOrganisations, setShowOrganisations] = useState(true);
  
  let [pauzablesTable, setPauzablesTable] = useState();
  let [pauzableUsers, setPauzableUsers] = useState([]);
  let [pauzableFilterName, setPauzableFilterName] = useState("");
  let [pauzableFilterFunction, setPauzableFilterFunction] = useState("");
  let [pauzableFilterOrg, setPauzableFilterOrg] = useState("");
  let [pauzableFilterStatus, setPauzableFilterStatus] = useState("");

  const token = sessionStorage.getItem("complexToken");

  useEffect(() => {
    getOrganisations();
    getNewOrganisations();
    getUsers();
    getMoodQuestions();
    getSuperAdminFeedbacks();
    getUsersForPauze();
  }, []);

  async function toggleTable(e) {
    e.preventDefault();
    showOrganisations = !showOrganisations
    setShowOrganisations(showOrganisations);
    if(showOrganisations) {
      getOrganisations();
    } else {
      getMoodQuestions();
    }
  }

  const getOrganisations = async () => {
    axios
      .get(`/Organisations`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        organisations = response.data;
        setOrganisations(organisations);
        tableOrganisations = organisations.map((organisation) => (
          <tr key={organisation.id} style={{height:"3.6rem"}}>
            <td>{organisation.organisationName}</td>
          </tr>
        ));
        setTableOrganisations(tableOrganisations);
      })
      .catch((error) => console.log(error));
  };
  async function getNewOrganisations() {
    axios
    .get(`/Organisations/New`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      tableNewOrganisations = res.data.map((org) => (
        <tr key={org.id}>
          <td>{org.organisationName}</td>
          <td>
            <Row>
              <Col>
                <CustomButton 
                  handler={() => acceptOrg(org.id)}
                  name="Acccepteer"
                  />
              </Col>
              <Col>
                <CustomButton
                  handler={() => deleteOrg(org.id)}
                  name="Verwijderen"
                  />
              </Col>
            </Row>
          </td>
        </tr>
      ));
      setTableNewOrganisations(tableNewOrganisations);
    })
    .catch((err) => console.log(err));
  }
  async function acceptOrg(id) {
    axios
    .put(`/Organisations/Accept/${id}`, {id: id}, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(() => {
      getOrganisations();
      getNewOrganisations();
      getUsers();
      getUsersForPauze();
    })
    .catch((err) => console.log(err));
  }
  const getMoodQuestions = async () => {
    axios
      .get(`/MoodQuestions`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        moodQuestions = response.data;
        setMoodQuestions(moodQuestions);
        tableMoodQuestions = moodQuestions.map((moodQuestion) => (
          <tr key={moodQuestion.id} style={{height:"3.6rem"}}>
            <td>{moodQuestion.question}</td>
          </tr>
        ));
        setTableMoodQuestions(tableMoodQuestions);
      })
      .catch((error) => console.log(error));
  };
  async function deleteOrg(id) {
    axios
    .delete(`/Organisations/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      getOrganisations();
      getNewOrganisations();
    })
    .catch((err) => console.log(err));
  }

  async function getSuperAdminFeedbacks() {
    axios
      .get(`/Feedbacks/SuperAdmin`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        feedbacks = [];
        feedbacks = res.data.map((el) => (
          <tr>
            <td>{el.user.firstName} {el.user.lastName}</td>
            <td>{el.message}</td>
          </tr>
        ));
        setFeedbacks(feedbacks);
      }).catch((err) => console.log(err));
  }

  const activateHandler = async (id) => {
    await axios
      .put(`Users/Activate/${id}`, null, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        getUsers();
      })
      .catch((error) => console.log(error));
  }
  const deleteHandler = async (id) => {
    await axios
      .delete(`/Users/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        getUsers();
        getUsersForPauze();
      })
      .catch((error) => console.log(error));
  }

  const getUsers = () => {
    axios.get(`Users/Admin`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      adminList = response.data.map((user) => {
        if (user.grade === "Admin" ) //Shouldn't be neccessary here, but only admins will be shown
        return (
          <tr key={user.id} style={{height:"3.6rem"}}>
            <td>{user.firstName} {user.lastName}</td>
            <td>{user.grade == "Admin" ? "Beheerder" : user.grade}</td>
            {user.userRoles.map((userRole) => 
              userRole.role.name !== "Admin" ? (
                <td key={userRole.id}>
                  <CustomButton
                    handler={() => activateHandler(user.id)}
                    name="Activeren"
                  />
                </td>
              ) : (
                <td key={userRole.id}>Actief</td>
              )
            )}
            <td>
              <CustomButton
                handler={() => deleteHandler(user.id)}
                name="Verwijderen"
              />
            </td>
          </tr>
        )
      });
      setAdminList(adminList);

    })
  }

  async function getUsersForPauze() {
    axios.
      get(`/Users/Pauzable`, {
        headers: { Authorization: `Bearer ${token}`},
      })
      .then((res) => {
        setPauzableUsers(res.data);
      })
  }

  function roleTranslate(role) {
    switch (role) {
      case "Employer": return "Werkgever"
      case "Coach": return "Coach"
      case "Admin": return "Beheerder"
      default: return role
    }
  }

  pauzablesTable = pauzableUsers
    .filter(
      (user) =>
        (
          user.firstName.toLowerCase().includes(pauzableFilterName.toLowerCase())
          ||
          user.lastName.toLowerCase().includes(pauzableFilterName.toLowerCase())
        )
        &&
        roleTranslate(user.grade).toLowerCase().includes(pauzableFilterFunction.toLowerCase())
        &&
        user.organisation.organisationName.toLowerCase().includes(pauzableFilterOrg.toLowerCase())
        &&
        (pauzableFilterStatus !== "" ? (user.lockoutEnabled ? "hervatten".includes(pauzableFilterStatus.toLowerCase()) : "pauzeren".includes(pauzableFilterStatus.toLowerCase())) : true)
    )
    .map((user) => 
      {
        return (
        <tr key={"pauzable" + user.id}>
          <td>{user.firstName} {user.lastName}</td>
          <td>
            {roleTranslate(user.grade)}
          </td>
          <td>{user.organisation.organisationName}</td>
          <td>
            {user.lockoutEnabled ? <p style={{color:"#e61c1c", marginRight: "0.5rem"}}>Gepauzeerd</p> : <p style={{color:"#1ed454", marginRight: "0.5rem"}}>Actief/hervat</p>}
            {user.lockoutEnabled ? 
              <CustomButton
                handler={() => resumeHandler(user.id)}
                name="Hervatten"
              />
            :
              <CustomButton
                handler={() => pauzeHandler(user.id)}
                name="Pauzeren"
              />
            }
            <CustomButton
              handler={() => deleteHandler(user.id)}
              name="Verwijderen"
              />
          </td>
        </tr>
        );
      }
    );

  async function pauzeHandler(id) {
    axios
    .delete(`/Users/Deactivate/${id}`, {
      headers: { Authorization: `Bearer ${token}`},
    })
    .then((res) => {
      getUsersForPauze();
    })
    .catch((err) => console.log(err));
  }
  async function resumeHandler(id) {
    axios
    .get(`/Users/Activate/${id}`, {
      headers: { Authorization: `Bearer ${token}`},
    })
    .then((res) => {
      getUsersForPauze();
    })
    .catch((err) => console.log(err));
  }

  function submitOrganisation(e) {
    e.preventDefault();

    let data = {
      organisationName: newOrganisation
    };

    axios.
      post(`Organisations`, data, {
        headers: { Authorization: `Bearer ${token}`},
      })
      .then((response) => {
        getOrganisations();
      })
      .catch((err) => console.log(err));
  };
  function submitMoodQuestion(e) {
    e.preventDefault();

    let data = {
      Question: newMoodQuestion
    };
    axios
      .post(`MoodQuestions`, data, {
        headers: { Authorization: `Bearer ${token}`},
      })
      .then((response) => {
        getMoodQuestions();
      })
      .catch((err) => console.log(err));
  }


  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Card
              style={{
                marginBottom: "5vh",
                padding: "4.5vh",
                paddingBottom: "4.5vh",
                boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                overflowY: "scroll",
                height: "30rem",
              }}
              >
              <Table>
                <thead>
                  <tr>
                    <th>{showOrganisations ? "Organisaties:" : "Emotionele vragen:"}</th>
                  </tr>
                </thead>
                <tbody>
                  {showOrganisations ? tableOrganisations : tableMoodQuestions}
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col>
            <Card
              style={{
                marginBottom: "5vh",
                padding: "4.5vh",
                paddingBottom: "4.5vh",
                boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                overflowY: "scroll",
                height: "30rem",
              }}
              >
              <Table>
                <thead>
                  <tr>
                    <th>Naam</th>
                    <th>Functie</th>
                    <th colSpan="2">Actie</th>
                  </tr>
                </thead>
                <tbody>
                  {adminList}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <form onSubmit={submitOrganisation}>
              <input 
                required
                placeholder="Nieuwe Organisatie"
                className="form-control"
                pattern="[a-zA-Z -]{1,64}"
                onChange={(e) => setNewOrganisation(e.target.value)}
              />
              <CustomButton
                style={{
                  backgroundColor: "#2d5971",
                  color: "white",
                  borderRadius: "5px",
                  borderStyle: "hidden",
                  width: "100%",
                  height: "auto",
                  padding: "0.5em 2em",
                  fontSize: "25px",
                }}
                name={(
                  <div>
                    <FontAwesomeIcon
                      style={{ textDecoration: "none", color: "white" }}
                      icon={faPlusSquare}
                    />{" "}
                    Organisatie Toevoegen
                  </div>
                
                )}
                />
            </form>
          </Col>
          <Col>
            <form onSubmit={submitMoodQuestion}>
              <input
                required
                placeholder="Nieuwe Emotionele vraag?"
                className="form-control"
                pattern="[a-zA-Z -?]{1,64}"
                onChange={(e) => setNewMoodQuestion(e.target.value)}
              />
              <CustomButton
                style={{
                  backgroundColor: "#2d5971",
                  color: "white",
                  borderRadius: "5px",
                  borderStyle: "hidden",
                  width: "100%",
                  height: "auto",
                  padding: "0.5em 2em",
                  fontSize: "25px",
                }}
                name={(
                  <div>
                    <FontAwesomeIcon
                      style={{ textDecoration: "none", color: "white" }}
                      icon={faPlusSquare}
                    />{" "}
                    Emotionele vraag Toevoegen
                  </div>
                
                )}
                />
            </form>
          </Col>
        </Row>
        <Row>
          <Col>
            <form onSubmit={toggleTable}>
              <CustomButton
                name="&larr; Wissel &rarr;"
              />
            </form>
          </Col>
        </Row>
        {tableNewOrganisations.length > 0 ?
          <Row>
            <Col>
              <Card
                style={{
                  marginBottom: "5vh",
                  padding: "4.5vh",
                  paddingBottom: "4.5vh",
                  boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                  overflowY: "scroll",
                  height: "30rem",
                }}
                >
                <Table>
                  <thead>
                    <tr>
                      <th>Nieuwe organisatie</th>
                      <th>Actie</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableNewOrganisations}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
          :
          <Row>
            <Col>
              <p>
                <br/>
                Er zijn geen nieuwe organisaties.
              </p>
            </Col>
          </Row> }
        { feedbacks.length > 0 ? 
          <Row>
            <Col>
              <Card
                style={{
                  marginBottom: "5vh",
                  padding: "4.5vh",
                  paddingBottom: "4.5vh",
                  boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                  overflowY: "scroll",
                  height: "30rem",
                }}
                >
                  <Table>
                    <thead>
                      <tr>
                        <td>Gebruiker</td>
                        <td>Feedback</td>
                      </tr>
                    </thead>
                    <tbody>
                      {feedbacks}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
           : <></>}
          <Row>
            <Col>
              <Card
                style={{
                  marginBottom: "5vh",
                  padding: "4.5vh",
                  paddingBottom: "4.5vh",
                  boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                  overflowY: "scroll",
                  height: "30rem",
                }}
                >
                <h2>Werkgevers, Coaches en Beheerders met hun status</h2>
                <Table>
                  <thead>
                    <tr>
                      <th>Gebruiker</th>
                      <th>Functie</th>
                      <th width="5rem">Organisatie</th>
                      <th>Actie</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          onChange={(e) => setPauzableFilterName(e.target.value)}
                          placeholder="Zoek op naam"
                          />
                      </td>
                      <td>
                        <input
                          onChange={(e) => setPauzableFilterFunction(e.target.value)}
                          placeholder="Zoek op functie"
                          />
                      </td>
                      <td>
                        <input
                          onChange={(e) => setPauzableFilterOrg(e.target.value)}
                          placeholder="Zoek op organisatie"
                          />
                      </td>
                      <td>
                        <input
                          onChange={(e) => setPauzableFilterStatus(e.target.value)}
                          placeholder="Zoek op actie"
                          />
                      </td>
                    </tr>
                    {pauzablesTable}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
      </Container>
    </div>
  );
}

export default SuperAdminPage;
