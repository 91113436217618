import React from "react";
import { InputGroup, FormControl } from "react-bootstrap";
function CustomInputField(props) {
  return (
    <>
      <InputGroup
        key={props.key}
        onDoubleClick={props.clickHandler}
        className="mb-3  mr-5"
      >
        <FormControl
          style={props.style}
          onChange={props.handler}
          name={props.name}
          defaultValue={props.value || ""}
          placeholder={props.placeholder}
          type="text"
          aria-label="Username"
          aria-describedby=""
        ></FormControl>
      </InputGroup>
    </>
  );
}

export default CustomInputField;
