import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Container, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Axios from "axios";
import LoadingIcon from "../../actions/LoadingIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PopUpConfirmDelete from "../../actions/popUpConfirmDelete";
import GoBack from "../../buttons/goBackButton";
import Context from "../../../contexts/DefaultContext";

let title = [];
function AvailableQuizesPerSubject() {
  let [userChallenge, setUserChallenge] = useState([]);
  const subjectId = sessionStorage.getItem("subjectId");
  let [startDate, setStartDate] = useState(null);
  let [challengeId, setChallengeId] = useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  let newDate = new Date();
  const token = sessionStorage.getItem("complexToken");
  let [feedbacks, setFeedbacks] = useState([]);
  let [quizzesLoading, setQuizzesLoading] = useState(true);
  let [feedbacksLoading, setFeedbacksLoading] = useState(true);
  const { addFlashMessage, setAlert } = useContext(Context);

  useEffect(function () {
    getData();
    getFeedBacks();
  }, []);

  const getData = () => {
    // krijg data van alle quizen van een Subject
    Axios.get(`/Challenges/Subjects/${subjectId}/WebApp`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setUserChallenge(response.data);
        userChallenge.map((res) => setStartDate(res.startDate));
        setQuizzesLoading(false);
      })
      .catch((error) => console.log(error));
  }

  async function getFeedBacks() {
    Axios
      .get(`/Feedbacks/Subject/${subjectId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        feedbacks = response.data.map((item) => (
          <tr key={item.id}>
            <td>{item.user.firstName + " " + item.user.lastName}</td>
            <td>{item.message}</td>
          </tr>
        ));
        setFeedbacks(feedbacks);
        setFeedbacksLoading(false);
      })
      .catch((err) => console.log(err));
  }

  const handleClick = (id, quizName) => {
    sessionStorage.setItem("challengeId", id);
    sessionStorage.setItem("quizName", quizName);
  };
  // functie voor het deleten van een gekozen quiz
  const deleteChallenge = async (id) => {
    await Axios.delete(`/Challenges/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        addFlashMessage("Uw account is niet toegestaan om quizzen te verwijderen.", "warning");
        setAlert(true);
        setTimeout(() => {
          handleClose();
          window.location.reload();
        }, 2000);
      });
  };
  const handleClose = () => setModalShow(false);

  const deleteChallengeId = (id) => {
    setChallengeId(id);
  };

  // map alle quizen
  title = userChallenge.map((result) => {
    return (
      <Container key={result.id}>
        <Row>
          <Col lg={6} sm={10}>
            {/* toon enkel de optie voor aanpassen als quiz nog niet begonnen is */}
            {new Date(result.startDate) >= newDate && sessionStorage.getItem("LockedOut") !== "true" ? (
              <Link
                style={{
                  textDecoration: "none",
                  color: "#5094B9",
                  hover: "#2d5971",
                }}
                className="tekstNaamInstelling ml-0 mt-3"
                to={{ pathname: "/UpdateQuestions2", customObject: title }}
                onClick={() => handleClick(result.id, result.title)}
              >
                {result.title}
              </Link>
            ) : (
              <div
                style={{
                  textDecoration: "none",
                  color: "#5094B9",
                  opacity: "0.5",
                }}
                className="tekstNaamInstelling ml-0 mt-3"
              >
                {result.title}
              </div>
            )}
          </Col>
          <Col style={{ marginTop: "3vh" }} lg={2} sm={2}>
            {/* toon enkel de optie voor deleten als quiz nog niet begonnen is */}
            {/* en als de gebruiker niet Locked Out is */}
            {(new Date(result.startDate) >= newDate && sessionStorage.getItem("LockedOut") !== "true") ? (
              <div onClick={() => setModalShow(true)}>
                {
                  <FontAwesomeIcon
                    onClick={() => deleteChallengeId(result.id)}
                    style={{
                      textDecoration: "none",
                      color: "#5094B9",
                      cursor: "pointer",
                    }}
                    icon={["fas", "trash"]}
                    size="2x"
                  />
                }
              </div>
            ) : (
              <div>
                {
                  <FontAwesomeIcon
                    style={{
                      textDecoration: "none",
                      color: "#5094B9",
                      opacity: "0.5",
                    }}
                    icon={["fas", "trash"]}
                    size="2x"
                  />
                }
              </div>
            )}
          </Col>
          <Col style={{ marginTop: "3vh" }} lg={2} sm={2}>
            {/* toon enkel de optie voor analyse als quiz al begonnen is */}
            {new Date(result.startDate) <= newDate ? (
              <div>
                {
                  <Link
                    to={{ pathname: "/Dashboard", customObject: title }}
                    onClick={() => handleClick(result.id, result.title)}
                  >
                    <FontAwesomeIcon
                      style={{
                        textDecoration: "none",
                        color: "#5094B9",
                        cursor: "pointer",
                      }}
                      icon={["fas", "chart-bar"]}
                      size="2x"
                    />
                  </Link>
                }
              </div>
            ) : (
              <div>
                {
                  <FontAwesomeIcon
                    style={{
                      textDecoration: "none",
                      color: "#5094B9",
                      opacity: "0.5",
                    }}
                    icon={["fas", "chart-bar"]}
                    size="2x"
                  />
                }
              </div>
            )}
          </Col>
        </Row>
        <PopUpConfirmDelete
          show={modalShow}
          onConfirm={() => deleteChallenge(challengeId)}
          onClose={() => handleClose()}
        />
      </Container>
    );
  });

  return (
    <div>
      <Container>
        <Row>
          <Col sm={1} lg={1}>
            <GoBack />
          </Col>
          <Col sm={10}>
            <h1>{sessionStorage.getItem("subjectName")}</h1>
          </Col>
        </Row>
      </Container>

      {quizzesLoading ? (
        <LoadingIcon />
      ) : (
        <div style={{minHeight: "25rem", maxHeight: "30rem", overflowY: "scroll", borderRadius: "0",}}>
          {title}
        </div>
      )}
      <br/>
      {feedbacksLoading && feedbacks.length ? (
        <Container>
          <Row>
            <Col>
              <Card
                style={{
                  marginBottom: "5vh",
                  padding: "4.5vh",
                  paddingBottom: "4.5vh",
                  boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                  overflowY: "scroll",
                  borderRadius: "0",
                  maxHeight: "15rem",
                }}
                >
                <Table>
                  <thead>
                    <tr>
                      <th>Gebruiker</th>
                      <th>Feedbacks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {feedbacks}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (<Container></Container>)}
    </div>
  );
}

export default AvailableQuizesPerSubject;
