import React, { useEffect, useContext } from "react";
import { Alert } from "react-bootstrap/";
import DefaultContext from "../../contexts/DefaultContext";
function AlertMessage(props) {
  let { alert, setAlert } = useContext(DefaultContext);
  // Laat de alert na een korte tijd terug verdwijnen.
  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
    }, 3000);
  });

  const style = {
    position: "absolute",
    zIndex: 999,
    width: "30%",
    top: "15%",
    left: "35%",
    transition: `opacity ms ease-in-out`,
  };
  // Om een alert op te roepen moet het bericht worden meegegeven als messages en de soort als variant
  /** De mogelijke varianten zijn:
   * primary
   * secondary
   * success
   * danger
   * warning
   * info
   * light
   * dark
   */
  return (
    <div>
      {alert && (
        <Alert variant={props.variant} className="alert" style={style}>
          <div className="ml-5">{props.messages}</div>
        </Alert>
      )}
    </div>
  );
}

export default AlertMessage;
