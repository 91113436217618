import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Row, Container, Table, Card } from "react-bootstrap";
import Axios from "axios";
import uniqid from "uniqid";
import LoadingIcon from "../../actions/LoadingIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faKey } from "@fortawesome/free-solid-svg-icons";
import { Pie, Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
ChartJS.register(
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler
);

let title = [];
function DashboardMoodTop() {
  let [moodLoading, setMoodLoading] = useState(true);
  const token = sessionStorage.getItem("complexToken");
  const challengeId = sessionStorage.getItem("challengeId");
  const subjectId = sessionStorage.getItem("subjectId");
  const history = useHistory();
  let [bestScores, setBestScores] = useState([]);
  let [worstScores, setWorstScores] = useState([]);
  let [bestScorers10] = useState([]);
  let [worstScorers10] = useState([]);
  let [bestScorers3] = useState([]);
  let [worstScorers3] = useState([]);
  let [dataLineScoresTop] = useState([]);
  let [dataLineScoresBottom] = useState([]);
  let [employeeMoodChallengeScoresTop, setEmployeeMoodChallengeScoresTop] =
    useState([]);
  let [employeeMoodChallengeLabelsTop, setEmployeeMoodChallengeLabelsTop] =
    useState([]);
  let [
    employeeMoodChallengeScoresBottom,
    setEmployeeMoodChallengeScoresBottom,
  ] = useState([]);
  let [
    employeeMoodChallengeLabelsBottom,
    setEmployeeMoodChallengeLabelsBottom,
  ] = useState([]);
  let [bestChosen, setBestChosen] = useState([]);
  let [worstChosen, setWorstChosen] = useState([]);

  useEffect(function () {
    getChallengeData();
  }, []);
  // haal resultaten op van de quiz via de api
  const getChallengeData = () => {
    Axios.get(`/Moods/Challenge/${challengeId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(async (response) => {
        let worst10 = [];
        let best10 = [];
        let userList = [];
        //Filter per user
        response.data.forEach((element) => {
            let isNewUser = true;
            userList.forEach((item) => {
                if (element.userId === item.userId) isNewUser = false;
            });
            if (isNewUser) {
                userList.push({
                    id: element.id,
                    employeeMoodAverage: moodCalculator(element.employeeMood),
                    challengeId: element.challengeId,
                    user: element.user,
                    userId: element.userId,
                    moodCount: 1,
                });
            } else {
                userList.map((item) => {
                    if (item.userId === element.userId) {
                        item.employeeMoodAverage += moodCalculator(element.employeeMood);
                        item.moodCount++;
                    }
                });
            }
        });
        userList.forEach((element) => {
            element.employeeMoodAverage = element.employeeMoodAverage/element.moodCount;
        });
        // vul de worst/best 10 op met de verkregen data
        userList.forEach((element) => {
          worst10.push(element);
          best10.push(element);
        });

        // sorteer de nieuwe arrays met custom sort methodes
        worst10.sort(sortWorst);
        worst10.length = 10;
        best10.sort(sortBest);
        best10.length = 10;
        setBestScores(best10);
        setWorstScores(worst10);
        // initialiseer de grafieken met de top/bottom scoorders
        handleClickTop(best10[0].user);
        handleClickBottom(worst10[0].user);
        setMoodLoading(false);
      })
      .catch((error) => console.log(error));
  };

  function moodCalculator(emoji) {
    switch (emoji) {
      case "InLove":
        return 100;

      case "Easy":
        return 90;

      case "Funny":
        return 80;

      case "Good":
        return 70;

      case "OK":
        return 60

      case "Neutral":
        return 50;

      case "Surprised":
        return 40;

      case "Angry":
        return 30;

      case "Sad":
        return 20;

      case "VerySad":
        return 10;
      
      case "Sick":
        return 0;
      
      default:
        return 50;
    }
  };

  // custom sort methodes voor beste en slechtste scoorders
  function sortBest(a, b) {
    if (a.employeeMoodAverage > b.employeeMoodAverage) {
      return -1;
    }
    if (a.employeeMoodAverage < b.employeeMoodAverage) {
      return 1;
    }
    return 0;
  }
  function sortWorst(a, b) {
    if (a.employeeMoodAverage < b.employeeMoodAverage) {
      return -1;
    }
    if (a.employeeMoodAverage > b.employeeMoodAverage) {
      return 1;
    }
    return 0;
  }
  const goBack = () => {
    history.goBack();
  };
  const handleClick = (user) => {
    sessionStorage.setItem("employeeId", user.id);
  };

  // functie om de grafiek van de top scoorders in te vullen op click van een naam in de top 3
  // dit gebeurt automatisch 1x bij het inladen van de pagina met de beste scoorder
  const handleClickTop = async (user) => {
    let data = [];
    let scores = [];
    let labels = [];
    //await Axios.get(`/Results/Employee/${user.id}/Subject/${subjectId}`, {
    await Axios.get(`/Moods/Subject/${subjectId}/User/${user.id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        data = response.data;
      })
      .catch((error) => console.log(error));
    data.map((res, index) => {
      scores.push(moodCalculator(res.employeeMood));
      labels.push(moodCalculator(res.employeeMood));
    });
    setEmployeeMoodChallengeScoresTop(scores);
    setEmployeeMoodChallengeLabelsTop(labels);
    setBestChosen(`Voortgang ${user.firstName} ${user.lastName}`);
  };

  dataLineScoresTop = {
    labels: employeeMoodChallengeLabelsTop,
    datasets: [
      {
        label: "Scores op deelgenomen testen uitgedrukt in %",
        data: employeeMoodChallengeScoresTop,
        borderColor: "rgba(84, 255, 84, 1)",
        borderWidth: "1",
        backgroundColor: "rgba(84, 255, 84, 0.2)",
        fill: true,
        tension: "0.4",
      },
    ],
    scales: {
      y: {
        min: 0,
        max: 100,
      },
    },
  };

  // functie om de grafiek van de bottom scoorders in te vullen op click van een naam in de bottom 3
  // dit gebeurt automatisch 1x bij het inladen van de pagina met de slechtste scoorder
  const handleClickBottom = async (user) => {
    let data = [];
    let scores = [];
    let labels = [];
    await Axios.get(`/Moods/Subject/${subjectId}/user/${user.id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        data = response.data;
      })
      .catch((error) => console.log(error));
    data.map((res, index) => {
      scores.push(moodCalculator(res.employeeMood));
      labels.push(res.challenge.title);
    });
    setEmployeeMoodChallengeScoresBottom(scores);
    setEmployeeMoodChallengeLabelsBottom(labels);
    setWorstChosen(`Voortgang ${user.firstName} ${user.lastName}`);
  };

  dataLineScoresBottom = {
    labels: employeeMoodChallengeLabelsBottom,
    datasets: [
      {
        label: "Scores op deelgenomen testen uitgedrukt in %",
        data: employeeMoodChallengeScoresBottom,
        borderColor: "rgba(255, 84, 84, 1)",
        borderWidth: "1",
        backgroundColor: "rgba(255, 84, 84, 0.2)",
        fill: true,
        tension: "0.4",
      },
    ],
    scales: {
      y: {
        min: 0,
        max: 100,
      },
    },
  };
  // map de top 10 scoorders en de functie om naar hun analysepagina te gaan
  bestScorers10 = bestScores.map((res) => {
    return (
      <tr key={uniqid()}>
        <td>
          <Link
            style={{
              textDecoration: "none",
              color: "#5094B9",
              hover: "#2d5971",
              fontSize: "1.2rem",
            }}
            to={{ pathname: "/DashboardEmployeeMood", customObject: title }}
            onClick={() => handleClick(res.user)}
          >
            <p>
              {res.user.firstName} {res.user.lastName}
            </p>
          </Link>
        </td>
      </tr>
    );
  });
  // map de bottom 10 scoorders en de functie om naar hun analysepagina te gaan
  worstScorers10 = worstScores.map((res) => {
    return (
      <tr key={uniqid()}>
        <td>
          <Link
            style={{
              textDecoration: "none",
              color: "#5094B9",
              hover: "#2d5971",
              fontSize: "1.2rem",
            }}
            to={{ pathname: "/DashboardEmployeeMood", customObject: title }}
            onClick={() => handleClick(res.user)}
          >
            <p>
              {res.user.firstName} {res.user.lastName}
            </p>
          </Link>
        </td>
      </tr>
    );
  });
  // map de top 3 scoorders en geef de functie om op click de grafiek te vranderen
  bestScorers3 = bestScores.slice(0, 3).map((res) => {
    return (
      <tr key={uniqid()}>
        <td>
          <button
            style={{
              textDecoration: "none",
              color: "#5094B9",
              hover: "#2d5971",
              fontSize: "1.2rem",
              background: "transparent",
              border: "none",
            }}
            onClick={() => handleClickTop(res.user)}
          >
            <p>
              {res.user.firstName} {res.user.lastName}
            </p>
          </button>
        </td>
      </tr>
    );
  });
  // map de bottom 3 scoorders en geef de functie om op click de grafiek te vranderen
  worstScorers3 = worstScores.slice(0, 3).map((res) => {
    return (
      <tr key={uniqid()}>
        <td>
          <button
            style={{
              textDecoration: "none",
              color: "#5094B9",
              hover: "#2d5971",
              fontSize: "1.2rem",
              background: "transparent",
              border: "none",
            }}
            onClick={() => handleClickBottom(res.user)}
          >
            <p>
              {res.user.firstName} {res.user.lastName}
            </p>
          </button>
        </td>
      </tr>
    );
  });

  // de opties voor beide grafieken om bepaalde dingen zoals tooltips en assen af te zetten
  const options = {
    scales: {
      y: {
        min: 0,
        max: 100,
        display: false,
      },
      x: { display: false },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    datalabels: {
      display: false,
    },
  };
  return (
    <div>
      <Container>
        <Row>
          <Col sm={1} lg={1}>
            <div>
              {
                <FontAwesomeIcon
                  onClick={goBack}
                  style={{ textDecoration: "none", color: "#2d5971" }}
                  icon={faArrowLeft}
                  size="3x"
                />
              }
            </div>
          </Col>
          <Col sm={10}>
            <h1>{sessionStorage.getItem("quizName")}</h1>
            <h2>Top and bottom scores</h2>
          </Col>
        </Row>
      </Container>

      <Container key={uniqid()}>
        {moodLoading ? (
          <div style={{ marginRight: "20vw", marginTop: "10vh" }}>
            <LoadingIcon />
          </div>
        ) : (
          <div>
            <Row>
              <Col md={5}>
                <Row>
                  <Col md={6}>
                    <Card
                      style={{
                        marginBottom: "5vh",
                        borderColor: "#96fc2e",
                        borderWidth: "2px",
                        height: "90%",
                        paddingTop: "4.4vh",
                        paddingBottom: "5vh",
                        paddingLeft: "1vh",
                        paddingRight: "1vh",
                        boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      <Table>
                        <thead>
                          <tr>
                            <th>Top 10</th>
                          </tr>
                        </thead>
                        <tbody>{bestScorers10}</tbody>
                      </Table>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card
                      style={{
                        marginBottom: "5vh",
                        borderColor: "#ff4747",
                        borderWidth: "2px",
                        height: "90%",
                        paddingTop: "4.4vh",
                        paddingBottom: "5vh",
                        paddingLeft: "1vh",
                        paddingRight: "1vh",
                        boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      <Table>
                        <thead>
                          <tr>
                            <th>Bottom 10</th>
                          </tr>
                        </thead>
                        <tbody>{worstScorers10}</tbody>
                      </Table>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col md={7}>
                <Row>
                  <Col md={8}>
                    <Card
                      style={{
                        marginBottom: "5vh",
                        padding: "2vh 0 0 0",
                        boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        height: "90%",
                      }}
                    >
                      <p>{bestChosen}</p>
                      <Line data={dataLineScoresTop} options={options} />
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card
                      style={{
                        marginBottom: "5vh",
                        borderColor: "#96fc2e",
                        borderWidth: "2px",
                        height: "90%",
                        paddingTop: "2vh",
                        paddingBottom: "2vh",
                        paddingLeft: "1vh",
                        paddingRight: "1vh",
                        boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      <Table>
                        <thead>
                          <tr>
                            <th>Top 3</th>
                          </tr>
                        </thead>
                        <tbody>{bestScorers3}</tbody>
                      </Table>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <Card
                      style={{
                        marginBottom: "5vh",
                        padding: "2vh 0 0 0",
                        boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                        height: "90%",
                      }}
                    >
                      <p>{worstChosen}</p>
                      <Line data={dataLineScoresBottom} options={options} />
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card
                      style={{
                        marginBottom: "5vh",
                        borderColor: "#ff4747",
                        borderWidth: "2px",
                        height: "90%",
                        paddingTop: "2vh",
                        paddingBottom: "2vh",
                        paddingLeft: "1vh",
                        paddingRight: "1vh",
                        boxShadow: "3px 3px 15px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      <Table>
                        <thead>
                          <tr>
                            <th>Bottom 3</th>
                          </tr>
                        </thead>
                        <tbody>{worstScorers3}</tbody>
                      </Table>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </div>
  );
}

export default DashboardMoodTop;
