import React from "react";

function CustomButtonLarge(props) {
  return (
    <>
      <button type="submit" onClick={props.handler} style={style}>
        {props.name}
      </button>
    </>
  );
}
const style = {
  backgroundColor: "#2d5971",
  color: "white",
  borderRadius: "5px",
  borderStyle: "hidden",
  width: "100%",
  height: "2.5em",
  padding: "0.5em 2em 2em 2em",
  fontSize: "23px",
};
export default CustomButtonLarge;
