import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { DropdownButton, Dropdown } from "react-bootstrap";

function Header() {
  let logo = null;
  let homeButton;
  let role = "User";

  const token = sessionStorage.getItem("complexToken");
  let userId = sessionStorage.getItem("Id");

  let history = useHistory();
  let [unreadReminderCount, setUnreadReminderCount] = useState(0);
  let [reminders, setReminders] = useState([]);
  let [reminderDisplay, setReminderDisplay] = useState([]);

  // Check of de gebruiker is ingelogd en zo ja plaats de opties voor het profiel in de header.
  if (token) {
    logo = (
      <div>
        <Logo />
      </div>
    );
    homeButton = (
      <Link
        style={{
          textDecoration: "none",
          color: "#5094B9",
          hover: "#2d5971",
          fontSize: "1.8rem",
        }}
        to={{ pathname: "/HomePage"}}
        >
          Home
      </Link>
    );
  } else {
    logo = (
      <h1 className="my-0 mr-md-auto font-weight-normal">
        <div className="text-white mb-3"><a href="https://workerpro.be" style={{color: "white"}}>WorkerPro</a></div>
      </h1>
    );
  }
  //Translate RoleName
  switch (sessionStorage.getItem("RoleName")){
    case "Employer":
      role = "Werkgever";
      break;
    case "Coach":
      role = "Coach";
      break;
    case "Admin":
      role = "Beheerder";
      break;
    case "SuperAdmin":
      role = "SuperAdmin";
      break;
    default:
      role = "Gebruiker";
      break;
  }

  useEffect(function() {
    if (userId && role == "Werkgever") {
      getReminders();
    }
    if (token) {
      updateUser(); //This is mainly for if the user gets locked out, pauzed
    }
  }, []);

  async function updateUser() {
    Axios
    .get(`/Users/${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // sessionStorage.setItem("FirstName", res.data.voornaam);
      // sessionStorage.setItem("LastName", res.data.familienaam);
      // sessionStorage.setItem("complexToken", res.data.jwtToken);
      // sessionStorage.setItem("OrganisationId", res.data.organisation);
      // sessionStorage.setItem("RoleName", res.data.roles[0]);
      // sessionStorage.setItem("Id", res.data.id);
      // sessionStorage.setItem("Email", res.data.email);
      sessionStorage.setItem("LockedOut", res.data.lockoutEnabled);
    })
  }

  async function goToQuiz(id) {
    let reminder = reminders.find(res => res.id === id);
    reminder.userChallenges = null;
    reminder.reminderRead = true;
    
    await Axios
    .put("/Challenges/"+id, reminder, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then()
    .catch((err) => console.log(err));

    sessionStorage.setItem("challengeId", id);
    sessionStorage.setItem("quizName", reminders.filter(x => x.id === id).pop().title);
    history.push("/Dashboard");

    getReminders();
  }

  const getReminders = async() => {
    Axios
      .get("/Challenges/Employer/" + userId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        reminders = [];
        reminders = res.data;
        setReminders(reminders);
        reminderDisplay = [];

        unreadReminderCount = 0;
        reminderDisplay = reminders.filter(
          x => x.reminderMessage !== null
          && new Date(x.startDate) < new Date()
          && new Date(x.reminderTimeStamp) < new Date()
        ).map((reminder) => {
          //reminder.reminderMessage !== null) --> If it has been implemented.
          //new Date(reminder.startDate) < new Date() --> If challenge has started, otherwise there's no data.
          //new Date(reminder.reminderDate) < new Date() --> If user chose to be notified at this time yet.
          
          if (reminder.reminderRead === false) unreadReminderCount ++;
          return (
            <Dropdown.Item
              key={"chalId" + reminder.id}
              style={{
                backgroundColor: reminder.reminderRead ? "#fff" : "#2d597166",
                width: "content",
                whiteSpace: "pre-line"
              }}
              onClick={(e) => {
                goToQuiz(reminder.id);
              }}
              >
                <h5 style={{fontWeight: "bold"}}>
                  <span style={{color: "#2d5971"}}>Quiz: </span>
                  {reminder.title}
                </h5>
                <p>{reminder.reminderMessage}</p>
            </Dropdown.Item>
          );
        });

        let reminderFrequency = 0;
        Axios.get(`/Settings/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          reminderFrequency = res.data.reminderFrequency;
          
          let latestQuiz = new Date(null);
          reminders.map((reminder) => {
            if (new Date(reminder.startDate) > latestQuiz)
            {
              latestQuiz = new Date(reminder.startDate);
            }
          });
          let compareDate = latestQuiz;
          compareDate.setDate(compareDate.getDate() + reminderFrequency)
          
          if (new Date() > compareDate)
          {
            reminderDisplay.unshift 
            (
              <Dropdown.Item
                key={"CreateNewQuizFromHeader"}
                style={{
                  backgroundColor: "#5094B966",
                  width: "content",
                  whiteSpace: "pre-line"
                }}
                onClick={(e) => {
                  history.push("/HomePage");
                }}
                >
                  <h5 style={{fontWeight: "bold"}}>Herinnering</h5>
                  <p>U heeft al even geen nieuwe quiz aangemaakt!</p>
              </Dropdown.Item>
            );
            unreadReminderCount ++;
            setReminderDisplay(reminderDisplay);
            setUnreadReminderCount(unreadReminderCount);
          }
        })
        .catch((err) => console.log(err));
        
        //console.log("ReminderDisplay after:", reminderDisplay);
        
        setReminderDisplay(reminderDisplay);
        setUnreadReminderCount(unreadReminderCount);
      })
      .catch((err) => console.log(err));
  }

  return (
    <header className="Header2">
      <div className="container d-flex flex-column flex-md-row align-items-center p-1">
        <Row>
          <Col>
            <h4>{homeButton || "WorkerPro Portal"}</h4>
          </Col>
        </Row>
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            gap: "1rem",
            alignContent: "flex-end",
            flexWrap: "wrap"
          }}
        >
          {/* display de rol en naam van de ingelogde persoon */}
          {userId ? (
            <>
              <h4 className=" my-0 text-right mt-3  font-weight-normal">
                {sessionStorage.getItem("RoleName") ? role : ""}
              </h4>
              <h4 className=" my-0 text-right mt-3  font-weight-normal">
                {sessionStorage.getItem("Description")}
              </h4>
              <h4 className=" my-0 text-right mt-3  font-weight-normal">
                {sessionStorage.getItem("FirstName")}{" "}
                {sessionStorage.getItem("LastName")}
              </h4>
              { role == "Werkgever" &&
                <DropdownButton
                  style={{
                    borderColor: "#2d5971",
                    outline: "none",
                    boxShadow: "none",
                    // height: "2rem",
                    //width: "7rem",
                    marginLeft: "auto"
                  }}
                  id="dropdown"
                  title={(
                  <div>
                    <FontAwesomeIcon
                      icon={faBell}
                      size="3x"
                      style={{
                        display: "inline",
                        float: "left",
                        display: "flex",
                        borderColor: "#2d5971",
                        outline: "none",
                        boxShadow: "none",
                      }}
                    />
                    <div
                      style={{
                        display: "inline",
                        borderRadius: "1000px",
                        width: "1.5rem",
                        height: "1.5rem",
                        backgroundColor: unreadReminderCount > 0 ? "red" : "green",
                        float: "right",
                        position: "relative",
                        top: "0rem",
                        left: "-1rem",
                        color: "white",
                      }}
                      >
                      {unreadReminderCount}
                    </div>
                  </div>
                  )}
                  >
                    {reminderDisplay}
                </DropdownButton>
              }
            </>
          ) : (<></>)}
          <div style={{ display: "flex", height: "4.2rem", marginLeft: "auto" }}>{logo}</div>
        </div>
      </div>
    </header>
  );
}

export default Header;
