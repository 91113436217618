import React, { useState } from "react";
import { InputGroup, FormControl, Col, Row } from "react-bootstrap";

function ChallengeContainer() {
  const [aantalVragen, setAantalVragen] = useState(0);
  const [titel, setTitel] = useState("");
  sessionStorage.setItem("titel", titel);
  sessionStorage.setItem("aantalVragen", aantalVragen);

  return (
    <form>
      <Row>
        <Col md={8}>
          <div className="tekstNaamInstelling pb2">
            Hoeveel vragen mag uw quiz bevatten:
          </div>
        </Col>
        <Col md={4}>
          <div className="tekstNaamInstelling pb2">Geef een titel in:</div>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <InputGroup className="mb-4" placeholder="aantal">
            <FormControl
              placeholder="aantal"
              type="number"
              min="2"
              aria-label="aantal Vragen"
              aria-describedby="basic-addon1"
              // verander de value op het moment dat de docent een nieuw teken invoert
              onChange={(e) => setAantalVragen(e.target.value)}
            />
          </InputGroup>
        </Col>
        <Col sm={12} md={6}></Col>
        <Col sm={12} md={4}>
          <InputGroup style={{ width: "80%" }} className="mb-4 ">
            <FormControl
              placeholder="titel"
              aria-label="titel"
              aria-describedby="basic-addon1"
              // verander de value op het moment dat de docent een nieuw teken invoert
              onChange={(e) => setTitel(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="tekstNaamInstelling pb2">
            Selecteer publicatie-en einddatum:
          </div>
        </Col>
        <Col md={6}>
          <div className="tekstNaamInstelling pb2">
            Selecteer start en eind-tijd: <span style={{fontSize: "small"}}>(optioneel)</span>
          </div>
        </Col>
      </Row>
    </form>
  );
}
export default ChallengeContainer;
